<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="tag-filter-category">
    <h4 class="tag-filter-category__name">
      {{ category.name }}
    </h4>
    <TagFilterRecord
      v-for="tag in category.tags"
      :key="tag.id"
      :tag="tag"
      @deselect="$emit('deselect', { categoryId: category.id, tagId: tag.id })"
    />
  </div>
</template>

<script>
import TagFilterRecord from './TagFilterRecord';

export default {
  name: 'TagFilterCategory',
  components: {
    TagFilterRecord,
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
  emits: ['deselect']
};
</script>

<style scoped lang="scss">
@import 'Styles/shared/_colors';

.tag-filter-category {
  background: $color-light-grey;
  display: flex;
  flex-wrap: wrap;
  margin: 0 0.5rem 0.5rem 0;
  padding: 0.5rem 0.5rem 0 0;

  &__name {
    font-size: 0.9rem;
    font-weight: normal;
    line-height: 2rem;
    margin: 0 0.6rem;
    text-transform: uppercase;
  }
}
</style>

<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div
    class="publish-dropdown"
    :class="{ 'publish-dropdown--new-view': newView }"
  >
    <button
      ref="publishButton"
      type="button"
      class="publish-dropdown__button"
      data-automation="publish-button"
      aria-label="Publish"
      aria-haspopup="true"
      :aria-expanded="isOpen"
      @click.stop="toggleMenu"
    >
      <span class="publish-dropdown__button-label">Publish</span>
    </button>

    <!-- eslint-disable-next-line vuejs-accessibility/interactive-supports-focus -->
    <ul
      role="menu"
      :class="[
        'publish-dropdown__menu',
        { 'publish-dropdown__menu--open': isOpen }
      ]"
      data-automation="publish-dropdown__menu--open"
      @keydown.down="onArrowDown"
      @keydown.up="onArrowUp"
      @keydown.tab="hideMenu"
      @keydown.esc="hideMenu({ keepFocus: true })"
    >
      <li
        v-for="item in menuItems"
        :key="item.type"
        role="none"
      >
        <div
          v-if="item.separator"
          class="publish-dropdown__menu-separator"
          role="none"
        />
        <button
          :ref="item.type"
          :aria-label="item.label"
          :class="`publish-dropdown__menu-button publish-dropdown__menu-button--${item.cssName}`"
          :data-automation="item.dataAutomation"
          :disabled="item.disabled"
          :title="item.title"
          data="item.type"
          type="button"
          role="menuitem"
          tabindex="-1"
          @click="item.onClick"
        >
          {{ item.label }}
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import {
  ContentTypeDescriptions,
  JUPYTER_NOTEBOOK,
  PLUMBER_API,
  QUARTO,
  RMARKDOWN,
  RMARKDOWN_PARAMS,
  SHINY_APP,
} from '@/constants/contentTypes';
import { DEPLOY_WIZARD_OPEN } from '@/store/modules/deployWizard';
import { JUMP_START_OPEN } from '@/store/modules/jumpstart';
import { PUBLISH_WIZARD_OPEN } from '@/store/modules/publish';
import { docsPath } from '@/utils/paths';
import { mapMutations } from 'vuex';

const IMPORT_FROM_GIT = 'git';
const JUMP_START = 'jumpStart';
const PUBLISHING_GUIDE = 'publishingGuide';

export default {
  name: 'PublishDropdown',
  props: {
    newView: {
      type: Boolean,
      default: true,
    },
    gitEnabled: {
      type: Boolean,
      default: true,
    },
    gitAvailable: {
      type: Boolean,
      default: true,
    },
    jumpStartEnabled: {
      type: Boolean,
      default: true,
    },
  },
  data: function() {
    return {
      currentItem: 0,
      isOpen: false,
    };
  },
  computed: {
    gitEnabledButNotAvailable() {
      return this.gitEnabled && !this.gitAvailable;
    },
    gitButtonTitle() {
      return this.gitEnabledButNotAvailable
        ? 'Git is not available on your installation'
        : null;
    },
    menuItems() {
      return [
        {
          type: IMPORT_FROM_GIT,
          cssName: 'new-from-git',
          dataAutomation: 'new-from-git',
          onClick: this.startDeploymentWizard,
          title: this.gitButtonTitle,
          hidden: !this.gitEnabled,
          disabled: this.gitEnabledButNotAvailable,
          label: 'Import from Git'
        },
        {
          type: RMARKDOWN,
          cssName: 'rmarkdown',
          dataAutomation: 'publish-dropdown__rmarkdown',
          onClick: () => this.startPublishWizard({ contentType: RMARKDOWN }),
          separator: this.gitEnabled,
          label: ContentTypeDescriptions.RMARKDOWN,
        },
        {
          type: RMARKDOWN_PARAMS,
          cssName: 'rmarkdown-params',
          dataAutomation: 'publish-dropdown__rmarkdown--params',
          onClick: () => this.startPublishWizard({ contentType: RMARKDOWN_PARAMS }),
          label: ContentTypeDescriptions.RMARKDOWN_PARAMS,
        },
        {
          type: SHINY_APP,
          cssName: 'shiny',
          dataAutomation: 'publish-dropdown__shiny',
          onClick: () => this.startPublishWizard({ contentType: SHINY_APP }),
          label: ContentTypeDescriptions.SHINY_APP,
        },
        {
          type: PLUMBER_API,
          cssName: 'plumber',
          dataAutomation: 'publish-dropdown__plumber',
          onClick: () => this.startPublishWizard({ contentType: PLUMBER_API }),
          label: ContentTypeDescriptions.PLUMBER_API,
        },
        {
          type: JUPYTER_NOTEBOOK,
          cssName: 'jupyter',
          dataAutomation: 'publish-dropdown__jupyter',
          onClick: () => this.startPublishWizard({ contentType: JUPYTER_NOTEBOOK }),
          label: ContentTypeDescriptions.JUPYTER_NOTEBOOK,
        },
        {
          type: QUARTO,
          cssName: 'quarto',
          dataAutomation: 'publish-dropdown__quarto',
          onClick: () => this.startPublishWizard({ contentType: QUARTO }),
          label: ContentTypeDescriptions.QUARTO,
        },
        {
          type: JUMP_START,
          cssName: 'jump-start',
          dataAutomation: 'publish-dropdown__jump-start',
          onClick: this.startJumpStartDialog,
          hidden: !this.jumpStartEnabled,
          separator: true,
          label: 'Jump Start Examples'
        },
        {
          type: PUBLISHING_GUIDE,
          cssName: 'publishing-guide',
          dataAutomation: 'publish-dropdown__user-guide',
          onClick: this.openPublishingGuide,
          separator: !this.jumpStartEnabled,
          label: 'How to Publish (User Guide)'
        },
      ].filter(item => !item.hidden);
    },
  },
  created() {
    window.addEventListener('click', this.hideMenu);
  },
  unmounted() {
    window.removeEventListener('click', this.hideMenu);
  },
  methods: {
    focusButton() {
      setTimeout(() => this.$refs.publishButton?.focus(), 100);
    },
    toggleMenu() {
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        this.currentItem = 0;
        this.focusMenuItem(this.currentItem);
      }
    },
    focusMenuItem(index) {
      const firstItem = this.menuItems[index].type;
      this.$nextTick().then(() => this.$refs[firstItem][0].focus());
    },
    hideMenu({ keepFocus = false }) {
      this.isOpen = false;
      if (keepFocus) {
        this.focusButton();
      }
    },
    onArrowUp() {
      const currentItem = this.currentItem - 1;
      this.currentItem =
        currentItem < 0 ? this.menuItems.length - 1 : currentItem;
      this.focusMenuItem(this.currentItem);
    },
    onArrowDown() {
      const currentItem = this.currentItem + 1;
      this.currentItem =
        currentItem > this.menuItems.length - 1 ? 0 : currentItem;
      this.focusMenuItem(this.currentItem);
    },
    openPublishingGuide() {
      open(docsPath('user/publishing-overview/'), '_blank');
      this.focusButton();
    },
    ...mapMutations({
      startDeploymentWizard: DEPLOY_WIZARD_OPEN,
      startJumpStartDialog: JUMP_START_OPEN,
      startPublishWizard: PUBLISH_WIZARD_OPEN,
    }),
  },
};
</script>

<style lang="scss" scoped>
  @import 'Styles/shared/_colors';
  @import 'Styles/shared/_variables';
  @import 'Styles/shared/_mixins';

  .publish-dropdown {
    display: inline-block;
    position: relative;

  // After new search view lands, these styles should be the default
  // no "--..." modifier should be needed.
  &--new-view {
    .publish-dropdown__button {
      background-position: 0.8rem center;
      background-size: 0.8rem 0.8rem;
      background-image: url('/images/publish-icon.svg');
      padding: 0 1rem 0 2.2rem;
    }

    .publish-dropdown__menu {
      white-space: nowrap;
    }
  }

    &__button {
      height: 30px;
      margin-left: 10px;
      min-width: 0px;
      vertical-align: middle;
      text-align: left;
      color: $color-white;
      padding: 0 30px 0 20px;
      border-radius: 4px;
      background-color: $color-primary;
      background-position: right center;
      background-size: 30px 30px;
      background-image: url('/images/elements/actionMenuDropdown.svg');
      background-repeat: no-repeat;

      &-label {
        background-color: $color-primary;
      }
    }

    &__menu {
      @include strong-drop-shadow;
      background-color: $color-white;
      display: none;
      font-size: 13px;
      line-height: 40px;
      position: absolute;
      right: 0px;
      text-align: left;
      z-index: 1000;

      li {
        margin-bottom: 0;
      }

      &.publish-dropdown__menu--open {
        display: block;
      }
    }

    &__menu-button {
      display: block;
      margin-left: 0;
      height: 40px;
      width: 100%;
      padding: 0px 15px 0px 40px;
      text-align: left;
      background-color: transparent;
      background-size: 2em 2em;
      background-position: 0.6em center;
      background-repeat: no-repeat;

      &:hover {
        background-color: $color-posit-teal;
        color: $color-white;
      }

      &:focus {
        outline: none;
      }

      &:focus-visible {
        color: $color-white;
        background-color: $color-posit-teal;
        outline: none;
      }

      &--new-from-git {
        background-image: url('/images/Git-Icon-BlackA42.svg');
      }

      &--rmarkdown {
        background-image: url('/images/elements/iconDoc.svg');
      }
      &--rmarkdown-params {
        background-image: url('/images/elements/iconDocParams.svg');
      }
      &--shiny {
        background-image: url('/images/elements/iconApp.svg');
      }
      &--plumber {
        background-image: url('/images/elements/iconAPI.svg');
      }
      &--jupyter {
        background-image: url('/images/elements/iconJupyter.svg');
      }
      &--quarto {
        background-image: url('/images/elements/iconQuarto.svg');
      }

      &--jump-start {
        background-image: url('/images/elements/actionSamples.svg');
      }

      &--publishing-guide {
        background-image: url('/images/elements/actionInfo.svg');
      }
    }

    &__menu-separator {
      line-height: 1px;
      height: 1px;
      margin: 4px 0 5px 0;
      border-bottom: 1px dotted $color-light-grey-4;
    }
  }

  @include for-medium-screens() {
    .publish-dropdown__button {
      padding-left: 34px;
      background-size: 2 * $typical-action-size $typical-action-size;
      background-image: url('/images/elements/actionPublish.svg');

      &-label {
        display: none;
      }
    }
  }
</style>

<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div
    v-if="monogram"
    class="userInitialsIconContainer"
    data-automation="user-icon"
  >
    <div class="userIcon initials">
      {{ monogram }}
    </div>
  </div>
  <div v-else>
    <img
      src="/images/principal-user.png"
      alt="Generic User"
    >
  </div>
</template>

<script>
export default {
  name: 'UserIcon',
  props: {
    firstName: {
      type: String,
      default: '',
    },
    lastName: {
      type: String,
      default: '',
    },
    username: {
      type: String,
      required: true,
    }
  },
  computed: {
    /**
     * monogram returns the user monogram
     * Prefer first initial-last initial, then first initial only, then first character of username
     * @returns {string|null}
     */
    monogram() {
      if (this.firstName) {
        const firstLetter = String.fromCodePoint(
          this.firstName.codePointAt(0)
        ).toUpperCase();
        if (this.lastName) {
          const lastLetter = String.fromCodePoint(
            this.lastName.codePointAt(0)
          ).toUpperCase();
          return `${firstLetter}${lastLetter}`;
        }
        return `${firstLetter}`;
      } else if (this.username) {
        return String.fromCodePoint(
          this.username.codePointAt(0)
        ).toUpperCase();
      }
      return null;
    }
  }
};
</script>

<style scoped lang="scss">
@import 'Styles/shared/_colors';

img {
  width: 30px;
  height: 30px;
}
.userIcon {
  display: inline-block;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  overflow: hidden;
  background-color: $color-secondary;
  background-size: cover;
  background-repeat: no-repeat;
  vertical-align: middle;

  &.initials {
    color: $color-secondary-inverse;
    font-size: 16px;
    font-weight: 300;
    text-align: center;
    transform: rotate(-15deg);
  }
}

.userInitialsIconContainer {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 30px;
  vertical-align: middle;
  border-radius: 15px;
  background-color: $color-medium-grey;

  .userIcon {
    position: absolute;
    left: 0px;
    top: 0px;
    background-color: transparent;
  }
}
</style>

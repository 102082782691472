// Copyright (C) 2022 by Posit Software, PBC.

import { updateUserPreferences } from '@/api/users';

export const AUTH_ORIGIN_HELP_INIT = 'AUTH_ORIGIN_HELP_INIT';
export const AUTH_ORIGIN_HELP_HIDE = 'AUTH_ORIGIN_HELP_HIDE';
export const AUTH_ORIGIN_HELP_UNHIDE = 'AUTH_ORIGIN_HELP_UNHIDE';
export const AUTH_ORIGIN_HELP_UPDATE_HIDDEN = 'AUTH_ORIGIN_HELP_UPDATE_HIDDEN';

export default {
  state: {
    hide: false,
  },
  mutations: {
    [AUTH_ORIGIN_HELP_INIT](state) {
      if (this.state.currentUser?.user?.preferences?.hideAuthOriginHelp) {
        state.hide = true;
        return;
      }
      state.hide = false;
    },
    [AUTH_ORIGIN_HELP_HIDE](state) {
      state.hide = true;
    },
    [AUTH_ORIGIN_HELP_UNHIDE](state) {
      state.hide = false;
    },
  },
  actions: {
    [AUTH_ORIGIN_HELP_UPDATE_HIDDEN]({ state, commit, rootState: { currentUser: { user } } }) {
      const hide = !state.hide;
      updateUserPreferences(user.guid, {
        HideAuthOriginHelp: hide,
      });
      if (hide) {
        commit(AUTH_ORIGIN_HELP_HIDE);
        return;
      }
      commit(AUTH_ORIGIN_HELP_UNHIDE);
    },
  },
};

<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <span>
    <button
      type="button"
      aria-label="Email Report"
      title="Email Report"
      class="action email content-actions__email-report-button"
      data-automation="menuitem-email-report"
      @click="emailDialogActive = true"
    />
    <EmailDialog
      v-if="emailDialogActive"
      @hide="emailDialogActive = false"
    />
  </span>
</template>

<script>
import EmailDialog from './EmailDialog';

export default {
  name: 'EmailReportButton',
  components: { EmailDialog },
  data() {
    return {
      emailDialogActive: false,
    };
  },
};
</script>

<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<!-- Renders the Vars tab -->
<template>
  <div
    class="rs-variables"
    data-automation="app-settings__environment-variables"
  >
    <EmbeddedStatusMessage
      v-if="loading"
      message="Loading variables..."
      :show-close="false"
      type="activity"
      data-automation="loading"
    />
    <div
      v-if="loadingError"
      class="formSection"
      data-automation="loading-error"
    >
      <p>
        An error occurred while loading the environment variables.
      </p>
    </div>
    <div
      v-if="notExecutable"
      class="formSection"
      data-automation="not-executable-message"
    >
      <p>
        The source code for this content was not published.
        It is not executable and does not accept environment variables.
      </p>
    </div>
    <div
      v-if="isRestricted"
      class="formSection"
      data-automation="restricted-message"
    >
      <p>
        You do not have permissions to change the environment variables for this content.
      </p>
    </div>
    <div
      v-if="canEditVariables"
      data-automation="edit-variables"
    >
      <ConfirmationPanel
        :enabled="enableConfirmation"
        :visible="showConfirmation"
        @save="confirmSave"
        @discard="discard"
      />
      <RSInformationToggle>
        <template #title>
          <span class="rs-variables__title">
            Environment Variables
          </span>
        </template>
        <template #help>
          <p>
            Environment variables defined here are exposed to the processes executing your content.
            This is one way to pass configuration options (e.g. database passwords, shared secrets, etc.).
          </p>
          <p>
            Once you add a variable, the value will be obscured.
            Environment variables are encrypted on-disk and in-memory.
            They are decrypted only when a process is about to be started.
          </p>
        </template>
      </RSInformationToggle>
      <hr class="rs-divider">
      <form
        class="rs-variables__form"
        @submit.prevent="addVariable"
      >
        <div class="rs-variables__form__note">
          Note: Do not wrap your text in quotation marks;
          all symbols become part of the value available to your code.
        </div>
        <RSInputText
          v-model.trim="form.name"
          :message="nameError"
          label="Name"
          data-automation="input-environment-variable-name"
          name="name"
        />
        <RSInputPassword
          v-model.trim="form.value"
          label="Value"
          :required="false"
          data-automation="input-environment-variable-value"
          autocomplete="off"
          name="value"
        />
        <RSButton
          label="Add Variable"
          :disabled="isFormInvalid"
          data-automation="add-environment-variable"
          class="rs-variables__form__button"
        />
      </form>
      <hr class="rs-divider">
      <div
        v-for="(item, i) in form.activeList"
        :key="i"
        :class="{'rs-variables__field-changed': isChanged(item) }"
        data-automation="environment-variable"
      >
        <div class="rs-variables__field-name">
          <div
            :class="{'rs-variables__field-name__text--deleted': item.deleted}"
            class="rs-variables__field-name__text"
            data-automation="environment-variable-name"
          >
            {{ item.name }}
          </div>
          <div>
            <button
              v-if="!showUndo(item)"
              aria-label="Edit variable value"
              title="Edit variable value"
              class="rs-variables__field-name__edit"
              data-automation="edit-environment-variable"
              @click="editVariable(item)"
            />
            <button
              v-if="!showUndo(item)"
              aria-label="Delete variable"
              title="Delete variable"
              class="rs-variables__field-name__delete"
              data-automation="delete-environment-variable"
              @click="deleteVariable(item)"
            />
            <button
              v-if="showUndo(item)"
              aria-label="Undo change"
              title="Undo change"
              class="rs-variables__field-name__undo"
              data-automation="undo-environment-variable"
              @click="undoChanges(item)"
            />
          </div>
        </div>
        <RSInputPassword
          v-if="isEdited(item)"
          :ref="`edit${item.name}Input`"
          v-model="item.editedValue"
          label="New value"
          :show-label="false"
          :required="false"
          placeholder="Enter new value..."
          data-automation="edit-environment-variable-value"
          autocomplete="off"
          name="newValue"
        />
        <hr class="rs-divider">
      </div>
      <EnvironmentConflictDialog
        v-if="showConflictDialog"
        :variables="changedVariables"
        @close="reload"
      />
      <ReloadDialog
        v-if="showReloadDialog"
        :content-type="contentType"
        :variables="changedVariables"
        @close="closeDialog"
        @save="saveConfirmation"
      />
    </div>
  </div>
</template>

<script>
import { getAppVars, updateAppVars } from '@/api/app';
import ApiErrors from '@/api/errorCodes';
import EmbeddedStatusMessage from '@/components/EmbeddedStatusMessage.vue';
import RSButton from '@/elements/RSButton.vue';
import RSInformationToggle from '@/elements/RSInformationToggle.vue';
import RSInputPassword from '@/elements/RSInputPassword.vue';
import RSInputText from '@/elements/RSInputText.vue';
import { SET_CONTENT_FRAME_RELOADING } from '@/store/modules/contentView';
import {
  CLEAR_STATUS_MESSAGE,
  SET_ERROR_MESSAGE_FROM_API,
  SHOW_INFO_MESSAGE,
} from '@/store/modules/messages';
import ConfirmationPanel from '@/views/content/settings/ConfirmationPanel';
import EnvironmentConflictDialog from '@/views/content/settings/EnvironmentConflictDialog';
import ReloadDialog from '@/views/content/settings/ReloadDialog';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  name: 'EnvironmentSettings',
  components: {
    RSInformationToggle,
    RSInputText,
    RSInputPassword,
    RSButton,
    EmbeddedStatusMessage,
    ConfirmationPanel,
    EnvironmentConflictDialog,
    ReloadDialog,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      loading: true,
      loadingError: false,
      isAppExecutable: false,
      initialList: [],
      contentType: '',
      initialized: null, // promise chain initialized during mount (easy unit testing)
      form: {
        name: '',
        value: '',
        activeList: '',
      },
      version: null,
      showConflictDialog: false,
      showReloadDialog: false,
      restricted: true,
      isSaving: false,
    };
  },
  validations() {
    return {
      form: {
        name: {
          required,
          duplicated: (value, vm) => !vm.activeList.find(v => v.name === value && !v.deleted),
        },
      },
    };
  },
  computed: {
    ...mapState({
      app: state => state.contentView.app,
      currentUser: state => state.currentUser.user,
    }),
    nameError() {
      if (this.v$.form.name.$errors[0]?.$validator === 'duplicated') {
        return 'A variable with this name already exists';
      } else if (this.v$.form.name.$errors[0]?.$validator === 'required') {
        return 'Name is required';
      }
      return null;
    },
    isFormInvalid() {
      return this.v$.form.$dirty && this.v$.form.$invalid;
    },
    doneLoading() {
      return !this.loading && !this.loadingError;
    },
    notExecutable() {
      return this.doneLoading && !this.isAppExecutable;
    },
    isRestricted() {
      return this.doneLoading && this.isAppExecutable && this.restricted;
    },
    canEditVariables() {
      return this.doneLoading && this.isAppExecutable && !this.restricted;
    },
    changedVariables() {
      return this.form.activeList
        .filter(this.isChanged)
        .map(variable => variable.name)
        .join('\n');
    },
    showConfirmation() {
      // show the save/discard dialog if we have any local changes and are not in a conflict state
      return Boolean(this.changedVariables) && !this.showConflictDialog;
    },
    enableConfirmation() {
      return this.showConfirmation && !this.isSaving;
    },
  },
  created() {
    this.init();
  },
  methods: {
    ...mapMutations({
      reloadFrame: SET_CONTENT_FRAME_RELOADING,
      clearStatusMessage: CLEAR_STATUS_MESSAGE,
      setErrorMessageFromAPI: SET_ERROR_MESSAGE_FROM_API,
    }),
    ...mapActions({
      setInfoMessage: SHOW_INFO_MESSAGE,
    }),
    init() {
      this.loading = true;
      this.loadingError = false;
      this.clearStatusMessage();
      this.isAppExecutable = this.app?.isExecutable();
      this.contentType = this.app?.contentType();
      this.restricted = !this.currentUser.canEditAppSettings(this.app);
      this.initialized = this.getVariableData()
        .catch(e => {
          this.loadingError = true;
          this.setErrorMessageFromAPI(e);
        })
        .finally(() => (this.loading = false));
    },
    getVariableData() {
      if (!this.isAppExecutable || this.restricted) {
        return Promise.resolve();
      }
      return getAppVars(this.app.id).then(vars => {
        this.version = vars.version;
        this.initialList = Object.keys(vars.values).sort();
        this.resetActiveList();
      });
    },
    resetActiveList() {
      this.form.activeList = this.initialList.map(x => (
        {
          name: x,
          value: null,
          editedValue: null,
          deleted: false
        }
      ));
    },
    resetAddVariableForm() {
      this.form.name = '';
      this.form.value = '';
      this.v$.form.$reset();
    },
    addVariable() {
      // trigger validation before adding the variable and bail if form is not valid
      this.v$.form.$touch();
      if (this.v$.form.$invalid) {
        return;
      }

      this.form.activeList.unshift({
        name: this.form.name,
        value: this.form.value,
        editedValue: null,
        deleted: false,
      });
      this.resetAddVariableForm();
    },
    save() {
      const savingTimeout = setTimeout(() => {
        this.isSaving = true;
      }, 300);

      const data = this.form.activeList
        .filter(val => !val.deleted)
        .reduce((acc, val) => {
          // editedValue takes precedence over value, including if it is ''
          acc[val.name] = this.isEdited(val) ? val.editedValue : val.value;
          return acc;
        }, {});

      this.clearStatusMessage();
      this.resetAddVariableForm();

      return updateAppVars(this.app.id, this.version, data)
        .then(() => {
          this.setInfoMessage({ message: 'Updated environment variables successfully' });
          return this.getVariableData();
        })
        .catch(e => {
          // unsure what kind of error we are getting so better be safe
          if (
            e &&
            e.response &&
            e.response.data &&
            e.response.data.code === ApiErrors.EnvironmentVersionMismatch
          ) {
            this.showConflictDialog = true;
            return;
          }

          this.setErrorMessageFromAPI(e);
        })
        .finally(() => {
          this.reloadFrame(true);
          clearTimeout(savingTimeout);
          this.isSaving = false;
        });
    },
    discard() {
      this.clearStatusMessage();
      this.resetActiveList();
      this.resetAddVariableForm();
    },
    getDisplayValue(value) {
      return value === null
        ? '********'
        : value || '<empty>';
    },
    getConfirmationDisplayValue(variable) {
      let value;
      if (variable.deleted) {
        value = '<deleted>';
      } else if (this.isEdited(variable)) {
        value = variable.editedValue;
      } else {
        value = variable.value;
      }

      if (this.isEmptyValue(value)) {
        value = '<empty>';
      }

      return value;
    },
    isNewVariable(value) {
      return value !== null;
    },
    isEmptyValue(value) {
      return value === '';
    },
    isEdited(variable) {
      return variable.editedValue !== null;
    },
    isChanged(variable) {
      return variable.deleted || this.isEdited(variable) || this.isNewVariable(variable.value);
    },
    editVariable(variable) {
      const focusInput = () => (
        this.$refs[`edit${variable.name}Input`][0]
          .$el.querySelector('input').focus()
      );
      variable.editedValue = variable.value || '';
      this.$nextTick().then(focusInput);
    },
    deleteVariable(variable) {
      variable.deleted = true;
    },
    showUndo(variable) {
      return variable.deleted || this.isEdited(variable);
    },
    undoChanges(variable) {
      variable.deleted = false;
      variable.editedValue = null;
    },
    confirmSave() {
      this.showReloadDialog = true;
    },
    closeDialog() {
      this.showReloadDialog = false;
    },
    saveConfirmation() {
      this.showReloadDialog = false;
      this.save();
    },
    reload() {
      this.showConflictDialog = false;
      return this.getVariableData().catch(this.setErrorMessageFromAPI);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';
@import 'Styles/shared/_variables';

.rs-variables {
  &__title {
    font-size: 0.9rem; // override contentPanel styling
  }

  &__form {
    display: flex;
    flex-direction: column;

    &__button {
      align-self: flex-end;
    }

    &__note {
      margin-bottom: 1.35rem;
      font-size: 0.9rem;
      line-height: 1.2rem;
      color: $color-secondary-inverse;
    }
  }

  &__field-changed {
    font-weight: 800;
    font-size: 1rem;
  }

  &__field-name {
    display: flex;

    &__text {
      flex: 1;
      align-self: center;
      line-height: 1rem;
      word-break: break-word;
      word-wrap: break-word;

      &--deleted {
        text-decoration: line-through;
      }
    }

    &__edit,
    &__delete,
    &__undo {
      padding: 0;
      background-repeat: no-repeat;
      background-position: center;
      width: $rs-icon-size;
      height: $rs-icon-size;
      background-size: $rs-icon-size $rs-icon-size;
      background-color: transparent;

      &:hover {
        background-color: $color-button-background-hover;
      }
    }

    &__edit {
      background-image: url('/images/elements/actionEdit.svg');
    }

    &__delete {
      background-image: url('/images/elements/actionDelete.svg');
    }

    &__undo {
      background-image: url('/images/elements/actionReset.svg');
    }
  }

  &__field-value {
    padding-top: 0.5rem;
    color: $color-dark-grey;
    line-height: 1rem;
    word-break: break-word;
    word-wrap: break-word;

    &--empty {
      font-style: italic;
    }
  }
}
</style>

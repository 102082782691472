// Copyright (C) 2022 by Posit Software, PBC.

import DownloadExample from './panels/DownloadExample';
import OpenExample from './panels/OpenExample';
import OpenIDE from './panels/OpenIDE';
import RunExample from './panels/RunExample';
import VetiverOutro from './panels/VetiverOutro';

export default {
  bare: [],
  copyFrom: [
    DownloadExample,
    OpenIDE,
    OpenExample,
    RunExample,
    VetiverOutro,
  ],
};

<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="wizard-panel wizard-panel__rmarkdown-add-params wizard-panel--white">
    <div class="wizard-panel__content">
      <StepHeading :step="step">
        Add Parameters
      </StepHeading>
      <p>
        Declare one or more params in the YAML header and use those params
        in your R Markdown code.
      </p>
      <p>
        <a
          href="https://rmarkdown.rstudio.com/lesson-6.html"
          target="_blank"
        >Learn more about R Markdown</a>
      </p>
    </div>
  </div>
</template>

<script>
import StepHeading from '@/views/content/PublishWizard/StepHeading';
import PanelMixin from '../PanelMixin';

export default {
  name: 'RMarkdownAddParams',
  components: { StepHeading },
  mixins: [PanelMixin],
};
</script>

<style lang="scss" scoped>
.wizard-panel {
  &__rmarkdown-add-params {
    background-image: url('./background.png');
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 100% auto;
  }
}
</style>

// Copyright (C) 2022 by Posit Software, PBC.

import DownloadExample from './panels/DownloadExample';
import RunExample from './panels/RunExample';
import PythonDependencies from './panels/PythonDependencies';
import PublishIDE from './panels/PublishIDE';
import PlumberAPIFile from './panels/PlumberAPIFile';
import VetiverOutro from './panels/VetiverOutro';

export default {
  bare: [PlumberAPIFile, PublishIDE],
  copyFrom: [
    DownloadExample,
    PythonDependencies,
    RunExample,
    VetiverOutro,
  ],
};

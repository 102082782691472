// Copyright (C) 2024 by Posit Software, PBC.

import axios from 'axios';
import { apiV1Path } from '@/utils/paths';
import { keysToCamel } from '@/api/transform';

export const getUserSessions = () => axios.get(apiV1Path('oauth/sessions')).then(({ data }) => keysToCamel(data));

export const getContentAssociations = (guid) => axios
  .get(apiV1Path(`content/${encodeURIComponent(guid)}/oauth/integrations/associations`))
  .then(({ data }) => keysToCamel(data));

// Copyright (C) 2022 by Posit Software, PBC.

import { Filters } from '@/api/content';
import AppRoles from '@/api/dto/appRole';
import { buildSelectableTreeAndPaths } from '@/components/TagsCatalogSelector/tagsCatalogUtils';
import { FilterType } from '@/store/modules/contentList';
import { getHashQueryParameter } from '@/utils/paths';

/**
 * Creates an object for a given tag query filter
 * @param {string} filter The filter string in format (tagtree|tag):100:120:... i.e: tagtree:10:20
 * @param {string} categoryName The category name/label that the filter belongs to
 * @param {Array} paths The paths and labels for the selected tags in the category
 * @returns {Object} The object representation of the filter { filter, selected, paths }
 */
export function tagQueryFilterToObject(filter, categoryName = '', paths = []) {
  if (!filter) {
    return null;
  }
  const [, ...selected] = filter.split(':').map(id => Number(id));
  return {
    categoryName,
    filter,
    selected,
    paths,
  };
}

/**
 * Updates window.location to reflect the given parameters
 * @param {Object} params Search, visibility, contentType, viewType and tags
 */
export function pushParamsToUrl({
  search,
  visibility,
  contentType,
  viewType,
  tags = {},
} = {}) {
  const params = [];
  const tagRecords = Object.keys(tags);
  if (visibility) {
    params.push(Filters.minVisibilityAppRole(AppRoles.stringOf(visibility)));
  }
  if (contentType) {
    params.push(Filters.contentType(contentType));
  }
  if (viewType) {
    params.push(`view_type=${encodeURIComponent(viewType)}`);
  }
  tagRecords.forEach(categoryId => {
    const { filter } = tags[categoryId];
    params.push(`tags=${categoryId}-${filter}`);
  });
  if (search) {
    params.push(`search=${encodeURIComponent(search)}`);
  }
  // eslint-disable-next-line no-shadow
  const { origin, pathname } = window.location;
  if (params.length) {
    const url = `${origin}${pathname}#/content/listing?${params.join('&')}`;
    // NOTE: Using window.location = ''
    // pushState and replaceState make navigation buttons to behave laggy
    // possibly due to Angular navigation using history push as well and
    // losing track of state by not using ng's $location services.
    // (two clicks needed going back and forward from content list)
    window.location = url;
  }
}

/**
 * Parses the URL to return the params. It checks for present filters
 * in the store for tags only.
 * @param {Object} storeFilters selected filters from the store
 * @returns {Array<{Array, Object}>} The URL params: Array of filters, Object of viewType
 */
function parseUrlParams(storeFilters) {
  const paramsCollection = [];
  let view = null;
  const tags = getHashQueryParameter('tags');
  const filters = getHashQueryParameter('filter');
  const viewType = getHashQueryParameter('view_type');
  const [search] = getHashQueryParameter('search') || [];
  if (filters) {
    filters.forEach(filter => {
      const [key, value] = filter.split(':');
      const filterData = {};
      switch (key) {
        case 'min_role':
          filterData.type = FilterType.VISIBILITY;
          filterData.value = AppRoles.of(value);
          break;
        case 'content_type':
          filterData.type = FilterType.CONTENT_TYPE;
          filterData.value = value;
          break;
      }
      paramsCollection.push(filterData);
    });
  }
  if (viewType) {
    view = { type: 'viewType', value: viewType[0] };
  }
  if (search) {
    paramsCollection.push({ type: FilterType.SEARCH, value: search });
  }
  if (tags) {
    const selectedTags = {};
    tags.forEach(tag => {
      const [categoryId, filter] = tag.split('-');
      selectedTags[categoryId] = tagQueryFilterToObject(filter);
    });
    paramsCollection.push({ type: FilterType.TAGS, value: selectedTags });
  } else if (storeFilters.tags && Object.keys(storeFilters.tags).length) {
    // If no tag filters were present in the URL, then get the tag filters from
    // the store. This will guarantee that any selected tag filters from the
    // store will be selected in the UI when we `buildSelectableTreeAndPaths`.
    paramsCollection.push({ type: FilterType.TAGS, value: storeFilters.tags });
  }
  return [paramsCollection, view];
}

/**
 * Extracts the tags filters to generate the selectedTags, a set of selected tag ids
 * and the selectedTagMap, a map of tagId to categoryId
 * @param {Array} filters the list of filter objects as parsed from the URL
 * @returns {Object} The object representation of { tags, selectedTags, selectedTagMap }
 */
function getTagObjects(filters) {
  const tagFilter = filters.find(({ type }) => type === FilterType.TAGS);
  const selectedTagMap = {};
  let selectedTags = new Set();
  let tags = null;

  if (tagFilter) {
    tags = tagFilter.value;
    selectedTags = new Set(Object.keys(tags).flatMap(id => tags[id].selected));

    Object.keys(tags).forEach(categoryId => {
      tags[categoryId].selected.reduce((acc, tagId) => {
        acc[tagId] = Number(categoryId);
        return acc;
      }, selectedTagMap);
    });
  }

  return { tags, selectedTags, selectedTagMap };
}

/**
 * Add missing data to the tags object like paths and categoryName
 * This is needed b/c tags read from the URL, only have ids
 * @param {Object} params selectedTagsPaths, tags
 */
function addMissingTagData({ selectedTagsPaths, tags }) {
  if (selectedTagsPaths.length && tags) {
    selectedTagsPaths.forEach(({ categoryId, categoryName, paths }) => {
      tags[categoryId].paths = paths;
      tags[categoryId].categoryName = categoryName;
    });
  }
}

/**
 * Removes a tag by category and tag id from the tags object
 * @param {Object} params tags, categoryId and tagId
 */
export function removeFilterTag({ tags, categoryId, tagId }) {
  const category = tags[categoryId];

  // remove the last tag for the category when only one left
  if (category.selected.length === 1) {
    delete tags[categoryId];
  } else {
    let index = category.selected.findIndex(id => id === tagId);
    if (index > -1) {
      category.selected.splice(index, 1);
    }

    index = category.paths.findIndex(({ id }) => id === tagId);
    if (index > -1) {
      category.paths.splice(index, 1);
    }

    category.filter = category.filter.replace(`:${tagId}`, '');
    const prefix = category.filter.split(':')[0];
    const newPrefix = category.paths.some(({ hasChildren }) => hasChildren)
      ? 'tagtree'
      : 'tag';
    category.filter = category.filter.replace(prefix, newPrefix);
  }
}

/**
 * Generates a message for the user if any tags/categories have been deleted
 * in the server
 * @param {Object} params tags, categories
 * @returns {String} A message if any tags/categories have been removed, empty otherwise
 */
function getRemovedTagsMessage({ tags, categories }) {
  let message = '';
  if (categories) {
    message += 'Could not locate one or more tag categories. <br>';
  }
  if (tags && tags.size) {
    message += 'One or more tags could not be located within the following categories: ';
    tags.forEach(categoryName => (message += `${categoryName}, `));
    message = message.replace(/, $/, '.<br>');
  }
  return `${message}Your tag filter selections have been adjusted accordingly.`;
}

/**
 * Removes any tags that have been deleted in the server from the tags object
 * and gathers the neccesary data to put together a message for the user
 * @param {Object} params tags, selectedTags, selectedTagMap, tagsTree
 * @returns {Object} The object representation of { noTagsFound, removedTagsMsg }
 */
function removeDeletedTags({ tags, selectedTags, selectedTagMap, tagsTree }) {
  let noTagsFound = false;
  let removedTagsMsg = '';

  if (selectedTags.size) {
    const removed = { tags: new Set(), categories: false };

    [...selectedTags].forEach(tagId => {
      const categoryId = selectedTagMap[tagId];
      const category = tagsTree.find(({ id }) => id === categoryId);

      if (category) {
        removed.tags.add(category.name);
      } else {
        removed.categories = true;
      }

      removeFilterTag({ tags, categoryId, tagId });
    });

    // if tags is an empty object at this point, then all URL filter tags got deleted
    // tags will be null if there were no filter tags at all included in the URL
    noTagsFound = Boolean(tags && !Object.keys(tags).length);

    if (!noTagsFound) {
      removedTagsMsg = getRemovedTagsMessage(removed);
    }
  }

  return { noTagsFound, removedTagsMsg };
}

/**
 * Parses the filters from the url, adds any missing data like category name
 * and paths to the tag filters and discards any filter tags that no longer
 * exists in the server
 * @param {Array} tagsTree the tags tree fetched from the server
 * @param {Object} storeFilters selected filters from the vuex store
 * @param {String} viewType setting of view type (compact/expanded) - state if set, server setting otherwise
 * @returns {Object} The object representation of { filters, tagsTree, removedTagsMsg, noTagsFound, view }
 */
export function handleUrlFilters(tagsTree, storeFilters, viewType) {
  let viewSetting;
  const [filters, view] = parseUrlParams(storeFilters);
  const { tags, selectedTags, selectedTagMap } = getTagObjects(filters);
  const { selectableTree, selectedTagsPaths } = buildSelectableTreeAndPaths(
    tagsTree,
    selectedTags
  );
  if (view === null) {
    viewSetting = { type: 'viewType', value: viewType };
  } else {
    viewSetting = view;
  }

  // add the paths and category name to the selected tags b/c it is not in the URL
  addMissingTagData({ selectedTagsPaths, tags });

  // anything left in the selectedTags at this point are tags that got deleted
  const { noTagsFound, removedTagsMsg } = removeDeletedTags({
    tags,
    selectedTags,
    selectedTagMap,
    tagsTree,
  });
  return { filters, tagsTree: selectableTree, removedTagsMsg, noTagsFound, viewSetting };
}

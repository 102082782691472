<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <button
    type="button"
    aria-label="Refresh Report"
    :disabled="isBusy || !isInitialized"
    title="Refresh Report"
    class="action update content-actions__refresh-button"
    data-automation="menuitem-refresh-report"
    @click="onRefresh"
  />
</template>

<script>
import { LOAD_RENDERINGS_HISTORY, SET_CONTENT_FRAME_RELOADING } from '@/store/modules/contentView';
import { CLEAR_STATUS_MESSAGE, SHOW_INFO_MESSAGE } from '@/store/modules/messages';
import { PARAMETERIZATION_REFRESH_REPORT } from '@/store/modules/parameterization';
import isEmpty from 'lodash/isEmpty';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  name: 'RefreshButton',
  computed: {
    ...mapState({
      currentVariant: state => state.parameterization.currentVariant,
      isBusy: state => state.parameterization.isBusy,
    }),
    isInitialized() {
      return !isEmpty(this.currentVariant);
    },
  },
  methods: {
    ...mapMutations({
      reloadAppFrame: SET_CONTENT_FRAME_RELOADING,
      clearStatusMessage: CLEAR_STATUS_MESSAGE,
    }),
    ...mapActions({
      refreshReport: PARAMETERIZATION_REFRESH_REPORT,
      fetchRenderings: LOAD_RENDERINGS_HISTORY,
      setInfoMessage: SHOW_INFO_MESSAGE,
    }),
    onRefresh() {
      this.setInfoMessage({
        message: 'Your request to regenerate this content has been submitted.',
        autoHide: false,
      });
      this.refreshReport().then(() => {
        this.clearStatusMessage();
        this.reloadAppFrame(true);
        this.fetchRenderings(this.currentVariant.id);
        this.setInfoMessage({ message: 'Content generation complete.' });
      });
    },
  },
};
</script>

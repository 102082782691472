<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<script setup>
import CollapsiblePanel from '@/components/CollapsiblePanel.vue';
import SlidingToggle from '@/components/SlidingToggle.vue';
import RSButton from '@/elements/RSButton.vue';
import RSInformationToggle from '@/elements/RSInformationToggle.vue';
import RSInputText from '@/elements/RSInputText.vue';
import { HIDE_LOCKED_MESSAGE_PREVIEW, PREVIEW_LOCKED_MESSAGE } from '@/store/modules/contentView';
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';

const { app } = defineProps({
  app: { type: Object, required: true },
});
const MAX_LOCKED_MESSAGE_LENGTH = 4_096;
const locked = defineModel('locked', { type: Boolean, default: false });
const lockedMessage = defineModel('lockedMessage', { type: String, default: '' });

const isDirty = ref(false);
const invalidMessage = computed(
  () =>
    isDirty.value && locked.value && lockedMessage.value?.length > MAX_LOCKED_MESSAGE_LENGTH 
      ? `Locked message is limited to ${Number(MAX_LOCKED_MESSAGE_LENGTH).toLocaleString()} characters`
      : null
);
const store = useStore();

const label = computed(
  () => locked.value ? 'Content is locked' : 'Content is unlocked'
);
const currentUser = computed(() => store.state.currentUser.user);
const canLock = computed(() => currentUser.value.isAdmin() || currentUser.value.isAppOwner(app));

watch(locked, (isLocked) => {
  if (!isLocked) {
    isDirty.value = false;
    store.commit(HIDE_LOCKED_MESSAGE_PREVIEW);
  }
});

watch(lockedMessage, () => {
  isDirty.value = true;
});

const onPreview = () => {
  store.commit(PREVIEW_LOCKED_MESSAGE, lockedMessage.value);
};
</script>

<template>
  <div
    v-if="canLock"
    class="formSection"
  >
    <RSInformationToggle>
      <template #title>
        <div class="groupHeadings">
          Content Locking
        </div>
      </template>
      <template #help>
        <p>
          You can lock this content to prevent visitors from viewing it. When you lock content, the content
          is deactivated.
        </p>

        <p>
          You can add a message to display to visitors when they attempt to view this locked content. This message
          can contain a URL for a page with updated content. You can format this message using
          <a
            target="_blank"
            href="https://commonmark.org/help/"
          >Markdown</a>.
        </p>

        Locking content has the following effects:
        <ul class="lock-content-list">
          <li>
            Locked content does not appear in content searches unless the <code>is:locked</code> search term is used.
          </li>
          <li>
            All processes associated with the content are terminated.
          </li>
          <li>
            Any schedules associated with the content are paused.
          </li>
          <li>
            On-demand rendering of locked content via the API is disabled.
          </li>
        </ul>
      </template>
    </RSInformationToggle>

    <CollapsiblePanel
      :hide-icon="true"
      :is-expanded="locked"
    >
      <SlidingToggle
        v-model="locked"
        color="#1d6ebc"
        :label="label"
        data-automation="settings-lock-content"
      />

      <template #content>
        <div class="message-container">
          <div
            class="rsc-row"
            :class="{ 'hasError' : !!invalidMessage }"
          >
            <RSInputText
              v-model="lockedMessage"
              name="lockMessage"
              :lines="6"
              :message="invalidMessage"
              placeholder="Add a message for visitors to this locked content."
              label="Locked Content Message"
              data-automation="settings-lock-message"
            />
          </div>
          <div class="button-row">
            <RSButton
              :use-label-width="true"
              data-automation="settings-lock-preview"
              label="Preview"
              size="small"
              type="secondary"
              @click="onPreview"
            />
          </div>
        </div>
      </template>
    </CollapsiblePanel>
  </div>
</template>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';
@import 'Styles/shared/_mixins';

.formSection {
  .message-container {
    margin-top: 1rem;

    .rsc-row {
      padding: 2px;
    }
  }
}

.groupHeadings {
  color: $color-heading;
  letter-spacing: .1em;
  font-size: 1em;
  text-transform: uppercase;
  margin-bottom: 0.5em;
}

.collapsible-container {
  margin-top: 1rem;
}

.lock-content-list {
  list-style: disc;
}

.button-row {
  display: flex;
  justify-content: flex-end;
  margin: 0 2px 2px 0;

  button {
    padding: 0.5rem 1rem;
  }
}
</style>

// Copyright (C) 2022 by Posit Software, PBC.

import { createStore } from 'vuex';
import publish from '@/store/modules/publish';
import deployWizard from './modules/deployWizard';
import jumpstart from '@/store/modules/jumpstart';
import contentList from '@/store/modules/contentList';
import accessSettings from '@/store/modules/accessSettings';
import authOriginHelp from '@/store/modules/authOriginHelp';
import infoSettings from '@/store/modules/infoSettings';
import logsOverlay from '@/store/modules/logsOverlay';
import serverNotices from '@/store/modules/serverNotices';
import parameterization from '@/store/modules/parameterization';
import legacyParams from '@/store/modules/legacyParams';
import server from '@/store/modules/server';
import currentUser from '@/store/modules/currentUser';
import contentView from '@/store/modules/contentView';
import bundles from '@/store/modules/bundles';
import messages from '@/store/modules/messages';
// eslint-disable-next-line no-shadow
import navigation from '@/store/modules/navigation';
import adminTags from '@/store/modules/adminTags';

export const store = createStore({
  modules: {
    publish,
    deployWizard,
    jumpstart,
    contentList,
    accessSettings,
    authOriginHelp,
    infoSettings,
    logsOverlay,
    parameterization,
    legacyParams,
    serverNotices,
    server,
    currentUser,
    contentView,
    bundles,
    messages,
    navigation,
    adminTags,
  },
   
  strict: process.env.NODE_ENV === 'development',
  plugins: [],
});

// Copyright (C) 2023 by Posit Software, PBC.

import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';

dayjs.extend(calendar);
export class ApiKey {
  constructor({
    id,
    key,
    name: keyName,
    userId,
    created,
    lastUsedTime,
  }) {
    this.id = id;
    this.key = key;
    this.name = keyName;
    this.userId = userId;
    this.created = dayjs(created).calendar();
    this.lastUsed = dayjs(lastUsedTime).calendar();
  }
}

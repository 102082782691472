// Copyright (C) 2022 by Posit Software, PBC.

const AppModes = {
  Unknown: 'unknown',
  Shiny: 'shiny',
  ShinyRmd: 'rmd-shiny',
  StaticRmd: 'rmd-static',
  Static: 'static',
  PlumberApi: 'api',
  TensorFlowApi: 'tensorflow-saved-model',
  StaticJupyter: 'jupyter-static',
  PythonApi: 'python-api',
  PythonDash: 'python-dash',
  PythonStreamlit: 'python-streamlit',
  PythonBokeh: 'python-bokeh',
  PythonFastAPI: 'python-fastapi',
  ShinyQuarto: 'quarto-shiny',
  StaticQuarto: 'quarto-static',
  PythonShiny: 'python-shiny',
  JupyterVoila: 'jupyter-voila',

  // we need both sets of cases because v0 serves a number
  // and v1 serves a string
  // eslint-disable-next-line complexity
  of(value) {
    switch (value) {
      case 0:
      case 'unknown':
        return AppModes.Unknown;
      case 1:
      case 'shiny':
        return AppModes.Shiny;
      case 2:
      case 'rmd-shiny':
        return AppModes.ShinyRmd;
      case 3:
      case 'rmd-static':
        return AppModes.StaticRmd;
      case 4:
      case 'static':
        return AppModes.Static;
      case 5:
      case 'api':
        return AppModes.PlumberApi;
      case 6:
      case 'tensorflow-saved-model':
        return AppModes.TensorFlowApi;
      case 7:
      case 'jupyter-static':
        return AppModes.StaticJupyter;
      case 8:
      case 'python-api':
        return AppModes.PythonApi;
      case 9:
      case 'python-dash':
        return AppModes.PythonDash;
      case 10:
      case 'python-streamlit':
        return AppModes.PythonStreamlit;
      case 11:
      case 'python-bokeh':
        return AppModes.PythonBokeh;
      case 12:
      case 'python-fastapi':
        return AppModes.PythonFastAPI;
      case 13:
      case 'quarto-shiny':
        return AppModes.ShinyQuarto;
      case 14:
      case 'quarto-static':
        return AppModes.StaticQuarto;
      case 15:
      case 'python-shiny':
        return AppModes.PythonShiny;
      case 16:
      case 'jupyter-voila':
        return AppModes.JupyterVoila;
      default:
        return AppModes.Unknown;
    }
  },
};

export default Object.freeze(AppModes);

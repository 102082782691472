<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<!--
  Renders the routable ServerSettingsTogglerView container

  The ServerSettingsTogglerView is an internal view that lets developers alter
  the client-side value for many of our feature flags, which are managed within
  the serverSettings object.
-->
<template>
  <div>
    <div class="flex">
      <h1
        ref="title"
        class="sectionTitle focusedTitle"
        tabindex="-1"
      >
        Server Settings - Boolean Flags
      </h1>
    </div>
    <div class="section">
      <div>
        <p>
          Changes made through this interface are updated immediately within
          <br>this client instance only and are reset upon reload or requery of the
          <br>server settings endpoint.
        </p>The server database / gcfg file is
        <b>NOT</b> being updated!
      </div>
      <table class="padded">
        <tr>
          <th class="keyColumn">
            <b>Configuration [Server] section key</b>
          </th>
          <th>
            <b>Activated (true)</b>
          </th>
        </tr>
        <tr
          v-for="key in booleanServerFlags"
          :key="key"
        >
          <td class="padded keyColumn">
            {{ key }}
          </td>
          <td class="center padded">
            <label
              class="rs-checkbox__label"
              :aria-label="key"
              :for="key"
            >
              <input
                :id="key"
                class="rs-checkbox__input"
                :data-server-key="key"
                :checked="serverSettings[key]"
                :aria-label="'serverSettings.' + key + ' configuration setting'"
                type="checkbox"
                @change="onChangeServerFlag"
              >
            </label>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { SERVER_SETTINGS_SET_VALUE } from '@/store/modules/server';
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'ServerSettingsTogglerView',
  computed: {
    booleanServerFlags() {
      // We only handle boolean values right now, so filter everything
      // other types.
      // Also filter out keys which start with '$'
      return Object.keys(this.serverSettings)
        .filter(key => {
          return typeof this.serverSettings[key] === 'boolean' && !key.startsWith('$');
        })
        .sort();
    },
    ...mapState({
      serverSettings: state => state.server.settings
    })
  },
  mounted() {
    this.$refs.title.focus();
  },
  methods: {
    // handle the on-change event from each of the checkbox controls
    // associated with the server configuration flags
    onChangeServerFlag({ srcElement: { dataset: { serverKey: key }, checked } }) {
      this.setValue({ key, checked });
    },
    ...mapMutations({
      setValue: SERVER_SETTINGS_SET_VALUE,
    })
  },
};
</script>

<style scoped>
tr:nth-child(even) {
  background: #ccc;
}
tr:nth-child(odd) {
  background: #fff;
}

.keyColumn {
  min-width: 300px;
}

.padded {
  padding: 5px;
  margin: 25px;
}
.center {
  align-content: center;
}
</style>

<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div
    class="formSection infoSection"
    data-automation="schedule-tab__not-schedulable"
  >
    <p
      v-if="isWorker"
      data-automation="schedule-tab__not-schedulable-worker"
    >
      This is
      <span>{{ noSchedulableAppDesc }}</span>
      so there is no need to render on a schedule - it will present the latest data whenever it is run.
    </p>

    <p
      v-if="isStatic"
      data-automation="schedule-tab__not-schedulable-static"
    >
      The source code for this content was not published. The output cannot be refreshed.
    </p>
    <p
      v-if="isUnknown"
      data-automation="schedule-tab__not-schedulable-unknown"
    >
      This content cannot be refreshed.
    </p>
  </div>
</template>

<script>
import AppModes from '@/api/dto/appMode';
import { mapState } from 'vuex';

const contentDescLocaleKey = appmode => {
  switch (appmode) {
    case AppModes.Shiny:
      return 'shiny';
    case AppModes.ShinyRmd:
    case AppModes.ShinyQuarto:
      return 'interactiveShiny';
    case AppModes.StaticRmd:
      return 'staticRmd';
    case AppModes.Static:
      return 'static';
    case AppModes.PlumberAPI:
    case AppModes.PythonAPI:
      return 'api';
    case AppModes.PythonDash:
      return 'dash';
    case AppModes.PythonStreamlit:
      return 'streamlit';
    case AppModes.PythonBokeh:
      return 'bokeh';
    case AppModes.PythonFastAPI:
      return 'fastapi';
    case AppModes.PythonShiny:
      return 'shinyPython';
    case AppModes.JupyterVoila:
      return 'voila';
    case AppModes.TensorFlow:
      return 'tensorflow';
    case AppModes.StaticJupyter:
      return 'jupyter';
    case AppModes.StaticQuarto:
      return 'staticQuarto';
    default:
      return 'unknown';
  }
};

const contentDescriptions = {
  unknown: 'incomplete content',
  shiny: 'an interactive Shiny application',
  staticRmd: 'an R Markdown document',
  interactiveShiny: 'an interactive Shiny document', // shiny quarto too
  static: 'a document',
  api: 'an API-hosting application',
  dash: 'an interactive Dash application',
  streamlit: 'an interactive Streamlit application',
  bokeh: 'an interactive Bokeh application',
  fastapi: 'a FastAPI application',
  shinyPython: 'a Python Shiny application',
  voila: 'a Voilà interactive notebook',
  tensorflow: 'a TensorFlow Model API',
  jupyter: 'a Jupyter notebook',
  staticQuarto: 'Quarto content',
};

export default {
  name: 'NotSchedulableMsg',
  computed: {
    ...mapState({
      app: state => state.contentView.app,
    }),
    isWorker() {
      return this.app.hasWorker();
    },
    isStatic() {
      return this.app.isStatic();
    },
    isUnknown() {
      return this.app.appMode === AppModes.Unknown;
    },
    noSchedulableAppDesc() {
      const modeKey = contentDescLocaleKey(this.app.appMode);
      return contentDescriptions[modeKey];
    },
  },
};
</script>

<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <!-- eslint-disable vuejs-accessibility/no-static-element-interactions -->
  <!--
    To maintain backwards functionality,
    catch the escape key from any open tab to return to the default access tab.
  -->
  <div
    id="settingsPanel"
    class="contentPanel"
    data-automation="app-settings-main-pane"
    @keydown.esc.prevent.stop="resetToAccessTab"
  >
    <!-- eslint-enable vuejs-accessibility/no-static-element-interactions -->

    <div
      v-if="renderPanelComponents"
      class="appsettings settingsPane"
    >
      <div class="formSection">
        <div
          v-if="!appHasError"
          class="tabs"
          role="tablist"
          data-automation="app-settings-tablist"
        >
          <!-- eslint-disable vuejs-accessibility/interactive-supports-focus -->
          <!-- Already focusable and can be navigated with keyboard. Eslint keeps complaining -->
          <span
            v-for="(tab, i) in tabs"
            :id="`settings-tab-${i}`"
            :key="tab.id"
            :ref="tab.id"
            :tabindex="i === 0 ? 0 : -1"
            :data-automation="`app-settings-tab__${tab.id}`"
            type="button"
            role="tab"
            :class="['tab', tab.id, { current: currentRouteName.includes(tab.route) }]"
            :aria-selected="currentRouteName.includes(tab.route)"
            @keydown.enter.prevent.stop="navigateTo(tab)"
            @keydown.space.prevent.stop="navigateTo(tab)"
            @keydown.left.prevent.stop="focusTab(i - 1)"
            @keydown.right.prevent.stop="focusTab(i + 1)"
            @click="navigateTo(tab)"
          >
            {{ tab.name }}
          </span>
          <!-- eslint-enable vuejs-accessibility/interactive-supports-focus -->
        </div>
      </div>

      <!-- Tab content -->
      <RouterView
        v-if="isAuthorized"
        name="settings"
      />
    </div>
    <div
      v-if="appHasError"
      class="notfound"
      data-automation="app-settings-not-found"
    >
      <div
        v-if="requiresAuth"
        data-automation="app-settings-not-found__no-auth"
      >
        <h2>You're Not Logged In</h2>
        <p class="rsc-lead">
          You must log in to view and edit content settings.
        </p>
        <a
          class="button default"
          :href="loginUrl"
        >
          Log In
        </a>
      </div>
      <div
        v-else
        data-automation="app-settings-not-found__generic"
      >
        <h2>Not found</h2>
        <p class="rsc-lead">
          This application is missing or not visible to you.
        </p>
        <p>
          The application may have been deleted, or you may have followed an incorrect link.
        </p>
        <RouterLink
          class="button default"
          :to="{ name: 'contentList' }"
        >
          Back to content list
        </RouterLink>
      </div>
    </div>
  </div>
</template>

<script>
import { loginPath } from '@/utils/paths';
import { debounce, isEmpty } from 'lodash';
import { mapState } from 'vuex';
import { RouterLink, RouterView } from 'vue-router';

export const TabRoute = {
  Info: 'apps.info',
  Access: 'apps.access',
  Runtime: 'apps.runtime',
  Output: 'apps.output',
  Tags: 'apps.tags',
  Environment: 'apps.environment',
  isVariantApplicable(route) {
    // runtime and environment tabs
    // doesn't have variant specific routes.
    return (
      route !== TabRoute.Runtime &&
      route !== TabRoute.Environment
    );
  },
};

export default {
  name: 'ContentSettingsPane',
  components: {
    RouterLink,
    RouterView,
  },
  data() {
    return {
      tabs: [
        {
          id: 'info',
          name: 'Info',
          route: TabRoute.Info,
        },
        {
          id: 'access',
          name: 'Access',
          route: TabRoute.Access,
        },
        {
          id: 'runtime',
          name: 'Runtime',
          route: TabRoute.Runtime,
        },
        {
          id: 'schedule',
          name: 'Schedule',
          route: TabRoute.Output,
        },
        {
          id: 'tags',
          name: 'Tags',
          route: TabRoute.Tags,
        },
        {
          id: 'environmentVariables',
          name: 'Vars',
          route: TabRoute.Environment,
        },
      ],
      renderPanelComponents: true,
    };
  },
  computed: {
    ...mapState({
      app: state => state.contentView.app,
      appError: state => state.contentView.appError,
      requiresAuth: state => state.contentView.requiresAuth,
      isAuthorized: state => !state.contentView.isUnauthorized,
      currentVariant: state => state.parameterization.currentVariant,
      showSettingsPanel: state => state.contentView.showSettingsPanel,
    }),
    appHasError() {
      return this.requiresAuth || !!this.appError;
    },
    loginUrl() {
      return loginPath({ redirect: location.href });
    },
    currentRouteName() {
      return this.$route.name;
    },
    useVariantRouting() {
      return !isEmpty(this.currentVariant);
    },
  },
  watch: {
    // debounce is used to here to prevent jankiness that results
    // when the settings panel is closed and the settings components
    // disappear before the animation is complete. `debounce` instead of
    // `delay` or `setTimeout` to prevent the possibility of rapid clicking
    // causing the settings panel to appear with no components.
    showSettingsPanel() {
      debounce(function() {
        if (!this.showSettingsPanel) {
          this.renderPanelComponents = false;
          const routeParams = { idOrGuid: this.app.guid };
          this.$router.push({
            name: 'apps',
            params: routeParams,
            replace: true,
          });
          return;
        }
        this.renderPanelComponents = true;
        // default to first tab on render
        this.resetToAccessTab();
      }, 100).apply(this);
    },
  },
  created() {
    this.renderPanelComponents = this.showSettingsPanel;
  },
  methods: {
    navigateTo(tab) {
      const routeParams = { idOrGuid: this.app.guid };
      let routeName = tab.route;
      if (this.useVariantRouting && TabRoute.isVariantApplicable(tab.route)) {
        routeName += '.variant';
        routeParams.id = this.currentVariant.id;
      }
      this.$router.push({
        name: routeName,
        params: routeParams,
        replace: true,
      });
    },
    focusTab(index) {
      if (index < 0 || index === this.tabs.length) {
        return;
      }
      const tabToFocus = this.tabs[index].id;
      this.$refs[tabToFocus][0].focus();
    },
    resetToAccessTab() {
      this.navigateTo(this.tabs[1]);
      this.$nextTick().then(() => this.focusTab(1));
    },
  },
};
</script>

<style lang="scss" scoped>
.notfound {
  position: relative;
  background-color: #f8f8f8;
}
</style>

<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div
    class="nothingEmbedded"
    data-automation="no-content-frame-msg"
  >
    <div
      v-if="!isDeployed"
      data-automation="undeployed-content-msg"
    >
      <h1 class="undeployed">
        <div class="typeUnpublished" />
        This {{ appProse }} is incomplete.
      </h1>

      <p class="undeployed">
        This {{ appType }} has not been fully deployed. Please try publishing again.
      </p>

      <p
        v-if="canViewLogs"
        class="unrendered"
      >
        View the
        <a
          data-automation="content-log-link"
          href="_"
          @click="showLogs"
          @keypress.enter="showLogs"
        >
          Logs
          <span class="msg-logs-icon" />
        </a>
        for errors.
      </p>
    </div>

    <div
      v-if="isUnrendered"
      data-automation="unrendered-variant-msg"
    >
      <h1 class="unrendered">
        <div class="typeDoc" />
        This output has not been rendered.
      </h1>

      <div class="wrapText">
        <p
          v-if="canViewLogs"
          class="unrendered"
        >
          View the
          <a
            data-automation="content-log-link"
            href="_"
            @click="showLogs"
            @keypress.enter="showLogs"
          >
            Logs
            <span class="msg-logs-icon" />
          </a>
          for errors.
        </p>

        <p
          v-if="hasParams"
          class="unrendered"
          data-automation="unrendered-variant-param-msg"
        >
          You can render content by clicking the
          <span class="emphasize">Refresh Report</span>
          button on the right of the header or by opening the
          <span class="emphasize">Input</span>
          tab on the left and clicking the
          <span class="emphasize">Run Report</span>
          button. You
          can schedule the report to be rendered automatically by clicking the
          <span class="emphasize">Schedule</span>
          tab in the settings on the right.
        </p>

        <p
          v-else
          class="unrendered"
          data-automation="unrendered-variant-nonparam-msg"
        >
          You can render content by clicking the
          <span class="emphasize">Refresh Report</span>
          button on the right of the header. You can schedule the report to be
          rendered automatically by clicking the
          <span class="emphasize">Schedule</span>
          tab in the settings on the right.
        </p>
        <p class="unrendered">
          If rendering fails repeatedly, the source may contain an
          error or may not run in the deployed environment. Deploy the modified
          source after resolving the rendering issue.
        </p>
      </div>
    </div>

    <div
      v-if="isDeployed && !isPublished"
      data-automation="unpublished-content-msg"
    >
      <h1 class="unpublished">
        <div class="typeUnpublished" />
        This {{ appProse }} has not been published.
      </h1>

      <p class="unpublished">
        Please confirm the sharing configuration for the content and use the
        <span class="emphasize">Publish</span>
        button to publish this content.
      </p>

      <p
        v-if="userIsAdmin"
        class="unpublished"
        data-automation="admin-unpublished-msg"
      >
        Administrators can enumerate all
        <a
          :title="unpublishedLink.title"
          :href="unpublishedLink.link"
        >
          unpublished items that match the
          <code>published:false</code> filter
        </a>
        in the content search view.
      </p>
    </div>
  </div>
</template>

<script>
import { ContentTypeDescriptions } from '@/constants/contentTypes';
import { unpublishedAppsSearchPath } from '@/utils/paths';
import upperFirst from 'lodash/upperFirst';

export default {
  name: 'RenderMessage',
  props: {
    contentType: {
      type: String,
      required: true,
    },
    isDeployed: {
      type: Boolean,
      required: true,
    },
    isPublished: {
      type: Boolean,
      required: true,
    },
    isUnrendered: {
      type: Boolean,
      required: true,
    },
    hasParams: {
      type: Boolean,
      required: true,
    },
    userIsAdmin: {
      type: Boolean,
      required: true,
    },
    canViewLogs: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['showLogs'],
  computed: {
    appType() {
      return upperFirst(ContentTypeDescriptions[this.contentType]);
    },
    appProse() {
      return ContentTypeDescriptions[this.contentType];
    },
    unpublishedLink() {
      return {
        href: unpublishedAppsSearchPath(),
        title: 'Unpublished Content Listing Search',
      };
    },
  },
  methods: {
    showLogs(ev) {
      ev.preventDefault();
      this.$emit('showLogs');
    },
  },
};
</script>

<style scoped>
.msg-logs-icon {
  background-image: url(/images/elements/actionLogs.svg);
  background-repeat: no-repeat;
  background-size: 1.5rem;
  display: inline-block;
  height: 1.5rem;
  vertical-align: bottom;
  width: 1.5rem;
}
</style>

<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div
    v-if="show"
    class="rs-action"
  >
    <RSButton
      ref="openButton"
      label="Edit Profile"
      data-automation="ep-trigger"
      @click="reset(true)"
    />
    <RSModalForm
      v-if="form.visible"
      :active="true"
      subject="Edit Profile"
      @close="reset(false)"
      @submit="updateProfile"
    >
      <template #content>
        <EmbeddedStatusMessage
          v-if="status.message"
          :message="status.message"
          :type="status.type"
          data-automation="ucd-status-message"
          @close="status.message = ''"
        />
        <fieldset :disabled="processing">
          <RSInputText
            v-if="profileFieldPermissions.username.readable"
            ref="username"
            v-model.trim="form.username"
            :disabled="!profileFieldPermissions.username.writable"
            :help="profileFieldPermissions.username.helperMessage"
            label="Username"
            :message="errorMessage('username')"
            data-automation="ep-username"
            name="ep-username"
          />
          <RSInputText
            v-if="profileFieldPermissions.firstName.readable"
            ref="firstName"
            v-model.trim="form.firstName"
            :disabled="!profileFieldPermissions.firstName.writable"
            :help="profileFieldPermissions.firstName.helperMessage"
            label="First Name"
            data-automation="ep-first-name"
            name="ep-first-name"
          />
          <RSInputText
            v-if="profileFieldPermissions.lastName.readable"
            ref="lastName"
            v-model.trim="form.lastName"
            :disabled="!profileFieldPermissions.lastName.writable"
            :help="profileFieldPermissions.lastName.helperMessage"
            label="Last Name"
            data-automation="ep-last-name"
            name="ep-last-name"
          />
          <RSInputText
            v-if="profileFieldPermissions.email.readable"
            ref="email"
            v-model.trim="form.email"
            :disabled="!profileFieldPermissions.email.writable"
            :help="profileFieldPermissions.email.helperMessage"
            label="Email"
            :message="errorMessage('email')"
            data-automation="ep-email"
            name="ep-email"
            type="email"
          />
          <RSInputSelect
            v-if="profileFieldPermissions.userRole.readable"
            ref="userRole"
            v-model="form.userRole"
            :disabled="!profileFieldPermissions.userRole.writable"
            :options="roleOptions"
            label="Role"
            data-automation="ep-user-role"
            name="ep-user-role"
          />
        </fieldset>
      </template>
      <template #controls>
        <div class="controls">
          <RSButton
            label="Cancel"
            type="secondary"
            data-automation="ep-cancel"
            @click="reset(false)"
          />

          <RSButton
            label="Save Changes"
            data-automation="ep-update"
          />
        </div>
      </template>
    </RSModalForm>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';

import RSButton from '@/elements/RSButton';
import RSInputSelect from '@/elements/RSInputSelect';
import RSInputText from '@/elements/RSInputText';
import RSModalForm from '@/elements/RSModalForm';

import UserRoles from '@/api/dto/userRole';
import { safeAPIErrorMessage } from '@/api/error';
import { updateUser } from '@/api/users';
import EmbeddedStatusMessage from '@/components/EmbeddedStatusMessage';
import { ReauthenticationInProgressError } from '@/components/RestrictedAccessWrapper';
import { SHOW_INFO_MESSAGE } from '@/store/modules/messages';
import { ValidationMessages } from '@/utils/validation';
import { emailValidator, usernameValidator } from '@/utils/validators';
import upperFirst from 'lodash/upperFirst';
import { mapActions } from 'vuex';

export default {
  name: 'EditProfile',
  components: {
    EmbeddedStatusMessage,
    RSButton,
    RSInputText,
    RSInputSelect,
    RSModalForm,
  },
  props: {
    executeRestrictedApi: {
      type: Function,
      required: true,
    },
    currentUser: {
      type: Object,
      required: true,
    },
    serverSettings: {
      type: Object,
      required: true,
    },
    profileFieldPermissions: {
      type: Object,
      required: true,
    },
    userProfile: {
      type: Object,
      required: true,
    },
  },
  emits: ['profileUpdated'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      processing: false,
      activityTimeoutId: null,
      status: {
        show: false,
        message: null,
        type: null,
      },
      form: {
        email: '',
        firstName: '',
        lastName: '',
        userRole: '',
        username: '',
        visible: false,
      },
    };
  },
  computed: {
    roleOptions() {
      return this.profileFieldPermissions.userRole.roles.reduce(
        (options, { role, available }) => {
          if (available) {
            options.push({
              value: role,
              label: upperFirst(role),
            });
          }
          return options;
        },
        []
      );
    },
    show() {
      // only show component if at least one field is editable

      // The field values below will be one of UserInfoEditableType (provider, admin, adminandself)
      // provider = cannot edit
      // admin = admin can edit
      // adminandself = admin or profile owner can edit
      const {
        authentication: {
          nameEditableBy,
          emailEditableBy,
          usernameEditableBy,
          roleEditableBy,
        },
      } = this.serverSettings;

      const editableFieldValues = [
        nameEditableBy,
        emailEditableBy,
        usernameEditableBy,
        roleEditableBy,
      ];

      // is some field editable by an admin or profile owner?
      const adminOrProfileOwnerEditable = editableFieldValues.some(
        v => v === 'adminandself'
      );

      // is some field editable by the admin?
      const adminEditable =
        this.currentUser.userRole === UserRoles.Admin &&
        editableFieldValues.some(v => v !== 'provider');

      return adminEditable || adminOrProfileOwnerEditable;
    },
  },
  methods: {
    ...mapActions({
      setInfoMessage: SHOW_INFO_MESSAGE,
    }),
    updateProfile() {
      this.v$.form.$touch();
      if (this.v$.form.$invalid) {
        // invalid form, bail
        return;
      }

      this.processing = true;
      this.activityTimeoutId = setTimeout(
        () => this.showStatusMessage(
          'activity',
          'Updating Profile...'
        ),
        300
      );

      // API call
      this.executeRestrictedApi(
        updateUser(this.userProfile.guid, {
          username: this.form.username,
          firstName: this.form.firstName,
          lastName: this.form.lastName,
          email: this.form.email,
          userRole: this.form.userRole,
        })
      )
        .then(() => {
          this.setInfoMessage({
            message: 'Successfully updated user profile.',
          });
          this.$emit('profileUpdated');
          this.reset(false);
          return null;
        })
        .catch(err => {
          if (!(err instanceof ReauthenticationInProgressError)) {
            this.showStatusMessage('error', safeAPIErrorMessage(err));
          }
        })
        .finally(() => {
          this.processing = false;
          clearTimeout(this.activityTimeoutId);
        });
    },
    getInitialFocus(isShowing) {
      if (!isShowing) {
        return this.$refs.openButton;
      }
      if (this.profileFieldPermissions.username.readable) {
        return this.$refs.username;
      }
      if (this.profileFieldPermissions.firstName.readable) {
        return this.$refs.firstName;
      }
      if (this.profileFieldPermissions.lastName.readable) {
        return this.$refs.lastName;
      }
      if (this.profileFieldPermissions.email.readable) {
        return this.$refs.email;
      }
      if (this.profileFieldPermissions.userRole.readable) {
        return this.$refs.userRole;
      }
    },
    errorMessage(field) {
      const fieldValidators = this.v$.form[field];

      if (!fieldValidators.$error) {
        // no error
        return null;
      }

      const firstFail = fieldValidators.$errors[0].$validator;
      return ValidationMessages[field][firstFail];
    },
    showStatusMessage(type, message) {
      this.status.show = true;
      this.status.type = type;
      this.status.message = message;
    },
    reset(show) {
      this.showStatusMessage('', '');
      this.form.username = this.userProfile.username;
      this.form.firstName = this.userProfile.firstName;
      this.form.lastName = this.userProfile.lastName;
      this.form.email = this.userProfile.email;
      this.form.userRole = UserRoles.stringOf(this.userProfile.userRole);
      this.form.visible = show;

      this.$nextTick().then(() => {
        const focusRef = this.getInitialFocus(show);
        focusRef && focusRef.focusElement();
      });
    },
  },
  validations() {
    return formValidations.call(this);
  },
};

// returns validators for fields in the form.
// Note: building the validators is extracted outside the vue component so the
// set of validators is accessible from within the `errorMessage` component
// method and the `validations` vuelidate mixin-method - component methods
// cannot call the `validations()` mixin-method.
function formValidations() {
  const validations = { form: {} };
  const { username, email } = this.profileFieldPermissions;

  if (username.readable) {
    validations.form.username = usernameValidator(this.serverSettings);
  }
  validations.form.email = email.writable ? emailValidator() : true;

  return validations;
}
</script>

<style lang="scss" scoped>
.rsc-status {
  margin-bottom: 0.9rem;
}
.rs-button {
  min-width: 5.75rem;
}
.controls {
  display: flex;
  justify-content: flex-end;

  button {
    margin-left: 1rem;
  }
}
</style>

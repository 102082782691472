<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <BaseHistory
    :type="type"
    :items="items"
    :displayed-id="displayedId"
    @display-item="item => onDisplayItem(item.id)"
    @toggle-history="onToggleHistory"
  />
</template>

<script>
import BaseHistory, { BUNDLE_HISTORY } from './BaseHistory';
import { mapState, mapMutations } from 'vuex';
import {
  TOGGLE_BUNDLES_HISTORY,
  SET_BUNDLES_DISPLAYED_ID,
} from '@/store/modules/bundles';

export default {
  name: 'BundleHistory',
  components: {
    BaseHistory,
  },
  data() {
    return {
      type: BUNDLE_HISTORY,
    };
  },
  computed: {
    ...mapState({
      items: state => state.bundles.items,
      displayedId: state => state.bundles.displayedId,
    }),
  },
  methods: {
    ...mapMutations({
      onToggleHistory: TOGGLE_BUNDLES_HISTORY,
      onDisplayItem: SET_BUNDLES_DISPLAYED_ID,
    }),
  },
};
</script>

// Copyright (C) 2022 by Posit Software, PBC.

import DownloadExample from './panels/DownloadExample';
import OpenExample from './panels/OpenExample';
import OpenIDE from './panels/OpenIDE';
import PublishIDE from './panels/PublishIDE';
import RMarkdownFile from './panels/RMarkdownFile';
import RMarkdownIntro from './panels/RMarkdownIntro';
import RMarkdownOutro from './panels/RMarkdownOutro';
import RunExample from './panels/RunExample';

export default {
  bare: [RMarkdownIntro, OpenIDE, RMarkdownFile, PublishIDE, RMarkdownOutro],
  copyFrom: [
    DownloadExample,
    OpenIDE,
    OpenExample,
    RunExample,
    PublishIDE,
    RMarkdownOutro,
  ],
};

// Copyright (C) 2022 by Posit Software, PBC.

import DownloadExample from './panels/DownloadExample';
import OpenExample from './panels/OpenExample';
import OpenIDE from './panels/OpenIDE';
import PinEnvironment from './panels/PinEnvironment';
import PublishIDE from './panels/PublishIDE';
import RMarkdownFile from './panels/RMarkdownFile';
import RMarkdownIntro from './panels/RMarkdownIntro';
import PinOutro from './panels/PinOutro';
import RunExample from './panels/RunExample';

export default {
  bare: [RMarkdownIntro, OpenIDE, RMarkdownFile, PublishIDE, PinOutro],
  copyFrom: [
    DownloadExample,
    OpenIDE,
    OpenExample,
    PinEnvironment,
    RunExample,
    PublishIDE,
    PinOutro,
  ],
};

<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div>
    <div class="rsc-metrics__graph-header">
      <div>
        <div class="flexSpaceAfter">
          <div class="sectionTitle small">
            {{ label }}
          </div>
          <div class="actionBar inline">
            <ToggleButton
              v-if="hasHelp"
              :is-on="showHelp"
              toggle-class="toggleInfo"
              aria-label="More Information"
              @toggled="toggleShowHelp"
            />
          </div>
        </div>
        <div data-automation="metrics-chart-usage">
          {{ usage }}
        </div>
      </div>
      <meter
        v-if="hasMax"
        :aria-label="`${label} usage`"
        :value="meterValue"
        :high="high"
        :low="low"
        :max="max"
        :min="0"
        :optimum="0"
        class="rsc-metrics__graph-meter"
        data-automation="metrics-chart-meter"
        tabindex="0"
      />
    </div>
    <div
      v-show="showHelp"
      class="toggleableInfo"
    >
      <p>
        {{ helpText }}
      </p>
    </div>
    <div
      v-show="hasMaxNamedUsersWarning"
      class="toggleableInfo warning"
    >
      <p>
        Oops, it looks like you have more than your licensed number of active users!
        Please contact <a :href="salesEmailLink">
          {{ salesEmail }}
        </a>
      </p>
    </div>
  </div>
</template>

<script>
import ToggleButton from '@/components/ToggleButton';
import { SALES_EMAIL } from '@/constants/contacts';
import pluralize from '@/utils/pluralize';
import round from 'lodash/round';

const chartTypes = {
  cpu: 'CPU',
  namedUsers: 'Named Users',
  ram: 'RAM',
  shinyConnections: 'Shiny Connections',
};

export default {
  name: 'ChartHeader',
  components: { ToggleButton },
  props: {
    type: {
      type: String,
      required: true,
    },
    max: {
      type: Number,
      default: 0,
    },
    value: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      salesEmail: SALES_EMAIL,
      showHelp: false,
    };
  },
  computed: {
    helpText() {
      if (this.isNamedUsersChart) {
        return `This graph tracks the number of accounts with recent activity. 
          Accounts are not counted when locked.`;
      }
      if (this.isShinyConnectionsChart) {
        return this.hasMax ?
          `Connect restricts new anonymous Shiny users when the number of total 
           concurrent Shiny users is in excess of this limit. The graph displays average values.`
          : 'Tracks the number of concurrent Shiny users. The graph displays average values.';
      }

      return null;
    },
    label() {
      return chartTypes[this.type];
    },
    salesEmailLink() {
      return `mailto:${this.salesEmail}`;
    },
    isCpuChart() {
      return this.type === 'cpu';
    },
    isNamedUsersChart() {
      return this.type === 'namedUsers';
    },
    isRamChart() {
      return this.type === 'ram';
    },
    isShinyConnectionsChart() {
      return this.type === 'shinyConnections';
    },
    hasHelp() {
      return Boolean(this.helpText);
    },
    hasMax() {
      return this.max > 0;
    },
    hasMaxNamedUsersWarning() {
      return this.isNamedUsersChart && this.hasMax && this.value > this.max;
    },
    usage() {
      const { value, max } = this;

      if (this.hasMax) {
        switch (this.type) {
          case 'cpu':
            return `using ${value.toFixed(2)} of ${max} cores`;
          case 'ram':
            return `using ${value.toFixed(2)} GiB of ${max} GiB`;
          default:
            return `${value} of ${max} allowed`;
        }
      }

      return `${value} ${pluralize(value, 'user', 'users')}`;
    },
    meterValue() {
      return round(this.value, 2);
    },
    low() {
      return this.max * 0.25;
    },
    high() {
      return this.max * 0.75;
    },
  },
  methods: {
    toggleShowHelp() {
      this.showHelp = !this.showHelp;
    },
  },
};
</script>

<style scoped lang="scss">
.rsc-metrics {
  &__graph {
    width: calc(50% - 1.2rem);
    margin-bottom: 3rem;

    &-header {
      margin-bottom: 0.6rem;
      padding: 0.6rem 0;
      align-items: flex-end;
      justify-content: space-between;
      display: flex;
    }

    &-meter {
      width: 100px;
      height: 30px;
    }
  }
}
</style>

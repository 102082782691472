<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="wizard-panel wizard-panel__quarto-intro wizard-panel--blue">
    <div class="wizard-panel__content wizard-panel__content--no-title">
      <p>
        Quarto is an open-source scientific and technical publishing
        system built on Pandoc. Author with scientific markdown. Create
        dynamic content with Python, R, and Observable. Publish articles,
        reports, presentations, websites, and more.
      </p>
      <p>
        Let's walk through how to publish Quarto content.
      </p>
      <div
        v-if="!quartoAvailable"
        class="wizard-panel__warning"
      >
        <p>
          <strong>Note:</strong>
          Quarto publishing is not available.
          To publish Quarto content, an adminstrator must install and
          configure Quarto on this server.
        </p>
        <p>
          <a
            :href="quartoConfigDocumentation"
            target="_blank"
          >
            Learn more about configuring Quarto with Posit Connect
          </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { docsPath } from '@/utils/paths';
import { mapState } from 'vuex';

export default {
  name: 'QuartoIntro',
  computed: {
    quartoConfigDocumentation() {
      return docsPath('admin/appendix/configuration/#Quarto');
    },
    ...mapState({
      quartoAvailable: state => state.server.settings.runtimes.includes('Quarto'),
    })
  },
};
</script>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';

.wizard-panel {
  &__quarto-intro {
    background-image: url('./background.png');
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 100% auto;
  }
  &__warning {
    border: 1px solid #db7575;
    background-color: #f8e3e3;
    margin: 1.5rem 0 0 0;
    padding: 1rem;
    color: #202020;
    * {
      background-color: transparent;
    }
    a {
      color: $color-primary-dark;
    }
  }
}
</style>

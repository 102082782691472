// Copyright (C) 2024 by Posit Software, PBC.

export const SHRINK_HEADER = 'SHRINK_HEADER';
export const SHOW_NAV_PANEL = 'SHOW_NAV_PANEL';
export const SHOW_USER_PANEL = 'SHOW_USER_PANEL';

export const defaultState = () => ({
  shrinkHeader: true,
  showNavPanel: false,
  showUserPanel: false,
});

export default {
  state: defaultState(),
  mutations: {
    [SHRINK_HEADER](state, shrink) {
      state.shrinkHeader = shrink;
    },
    [SHOW_NAV_PANEL](state, show) {
      state.showNavPanel = show;
    },
    [SHOW_USER_PANEL](state, show) {
      state.showUserPanel = show;
    },
  },
};

// Copyright (C) 2024 by Posit Software, PBC.

import { registerPath } from '@/utils/paths';

// This utility functions in most cases only serve to proxy window.* methods
// which might not appear to have a significant benefit over calling those window.* methods
// directly when needed.
//
// But, this was required and does provide significant benefit when there are "window" operations
// within Vue components, there are some dependencies expecting "window" to have certain integrity
// and when it comes to unit tests, mocking the "window" object gets complicated and even fragile.
// So, the main purpose of this windowUtil module is to wrap all window.* operations in such a way
// that it is significantly easier to mock and assert this operations,
// E.g:
//    jest.mock('@/utils/windowUtil')
//    expect(reloadWindow).toHaveBeenCalled();

/**
 * Reload the current window.
 */
export const reloadWindow = () => {
  window.location.reload();
};

/**
 * Determine if the current document is embedded, that is within an iframe.
 * @returns {Boolean}
 */
export const isEmbedded = () => {
  return !(window.self === window.top || window.location === window.parent.location);
};

/**
 * Determine if the current document is embedded within Connect.
 * @returns {Boolean}
 */
export const embeddedInConnect = () => {
  return window.location.hash.match(/__rscembedded__/);
};

/**
 * Listen incoming messages, it could be parent or child windows.
 * @param {Function} onMsg Handler of incoming messages
 * @param {BroadcastChannel} broadcastChannel Optional broadcast channel to listen for messages
 */
export const listenFrameMessagesWith = (onMsg, broadcastChannel) => {
  if (broadcastChannel) {
    broadcastChannel.addEventListener('message', onMsg);
  }
  window.addEventListener('message', onMsg);
};

/**
 * Create a redirect URL with specifics when document is embedded.
 * @returns {String} The redirect URL.
 */
export const embeddedRedirectUrl = () => {
  let redirectURL;

  if (embeddedInConnect()) {
    // embedded in dashboard so use parent frame's href as redirect URL
    redirectURL = window.frameElement.ownerDocument.location.href;
  } else {
    // else where so use actual content URL as redirect URL
    redirectURL = window.location.href;
  }

  if (isEmbedded() && !embeddedInConnect()) {
    // embedded outside Connect, add an expected query parm to be used later
    redirectURL += window.location.search ? '&' : '?';
    redirectURL += 'embedded=1';
  }

  return redirectURL;
};

/**
 * Send parent window location to register view.
 */
export const sendParentFrameToRegister = () => {
  window.frameElement.ownerDocument.location = registerPath();
};

/**
 * Open a window pop-up.
 * @param {String} url
 * @param {String} target
 * @param {String} windowAttrs
 * @returns {Window} Window object of the pop-up instance created.
 */
export const openPopUp = (url, target, windowAttrs) => {
  return window.open(url, target, windowAttrs);
};

/**
 * Post a message to the parent window.
 * @param {Object} msg Message object
 */
export const postMsgToParent = msg => {
  window.parent.postMessage(msg, '*');
};

/**
 * Determine if current window features BroadcastChannel.
 * @returns {Boolean}
 */
export const broadcastChannelInWindow = () => {
  return 'BroadcastChannel' in window;
};

/**
 * Send window location to given url.
 * @param {String} url
 */
export const locationHrefTo = url => {
  window.location.href = url;
};

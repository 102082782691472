<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<!-- Read-only Mail Settings page, with Send Test Email button -->
<template>
  <div class="settings flex">
    <h1
      ref="title"
      class="sectionTitle focusedTitle"
      tabindex="-1"
    >
      Email Settings
    </h1>
    <div
      class="settingsNote"
      data-automation="read-only-note"
    >
      <br>
      <p>
        These settings are controlled by your configuration file.
      </p>
      <span>
        Please visit the
        <a
          :href="emailSetupUrl"
          target="_blank"
        >
          Email Setup
        </a> section on the Admin Guide for details.
      </span>
    </div>
    <div
      v-show="loaded"
      class="settingsInfo"
    >
      <div class="section">
        <div class="label">
          Email Delivery
        </div>
        <div data-automation="sendtype">
          {{ sendType }}
        </div>
      </div>

      <div v-if="isSMTP">
        <div
          class="section"
        >
          <div class="label">
            Server Address
          </div>
          <div data-automation="smtp-host">
            {{ form.host }}
          </div>
        </div>

        <div
          class="section"
        >
          <div class="label">
            Port
          </div>
          <div data-automation="smtp-port">
            {{ form.port }}
          </div>
        </div>

        <div class="section">
          <div class="label">
            Use TLS
          </div>
          <div data-automation="smtp-starttls">
            {{ startTls }}
          </div>
        </div>

        <div
          v-if="form.auth"
          class="section"
        >
          <div
            class="label"
            data-automation="smtp-req-auth"
          >
            ✓ My mail server requires authentication
          </div>
        </div>

        <div
          v-if="form.auth"
          class="section"
        >
          <div class="label">
            Username
          </div>
          <div data-automation="smtp-user">
            {{ form.user }}
          </div>
        </div>
        <div
          v-if="form.hasPassword"
          class="section aside"
          data-automation="password"
        >
          Password not shown for security
        </div>
      </div>

      <div class="actions">
        <BaseButton
          v-if="isConfigured"
          v-bind="{
            label: 'Send Test Email',
            buttonClass: 'default'
          }"
          data-automation="test-email"
          @clicked.stop.prevent="sendTestEmail"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  getMailSettings,
  testMailSettings,
} from '@/api/serverSettings';
import BaseButton from '@/components/BaseButton';
import {
  CLEAR_STATUS_MESSAGE,
  SHOW_ERROR_MESSAGE,
  SHOW_INFO_MESSAGE,
} from '@/store/modules/messages';
import { docsPath } from '@/utils/paths';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  name: 'MailSettingsView',
  components: { BaseButton },
  data() {
    return {
      emailSetupUrl: docsPath('admin/email/'),
      readOnly: false,
      form: {
        type: '',
        host: '',
        port: 0,
        ssl: false,
        startTls: '',
        auth: false,
        user: '',
        password: '',
      },
    };
  },
  computed: {
    hasAuth() {
      return this.isSMTP && this.form.auth;
    },
    isConfigured() {
      return this.form.type !== 'none';
    },
    isSMTP() {
      return this.form.type === 'smtp';
    },
    loaded() {
      return Boolean(this.form.type);
    },
    sendType() {
      if (this.isSMTP) {
        return 'SMTP';
      } else if (!this.isSMTP && this.isConfigured) {
        return 'Sendmail';
      }
      return 'None';
    },
    startTls() {
      const options = {
        never: 'never',
        whenAvailable: 'When available',
        always: 'always',
      };

      let tlsOption = 'whenAvailable';
      if (this.form.startTls !== '') {
        tlsOption = this.form.startTls;
      }
      return options[tlsOption];
    },
    ...mapState({
      currentUser: state => state.currentUser.user,
    })

  },
  created() {
    this.getSettings();
  },
  mounted() {
    this.$refs.title.focus();
  },
  beforeUnmount() {
    this.clearStatusMessage();
  },
  methods: {
    ...mapMutations({
      clearStatusMessage: CLEAR_STATUS_MESSAGE,
    }),
    ...mapActions({
      setInfoMessage: SHOW_INFO_MESSAGE,
      setErrorMessage: SHOW_ERROR_MESSAGE,
    }),
    errorHandler(e) {
      let message;
      if (e && e.response && e.response.data && e.response.data.error) {
        message = `Error: ${e.response.data.error}`;
      } else if (e && e.error) {
        message = `Error: ${e.error}`;
      } else {
        message = 'An error has occurred.';
      }
      this.setErrorMessage({ message });
    },
    getSettings() {
      return getMailSettings()
        .then(res => {
          this.readOnly = (res && res.readOnly) || false;
          // add password field back to form, since it is not returned by API
          this.form = { ...(res || {}), password: '' };
          // must not display a port value of zero or less
          if (this.form.port < 1) {
            this.form.port = null;
          }
        })
        .catch(e => this.errorHandler(e));
    },
    sendTestEmail() {
      this.clearStatusMessage();
      this.setInfoMessage({ message: 'Sending test email.' });
      return testMailSettings()
        .then(() => {
          this.clearStatusMessage();
          this.setInfoMessage({
            message: `Test email sent to ${this.currentUser.email}`,
          });
        })
        .catch(e => {
          this.clearStatusMessage();
          this.errorHandler(e);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';

.settings {
  flex-direction: column;
}

.settingsInfo {
  width: 400px;
}

.section {
  background-color: $color-light-grey;
  line-height: 24px;
  margin: 1rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.4rem;
}

.label {
  font-size: 18px;
  font-weight: 300;
  padding-right: 0.4rem;
}

.aside {
  font-style: italic;
}
</style>

<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<script setup>
import RSButton from '@/elements/RSButton.vue';
import { HIDE_LOCKED_MESSAGE_PREVIEW } from '@/store/modules/contentView';
import markdownit from 'markdown-it';
import { computed } from 'vue';
import { useStore } from 'vuex';
import CollapsiblePanel from '../../../components/CollapsiblePanel.vue';

const store = useStore();

const lockedContentEnabled = computed(() => store.state.server.settings.lockedContentEnabled);
const showLockedMessagePreview = computed(() => store.state.contentView.showLockedMessagePreview);
const lockedMessagePreview = computed(() => {
  const markdownMsg = store.state.contentView.lockedMessagePreview;
  const md = markdownit({
    html: false,    // never change this to `true`
    linkify: true,
  });

  return markdownMsg ? md.render(markdownMsg) : '';
});
</script>

<template>
  <!--
    v-html is ok here. We are deliberately rendering html generated by markdown-it 
    with the `html` option disabled to prevent XSS.
  -->
  <!-- eslint-disable vue/no-v-html -->
  <CollapsiblePanel
    v-if="lockedContentEnabled"
    :hide-header="true"
    :is-expanded="showLockedMessagePreview"
  >
    <template #content>
      <div
        v-if="lockedContentEnabled"
        class="preview-container"
      >
        <RSButton
          class="close-button"
          data-automation="close-locked-message-preview"
          label="Close"
          size="small"
          type="primary"
          :use-label-width="true"
          @click="store.commit(HIDE_LOCKED_MESSAGE_PREVIEW)"
        />

        <div
          class="locked-message-preview"
          data-automation="locked-message-preview"
          v-html="lockedMessagePreview"
        />
      </div>
    </template>
  </CollapsiblePanel>
</template>

<style lang="scss">
@import 'Styles/shared/_colors';

.preview-container {
  background-color: $color-light-grey-2;
  background-color: lighten($color-alert-warning, 20%);
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.15);
  left: 0;
  margin-bottom: 5px;
  opacity: 0.95;
  padding: 2.5rem 2rem 2rem;
  position: relative;
  top: 0;
  z-index: 10;

  .locked-message-preview {
    padding: 1rem;
  }

  .close-button {
    position: absolute;
    right: .8rem;
    top: .8rem;
  }

  p {
    margin-top: 1rem;
  }
}
</style>

// Copyright (C) 2022 by Posit Software, PBC.

import DownloadExample from './panels/DownloadExample';
import PythonDependencies from './panels/PythonDependencies';
import PythonDeploy from './panels/PythonDeploy';
import PythonRsconnect from './panels/PythonRsconnect';
import PythonShinyOutro from './panels/PythonShinyOutro';

export default {
  bare: [],
  copyFrom: [
    DownloadExample,
    PythonDependencies,
    PythonRsconnect,
    PythonDeploy,
    PythonShinyOutro,
  ],
};

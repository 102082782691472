<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <RSTable
    v-if="applications.length"
    :columns="tableColumns"
    table-name="Content"
    data-automation="content-list__table"
  >
    <RSTableRow
      v-for="app in applications"
      :key="app.guid"
    >
      <RSTableCell
        :cell-id="`app-name-${app.guid}`"
        :has-icon="true"
        :fill="true"
        data-automation="content-row-icon-title-cell"
      >
        <RouterLink
          :to="{ name: 'apps', params: { idOrGuid: app.guid } }"
          class="content-table__content-title"
        >
          <div class="content-table__content-name">
            <div>
              <i
                :class="app.iconClass()"
                class="rs-icon rs-icon--large"
              />
            </div>
            <div
              data-automation="content-table__row__display-name"
              :class="[{ 'rsc-content-list__unpublished': !app.isDeployed() }, 'content-table__display-name']"
            >
              {{ app.contentDisplayName() || 'Untitled' }}
            </div>
          </div>
        </RouterLink>
      </RSTableCell>
      <RSTableCell data-automation="content-row-type-cell">
        {{ descriptionFor(app) }}
      </RSTableCell>
      <!-- Content author (owner info is available) -->
      <RSTableCell
        v-if="hasOwner(app)"
        :cell-id="`${app.id}-author`"
        data-automation="content-row-author-cell"
      >
        <RouterLink
          :to="{ name: 'people.users.profile', params: { guid: app.ownerGuid } }"
        >
          <span data-automation="content-row-author">
            {{ ownerName(app) }}
          </span>
        </RouterLink>
      </RSTableCell>
      <!-- Content author (empty cell b/c owner info is missing) -->
      <RSTableCell
        v-else
        data-automation="content-row-author-cell"
      />
      <RSTableCell
        v-if="app.isDeployed()"
        data-automation="content-row-updated-cell"
      >
        {{ lastDeployedTime(app) }}{{ fromGit(app.git) }}
      </RSTableCell>
      <RSTableCell
        v-else
        class="rsc-content-list__unpublished"
      >
        unpublished
      </RSTableCell>
    </RSTableRow>
  </RSTable>
</template>

<script>
import { mapState } from 'vuex';
import RSTable from '@/elements/RSTable';
import RSTableCell from '@/elements/RSTableCell';
import RSTableRow from '@/elements/RSTableRow';

import { activeTime } from '@/utils/activeTime.filter';
import { contentDescriptions, displayOwnerName, fromGit, hasOwner } from '@/utils/contentList';
import { truncate } from '@/utils/truncate.filter';
import { contentTypeLabel } from './contentTypes';
import { RouterLink } from 'vue-router';

export default {
  name: 'ContentTable',
  components: {
    RSTable,
    RSTableRow,
    RSTableCell,
    RouterLink,
  },
  props: {
    applications: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState({
      serverSettings: state => state.server.settings,
    }),
  },
  created() {
    this.tableColumns = [
      {
        label: 'Name',
        width: '100%',
      },
      { label: 'Type' },
      { label: 'Owner' },
      { label: 'Last Deployed' },
    ];
  },
  methods: {
    hasOwner,
    fromGit,
    ownerName(app) {
      const ownerName = displayOwnerName(app);
      return truncate(ownerName, 18);
    },
    lastDeployedTime(app) {
      return activeTime(app.lastDeployedTime);
    },
    descriptionFor(app) {
      if (this.serverSettings.newContentSearchEnabled) {
        return contentTypeLabel(app.appMode);
      }

      return contentDescriptions[app.contentType()];
    },
  },
};
</script>

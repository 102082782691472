// Copyright (C) 2022 by Posit Software, PBC.

import { getToken } from '@/api/metrics';
import { GiB as gibibyte } from '@/utils/bytes.filter';
import { serverPath } from '@/utils/paths';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import sockjs from 'sockjs-client';

dayjs.extend(duration);

const subscriptions = [
  { source: { type: 'system', id: 'CPU' } },
  { source: { type: 'system', id: 'RAM' } },
  { source: { type: 'license', id: 'users' } },
];

// delays in seconds for websocket reconnection
const delays = [0, 1, 2, 5, 10, 30, 60, 120];

// Block scope is needed inside MetricsSocket because execution happens outside
// the current scope of `this`
export class MetricsSocket {
  constructor(callback, { onError, clearErrors } = {}) {
    this.callback = callback;
    this.onError = onError || (() => {});
    this.clearErrors = clearErrors || (() => {});

    // used to detect a legitimate disconnect
    this.disconnect = false;

    // used for reconnecting the websocket
    this.attempts = 0;
    this.resetAttempts = null;
    this.reconnectAttempt = null;
    this.reconnectNotice = null;

    // make the initial attempt to connect
    this.connect();
  }

  // open the websocket connection and reconnect if it fails
  connect = () => {
    getToken()
      .then(token => {
        this.socket = new sockjs(serverPath(`__sockjs__/t=${token}`));
        this.socket.onopen = this.onopen;
        this.socket.onmessage = this.onmessage;
        this.socket.onclose = this.reconnect;
      })
      .catch(this.reconnect);
  };

  // reconnect the websocket with increased delays
  reconnect = () => {
    // cancel the timer started inside onopen()
    if (this.resetAttempts) {
      clearTimeout(this.resetAttempts);
      this.resetAttempts = null;
    }

    // bail if the disconnect was legit (i.e. close() was called)
    if (this.disconnect) {
      return;
    }

    // increase the attempt count unless we're at the max timeout value
    if (this.attempts < delays.length - 1) {
      this.attempts++;
    }

    // display a reconnect notice in a couple of seconds if there isn't one already
    if (!this.reconnectNotice) {
      this.reconnectNotice = setTimeout(() => {
        this.onError('Connection to server lost. Trying to reconnect...');
      }, 2000);
    }

    const nextDelay = delays[this.attempts] * 1000;
    console.log(
      `Websocket disconnected. Attempting reconnection in ${nextDelay /
        1000} seconds.`
    );

    // reconnect after the calculated delay for the next attempt
    this.reconnectAttempt = setTimeout(this.connect, nextDelay);
  };

  // subscribe to cpu, ram and users events
  onopen = () => {
    // clear any pending reconnect notice
    if (this.reconnectNotice) {
      clearTimeout(this.reconnectNotice);
      this.reconnectNotice = null;
      this.clearErrors();
    }

    // reset the reconnection attempt counter after the websocket has been
    // successfully connected for at least 5 seconds
    this.resetAttempts = setTimeout(() => {
      this.attempts = 0;
    }, 5000);

    this.socket.send(
      JSON.stringify(
        subscriptions.map(({ source }) => ({ source, subscribe: true }))
      )
    );
  };

  // call the callback for monitor events only
  onmessage = socketEvent => {
    const evt = JSON.parse(socketEvent.data).event;
    if (evt) {
      // convert monitor events from array of objects to object i.e
      // [{ name: 'a', value: 1 }, { name: 'b', value: 2 }] -> { a: 1, b: 2 }
      if (evt.class === 'MON') {
        evt.state.data = evt.state.data.reduce((object, item) => {
          object[item.name] = item.value;
          return object;
        }, {});
        this.callback(evt.state);
      }
    }
  };

  // unsubscribe from all events and close the websocket
  close = () => {
    this.disconnect = true;

    // clear any pending reconnect attempts
    if (this.reconnectAttempt) {
      clearTimeout(this.reconnectAttempt);
      this.reconnectAttempt = null;
    }

    this.socket.send(
      JSON.stringify(
        subscriptions.map(({ source }) => ({ source, subscribe: false }))
      )
    );

    this.socket.close();
  };
}

/**
 * Updates the chart data as each new socket event comes in. Shifts the series data
 * by one to append the new object if the time window was reached. Otherwise, it
 * replaces the last object with the new object if still within the same time window.
 * The chart data will not be updated if the incoming event timestamp is earlier
 * than the latest timestamp for the series.
 *
 * @param {Array} chartData - The chart data to be updated
 * @param {Object} eventData - Data from the websocket event
 * @param {number} timestamp - The timestamp in seconds from the websocket event
 * @param {Object} object - The current timeframe
 * @param {number} object.unit - The timeframe unit (1, 2, 3, 20, etc)
 * @param {string} object.range - The timeframe range (hours, days, weeks, months, years)
 */
const chartTransformer = (chartData, eventData, timestamp, { unit, range }) => {
  timestamp *= 1000; // convert to milliseconds
  const timePeriod = dayjs.duration(unit, range).asSeconds() * 1000;

  chartData.forEach(series => {
    const yValue = eventData[series.name];
    const timeWindow = timePeriod / series.data.length;
    const lastTimestamps = series.data.slice(-2).map(item => item.x);
    const windowReached = lastTimestamps[1] >= lastTimestamps[0] + timeWindow;

    // discard stale incoming socket data
    if (timestamp < lastTimestamps[1]) {
      const message = `Stale socket data found for series: ${series.name}.
      Socket event timestamp: ${timestamp} is older than the
      latest chart data timestamp: ${lastTimestamps[1]}.
      Timestamp difference: ${lastTimestamps[1] - timestamp} (milliseconds).`;
      console.log(message);
      return;
    }

    if (windowReached) {
      // append new object
      series.data = series.data.slice(1).concat({ x: timestamp, y: yValue });
    } else {
      // replace last object
      series.data[series.data.length - 1] = { x: timestamp, y: yValue };
    }
  });
};

/**
 * Transformer for the cpu subscription events. This event contains data used
 * to update the cpu chart and gauge values. The 'misc' value is calculated
 * from the 'idle' event data. The event data values used to update the chart
 * are converted to percentage.
 *
 * @param {number} timestamp - The timestamp in seconds from the websocket event
 * @param {Object} evt - Data from the websocket event
 * @param {Array} chartData - The cpu chart data
 * @param {Object} timeframe - The current timeframe (unit and range)
 *
 * @returns {Object} - transformed chart data and gauge values for the cpu chart
 */
const cpuTransformer = (timestamp, evt, chartData, timeframe) => {
  const { sys, user, idle, cores } = evt;
  const gaugeValue = (1 - idle) * cores;
  // multiply each series by 100 to go from 0.457 to 45.7%
  const eventData = {
    sys: sys * 100,
    user: user * 100,
    // `idle` is the calculated `misc` value on the CPU chart
    idle: Math.max(1 - idle - (user + sys), 0) * 100,
  };

  chartTransformer(chartData, eventData, timestamp, timeframe);

  return [
    {
      type: 'cpu',
      data: { datasets: chartData },
      gauge: { max: cores, value: gaugeValue },
    },
  ];
};

/**
 * Transformer for the ram subscription events. This event contains data used
 * to update the ram chart and gauge values. The 'cached' value is calculated
 * from the 'used' event data. The gauge values are converted from bytes to
 * gibibytes and the max value is rounded to the nearst tenth.
 *
 * @param {number} timestamp - The timestamp in seconds from the websocket event
 * @param {Object} evt - Data from the websocket event
 * @param {Array} chartData - The ram chart data
 * @param {Object} timeframe - The current timeframe (unit and range)
 *
 * @returns {Object} - transformed chart data and gauge values for the ram chart
 */
const ramTransformer = (timestamp, evt, chartData, timeframe) => {
  const { actualused, used, total } = evt;
  const gaugeMax = Math.round((total * 10) / gibibyte) / 10;
  const gaugeValue = actualused / gibibyte;

  const eventData = {
    actualused,
    // `used` is the calculated `cached` value on the RAM chart
    used: used - actualused,
  };

  chartTransformer(chartData, eventData, timestamp, timeframe);

  return [
    {
      type: 'ram',
      data: { datasets: chartData },
      gauge: { max: gaugeMax, value: gaugeValue },
    },
  ];
};

/**
 * Transformer for the users subscription events. This event contains data used
 * to update the namedUsers and shinyConnections charts and gauge values.
 *
 * @param {number} timestamp - The timestamp in seconds from the websocket event
 * @param {Object} evt - Data from the websocket event
 * @param {Object} chartData - The namedUsers and shinyConnections chart data
 * @param {Object} timeframe - The current timeframe (unit and range)
 *
 * @returns {Object} - transformed chart data and gauge values for the namedUsers
 * and shinyConnections chart
 */
const usersTransformer = (timestamp, evt, chartData, timeframe) => {
  const { namedUsers, shinyConnections } = chartData;
  const {
    active1day,
    active30day,
    active365day,
    shinyusers,
    licensedusers,
    licensedshinyusers,
  } = evt;
  const eventData = { active1day, active30day, active365day, shinyusers };

  chartTransformer(namedUsers, eventData, timestamp, timeframe);
  chartTransformer(shinyConnections, eventData, timestamp, timeframe);

  return [
    {
      type: 'namedUsers',
      data: { datasets: namedUsers },
      gauge: { max: licensedusers, value: active365day },
    },
    {
      type: 'shinyConnections',
      data: { datasets: shinyConnections },
      gauge: { max: licensedshinyusers, value: shinyusers },
    },
  ];
};

/**
 * Applies the appropriate transformer to the event/chart data by subscription
 *
 * @param {Object} evt - Event data from the websocket
 * @param {Object} timeframe - The current timeframe (unit and range)
 * @param {Object} charts - The chart data for all charts
 *
 * @returns {Object} - transformed chart data and gauge values
 */
export const socketEventTransformer = (evt, timeframe, charts) => {
  const { scope, data, ts } = evt;
  const cpu = charts.cpu.data.datasets;
  const ram = charts.ram.data.datasets;
  const users = {
    namedUsers: charts.namedUsers.data.datasets,
    shinyConnections: charts.shinyConnections.data.datasets,
  };

  switch (scope) {
    case 'system-CPU':
      return cpuTransformer(ts, data, cpu, timeframe);
    case 'system-RAM':
      return ramTransformer(ts, data, ram, timeframe);
    case 'license-users':
      return usersTransformer(ts, data, users, timeframe);
  }
};

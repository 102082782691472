<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="wizard-panel wizard-panel__quarto-file wizard-panel--white">
    <div class="wizard-panel__content">
      <StepHeading :step="step">
        Open a Quarto File
      </StepHeading>
      <p>
        If you already have a Quarto file or project, open it. If not, go
        ahead and make one. It is strongly recommended that you use
        v2022.02 or newer of the RStudio IDE, which includes support for editing
        and previewing Quarto documents.
      </p>
      <p>
        <a
          href="https://quarto.org/docs/tools/rstudio.html#overview"
          target="_blank"
        >Learn more about working with Quarto in the RStudio IDE</a>
      </p>
    </div>
  </div>
</template>

<script>
import StepHeading from '@/views/content/PublishWizard/StepHeading';
import PanelMixin from '../PanelMixin';

export default {
  name: 'QuartoFile',
  components: { StepHeading },
  mixins: [PanelMixin],
};
</script>

<style lang="scss" scoped>
.wizard-panel {
  &__quarto-file {
    background-image: url('./background.png');
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: 100% auto;
  }
}
</style>

// Copyright (C) 2022 by Posit Software, PBC.

export const JUPYTER_NOTEBOOK = 'JUPYTER_NOTEBOOK';
export const JUPYTER_VOILA = 'JUPYTER_VOILA';
export const PIN = 'PIN';
export const PLOT = 'PLOT';
export const PLUMBER_API = 'PLUMBER_API';
export const PYTHON_API = 'PYTHON_API';
export const PYTHON_BOKEH = 'PYTHON_BOKEH';
export const PYTHON_DASH = 'PYTHON_DASH';
export const PYTHON_FASTAPI = 'PYTHON_FASTAPI';
export const PYTHON_SHINY = 'PYTHON_SHINY';
export const PYTHON_STREAMLIT = 'PYTHON_STREAMLIT';
export const PYTHON_VETIVER = 'PYTHON_VETIVER';
export const QUARTO = 'QUARTO';
export const QUARTO_PYTHON = 'QUARTO_PYTHON';
export const QUARTO_PYTHON_SCRIPT = 'QUARTO_PYTHON_SCRIPT';
export const QUARTO_R = 'QUARTO_R';
export const QUARTO_R_SCRIPT = 'QUARTO_R_SCRIPT';
export const QUARTO_WEBSITE = 'QUARTO_WEBSITE';
export const RMARKDOWN = 'RMARKDOWN';
export const RMARKDOWN_PARAMS = 'RMARKDOWN_PARAMS';
export const R_VETIVER = 'R_VETIVER';
export const SHINY_APP = 'SHINY_APP';
export const SHINY_RMARKDOWN = 'SHINY_RMARKDOWN';
export const TENSOR_FLOW = 'TENSOR_FLOW';

export const BY_LANGUAGE = {
  // How would we handle multiple Jump Start examples for one item?
  R: [
    PIN, // TODO: separate R and Python flavored pin examples?
    PLOT,
    PLUMBER_API,
    RMARKDOWN,
    RMARKDOWN_PARAMS,
    SHINY_APP,
    SHINY_RMARKDOWN,
    R_VETIVER,
    QUARTO_R,
    QUARTO_R_SCRIPT,
  ],
  PYTHON: [
    JUPYTER_NOTEBOOK,
    JUPYTER_VOILA,
    PYTHON_API,
    PYTHON_DASH,
    PYTHON_FASTAPI,
    PYTHON_STREAMLIT,
    PYTHON_BOKEH,
    PYTHON_SHINY,
    PYTHON_VETIVER,
    QUARTO_PYTHON,
    QUARTO_PYTHON_SCRIPT,
    QUARTO_WEBSITE,
  ],
  QUARTO: [
    QUARTO,
    QUARTO_R,
    QUARTO_R_SCRIPT,
    QUARTO_PYTHON,
    QUARTO_PYTHON_SCRIPT,
    QUARTO_WEBSITE
  ],
};

export const ContentTypeDescriptions = {
  [JUPYTER_NOTEBOOK]: 'Jupyter Notebook',
  [JUPYTER_VOILA]: 'Voilà Dashboard',
  [PIN]: 'Pin',
  [PLOT]: 'Plot',
  [PLUMBER_API]: 'Plumber API',
  [PYTHON_API]: 'Python API',
  [PYTHON_BOKEH]: 'Bokeh App',
  [PYTHON_DASH]: 'Dash App',
  [PYTHON_FASTAPI]: 'Python FastAPI',
  [PYTHON_SHINY]: 'Python Shiny App',
  [PYTHON_STREAMLIT]: 'Streamlit App',
  [PYTHON_VETIVER]: 'Vetiver API',
  [QUARTO]: 'Quarto Content',
  [QUARTO_PYTHON]: 'Quarto Content',
  [QUARTO_PYTHON_SCRIPT]: 'Quarto Content',
  [QUARTO_R]: 'Quarto Content',
  [QUARTO_R_SCRIPT]: 'Quarto Content',
  [QUARTO_WEBSITE]: 'Quarto Content',
  [RMARKDOWN]: 'R Markdown Document',
  [RMARKDOWN_PARAMS]: 'Parameterized R Markdown Document',
  [R_VETIVER]: 'Vetiver API',
  [SHINY_APP]: 'Shiny App',
  [SHINY_RMARKDOWN]: 'Shiny R Markdown Document',
  [TENSOR_FLOW]: 'TensorFlow Model',
};

export const ContentTypeShortDescriptions = {
  [JUPYTER_NOTEBOOK]: 'Jupyter',
  [JUPYTER_VOILA]: 'Voilà',
  [PIN]: 'Pins',
  [PLOT]: 'Plots',
  [PLUMBER_API]: 'Plumber API',
  [PYTHON_API]: 'Python API',
  [PYTHON_DASH]: 'Dash',
  [PYTHON_FASTAPI]: 'Python FastAPI',
  [PYTHON_STREAMLIT]: 'Streamlit',
  [PYTHON_BOKEH]: 'Bokeh',
  [PYTHON_SHINY]: 'Python Shiny',
  [PYTHON_VETIVER]: 'Vetiver Python',
  [RMARKDOWN]: 'R Markdown',
  [RMARKDOWN_PARAMS]: 'R Markdown',
  [SHINY_APP]: 'Shiny',
  [SHINY_RMARKDOWN]: 'Shiny R Markdown',
  [TENSOR_FLOW]: 'TensorFlow',
  [R_VETIVER]: 'Vetiver R',
  [QUARTO]: 'Quarto',
  [QUARTO_R]: 'Quarto',
  [QUARTO_R_SCRIPT]: 'Quarto',
  [QUARTO_PYTHON]: 'Quarto',
  [QUARTO_PYTHON_SCRIPT]: 'Quarto',
  [QUARTO_WEBSITE]: 'Quarto',
};

export default [
  BY_LANGUAGE,
  JUPYTER_NOTEBOOK,
  JUPYTER_VOILA,
  PIN,
  PLOT,
  PLUMBER_API,
  PYTHON_API,
  PYTHON_BOKEH,
  PYTHON_DASH,
  PYTHON_FASTAPI,
  PYTHON_SHINY,
  PYTHON_STREAMLIT,
  PYTHON_VETIVER,
  QUARTO,
  QUARTO_PYTHON,
  QUARTO_PYTHON_SCRIPT,
  QUARTO_R,
  QUARTO_R_SCRIPT,
  QUARTO_WEBSITE,
  RMARKDOWN,
  RMARKDOWN_PARAMS,
  R_VETIVER,
  SHINY_APP,
  SHINY_RMARKDOWN,
  TENSOR_FLOW,
];

<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div>
    <RestrictedAccessWrapper
      v-slot="{ executeRestrictedApi }"
      :eager="false"
      @loaded="focus"
    >
      <RSModalForm
        data-automation="new-api-key-form"
        :active="true"
        subject="API Keys"
        @close="$emit('close')"
        @submit="() => createKey(executeRestrictedApi)"
      >
        <template #content>
          <EmbeddedStatusMessage
            v-if="errMessage"
            type="error"
            data-automation="create-key-error"
            :message="errMessage"
            @close="clearErr"
          />
          <RSInputText
            ref="input"
            v-model.trim="keyName"
            name="api-key-name"
            data-automation="api-key-name"
            label="Enter a name for the new API Key"
            :message="errorMsg"
            @change="checkInputLen"
          />
        </template>
        <template #controls>
          <RSButton
            data-automation="api-key-create"
            label="Create Key"
          />
        </template>
      </RSModalForm>
    </RestrictedAccessWrapper>
  </div>
</template>

<script>
import { safeAPIErrorMessage } from '@/api/error';
import { createUserAPIKey } from '@/api/users';

import EmbeddedStatusMessage from '@/components/EmbeddedStatusMessage';
import RestrictedAccessWrapper, {
  ReauthenticationInProgressError,
} from '@/components/RestrictedAccessWrapper';
import RSButton from '@/elements/RSButton';
import RSInputText from '@/elements/RSInputText';
import RSModalForm from '@/elements/RSModalForm';

const MAX_KEY_LENGTH = 50;

export default {
  name: 'CreateKey',
  components: {
    RestrictedAccessWrapper,
    RSButton,
    RSInputText,
    RSModalForm,
    EmbeddedStatusMessage,
  },
  emits: ['close', 'created'],
  data() {
    return {
      keyName: '',
      showInputErr: false,
      errMessage: '',
    };
  },
  computed: {
    errorMsg() {
      return this.showInputErr ? 'The name is required' : '';
    },
  },
  methods: {
    keyNameCheck() {
      // Check if key name is valid
      this.showInputErr = this.keyName === '';
    },
    async checkInputLen() {
      // Wait on model to update before length check
      await this.$nextTick();
      this.keyNameCheck();
      if (this.keyName.length > MAX_KEY_LENGTH) {
        this.keyName = this.keyName.slice(0, MAX_KEY_LENGTH);
      }
    },
    createKey(executeRestrictedApi) {
      // Halt if key name not valid
      this.keyNameCheck();
      if (this.showInputErr) {
        return;
      }

      executeRestrictedApi(createUserAPIKey(this.keyName))
        .then(newKey => {
          return this.$emit('created', newKey);
        })
        .catch(err => {
          if (!(err instanceof ReauthenticationInProgressError)) {
            this.errMessage = safeAPIErrorMessage(err);
          }
        });
    },
    clearErr() {
      this.errMessage = '';
    },
    focus() {
      this.$nextTick().then(() => this.$refs.input.$el.querySelector('input').focus());
    }
  },
};
</script>

<style lang="scss" scoped>
.statusMessage {
  margin-bottom: 0.9rem;
}
</style>

<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<!-- Renders the Groups View -->
<template>
  <div class="band">
    <div class="bandContent mainPage">
      <div class="menu">
        <div
          class="menuItems"
          role="tablist"
        >
          <RouterLink
            :to="{ name: 'people.users' }"
            class="menuItem users"
            data-automation="menu-item-users"
            role="tab"
          >
            Users
          </RouterLink>
          <RouterLink
            :to="{ name: 'people.groups' }"
            class="menuItem groups active"
            data-automation="menu-item-groups"
            role="tab"
          >
            Groups
          </RouterLink>
        </div>
      </div>
      <div :class="['contentWithOptionsPanel', hideOptionsPanelClass]">
        <div
          id="groupsPanel"
          class="majorColumn"
        >
          <div
            v-if="dataLoaded"
            class="sectionTitle flex"
          >
            <div
              ref="title"
              data-automation="group-title"
              tabindex="-1"
              class="title"
              role="heading"
              aria-level="1"
            >
              {{ title }}
              <i
                role="button"
                tabindex="0"
                class="rs-help-toggler__help-icon"
                :aria-label="showAuthOriginHelp ? 'Hide Authentication Source' : 'Show Authentication Source'"
                @click="toggleHidden"
                @keypress.stop="toggleHidden"
              />
            </div>
            <div class="actionBar inline showTitles">
              <BaseButton
                v-if="enableAddGroupsBtn"
                ref="addGroupButton"
                :label="newGroupButtonTitle"
                button-class="action new"
                :title="newGroupButtonTitle"
                :aria-label="newGroupButtonTitle"
                data-automation="add-group"
                @clicked.prevent="toggleAddModal"
              />
              <BaseButton
                v-if="!showOptionsPanel"
                ref="optionsButton"
                label="Options"
                button-class="action toggleOptions"
                title="Options"
                aria-label="Options"
                data-automation="add-group"
                @clicked.prevent="toggleOptionsPanel"
              />
            </div>
          </div>
          <div
            v-show="showAuthOriginHelp"
            class="rs-help-toggler__text"
          >
            {{ authOriginMsg }}
          </div>
          <div
            v-if="emptyResults"
            class="emptyListMessage"
            data-automation="groups__list--empty"
          >
            No results.
          </div>

          <EmbeddedStatusMessage
            v-if="isLoading"
            :show-close="false"
            message="Getting groups..."
            type="activity"
          />

          <RSTable
            v-if="!emptyResults"
            :columns="tableHeaders"
            data-automation="groups__list"
          >
            <RSTableRow
              v-for="(group, index) in groups"
              :key="group.guid"
              :class="highlightClass(index)"
              :row-id="group.guid"
              :deletable="canDeleteGroup(group)"
              delete-button-label="Delete Group"
              :row-label="`Group ${group.name}'s details`"
              @delete="(_rowId, currentTarget) => toggleDeleteModal(group, currentTarget)"
            >
              <RSTableCell
                :cell-id="`group-${group.guid}-link`"
                :has-icon="true"
                :fill="true"
                :link="groupHREF(group)"
                :clickable="true"
                @click="onClickRow"
              >
                <RSPrincipalInfo
                  :is-group="true"
                  :name="group.name"
                />
              </RSTableCell>
              <RSTableCell
                v-if="canEditGroup(group)"
                data-automation="group-rename-cell"
              >
                <div class="actionBar">
                  <button
                    class="action edit"
                    :aria-label="`Rename Group ${group.name}`"
                    @click.stop="e => onClickRename(e, group, index)"
                  />
                </div>
              </RSTableCell>
            </RSTableRow>
          </RSTable>
          <RSPager
            v-if="showPager"
            :disable-left-actions="disablePreviousPagination"
            :disable-right-actions="disableNextPagination"
            @first-page="gotoPage('first')"
            @previous-page="gotoPage('previous')"
            @next-page="gotoPage('next')"
            @last-page="gotoPage('last')"
          />
        </div>
        <div
          v-if="dataLoaded && showOptionsPanel"
          class="minorColumn"
        >
          <div class="optionsPositioner">
            <div class="band">
              <div class="innards bandContent">
                <GroupsOptionsPanel
                  v-if="showOptionsPanel"
                  ref="optionsPanel"
                  :can-add-groups="enableAddGroupsBtn"
                  :can-search-by-id="canSearchById"
                  :is-remote="hasExternalGroupSearch"
                  @close="toggleOptionsPanel"
                  @filter-change="updateSearchOptions"
                  @vue:mounted="onMounted"
                />
              </div>
            </div>
          </div>
        </div>

        <CreateGroupDialog
          v-if="dataLoaded && showCreateGroupDialog"
          @created="groupCreated"
          @close="toggleAddModal"
        />
        <DeleteGroupDialog
          v-if="showDeleteGroupDialog"
          :group="deleteTargetGroup"
          @close="handleDeleteResolution"
        />
        <RenameGroupDialog
          v-if="showGroupRenameDialog"
          :group="renameTargetGroup.target"
          @close="toggleRenameModal"
          @rename="handleRenamingGroup"
        />
        <ImportRemoteEntityDialog
          v-if="showAddRemoteGroupDialog"
          type="group"
          :server-settings="serverSettings"
          @import="groupImported"
          @close="toggleAddModal"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { searchGroups } from '@/api/groups';
import BaseButton from '@/components/BaseButton';
import EmbeddedStatusMessage from '@/components/EmbeddedStatusMessage';
import ImportRemoteEntityDialog from '@/components/ImportRemoteEntityDialog';
import RSPager from '@/elements/RSPager';
import RSPrincipalInfo from '@/elements/RSPrincipalInfo';
import RSTable from '@/elements/RSTable';
import RSTableCell from '@/elements/RSTableCell';
import RSTableRow from '@/elements/RSTableRow';
import {
  AUTH_ORIGIN_HELP_INIT,
  AUTH_ORIGIN_HELP_UPDATE_HIDDEN,
} from '@/store/modules/authOriginHelp';
import { SET_ERROR_MESSAGE_FROM_API } from '@/store/modules/messages';
import { groupPath } from '@/utils/paths';
import CreateGroupDialog from '@/views/groups/CreateGroupDialog';
import DeleteGroupDialog from '@/views/groups/DeleteGroupDialog';
import GroupsOptionsPanel from '@/views/groups/GroupsOptionsPanel';
import RenameGroupDialog from '@/views/groups/RenameGroupDialog';
import { mapActions, mapMutations, mapState } from 'vuex';
import { RouterLink } from 'vue-router';

export default {
  name: 'GroupsView',
  components: {
    BaseButton,
    CreateGroupDialog,
    DeleteGroupDialog,
    RenameGroupDialog,
    GroupsOptionsPanel,
    ImportRemoteEntityDialog,
    RSPager,
    RSPrincipalInfo,
    RSTable,
    RSTableRow,
    RSTableCell,
    EmbeddedStatusMessage,
    RouterLink,
  },
  data() {
    return {
      dataLoaded: false,
      loading: false,
      searchParameters: {
        prefix: '',
        currentPage: 1,
      },
      groups: [],
      highlightFlag: false,
      totalPages: 0,
      deleteTargetGroup: {},
      previousFocus: null,
      renameTargetGroup: { target: {} },
      showAddGroupDialog: false,
      showDeleteGroupDialog: false,
      showGroupRenameDialog: false,
      showOptionsPanel: true,
      tableHeaders: [{ label: 'Group', size: 3 }],
    };
  },
  computed: {
    highlightClass() {
      // Use a class that handles the highlight of new rows
      const highlight = this.highlightFlag;
      return rowIndex => (highlight && rowIndex === 0 ? 'highlight-once' : '');
    },
    emptyResults() {
      return !this.groups.length;
    },
    isLoading() {
      return !this.dataLoaded || this.loading;
    },
    showPager() {
      return this.totalPages > 1;
    },
    disablePreviousPagination() {
      return this.searchParameters.currentPage === 1;
    },
    disableNextPagination() {
      return this.searchParameters.currentPage === this.totalPages;
    },
    hideOptionsPanelClass() {
      return this.showOptionsPanel ? '' : 'hideOptionsPanel';
    },
    authProviderName() {
      return this.serverSettings.authentication.name;
    },
    hasExternalGroupOwner() {
      return this.serverSettings.authentication.externalGroupOwner;
    },
    hasExternalGroupMembers(){
      return this.serverSettings.authentication.externalGroupMembers;
    },
    hasExternalGroupSearch() {
      return this.serverSettings.authentication.externalGroupSearch;
    },
    enableAddGroupsBtn() {
      return this.canCreateGroup || this.canAddRemoteGroup;
    },
    showCreateGroupDialog() {
      return this.showAddGroupDialog && this.canCreateGroup;
    },
    showAddRemoteGroupDialog() {
      return this.showAddGroupDialog && this.canAddRemoteGroup;
    },
    canCreateGroup() {
      return this.currentUser.canCreateGroup(this.serverSettings);
    },
    canAddRemoteGroup() {
      return this.currentUser.canAddRemoteGroup(this.serverSettings);
    },
    canEditGroup() {
      return group => this.currentUser.canEditGroup(group, this.serverSettings);
    },
    canDeleteGroup() {
      return group => this.currentUser.canDeleteGroup(group);
    },
    canSearchById() {
      return Boolean(this.serverSettings.authentication.externalGroupId);
    },
    title() {
      let title = this.searchParameters.prefix ? 'Matching groups' : 'Groups';

      if (this.hasExternalGroupOwner) {
        title = `${title} (${this.authProviderName})`;
      }

      return title;
    },
    authOriginMsg() {
      let msg = 'Connect is using local membership information.';
      if (this.hasExternalGroupMembers) {
        msg = `Connect is using membership information from your remote ${this.authProviderName} provider.`;
      }

      return msg;
    },
    newGroupButtonTitle() {
      let msg = 'Add Group';
      if (this.hasExternalGroupSearch) {
        msg = 'Import Group';
      }

      return msg;
    },
    ...mapState({
      showAuthOriginHelp: state => !state.authOriginHelp.hide,
      currentUser: state => state.currentUser.user,
      serverSettings: state => state.server.settings,
    }),
  },
  created() {
    this.init();
    this.authOriginInit();
  },
  methods: {
    onMounted() {
      this.$refs.title.focus();
    },
    async init() {
      await this.search();
      this.dataLoaded = true;
    },
    onClickRow(_groupId, target) {
      this.saveFocusedElement(target);
    },
    onClickRename({ currentTarget }, group, index) {
      this.toggleRenameModal(group, index, currentTarget);
    },
    toggleAddModal() {
      this.showAddGroupDialog = !this.showAddGroupDialog;
      if (!this.showAddGroupDialog) {
        this.$nextTick().then(() => this.$refs.addGroupButton && this.$refs.addGroupButton.focus());
      }
    },
    saveFocusedElement(element) {
      if (element) { this.previousFocus = element; }
    },
    restorePreviousFocus() {
      if (!this.previousFocus) { return; }
      this.$nextTick().then(() => {
        this.previousFocus && this.previousFocus.focus();
        this.previousFocus = null;
      });
    },
    toggleDeleteModal(deleteTargetGroup, target) {
      this.saveFocusedElement(target);
      this.deleteTargetGroup = deleteTargetGroup || {};
      this.showDeleteGroupDialog = !this.showDeleteGroupDialog;

      if (!this.showDeleteGroupDialog) { this.restorePreviousFocus(); }
    },
    toggleRenameModal(renameTarget, index, target) {
      this.saveFocusedElement(target);
      this.renameTargetGroup = renameTarget ? { index, target: renameTarget } : { target: {} };
      this.showGroupRenameDialog = !this.showGroupRenameDialog;

      if (!this.showGroupRenameDialog) { this.restorePreviousFocus(); }
    },
    handleDeleteResolution(targetGUID) {
      this.toggleDeleteModal();
      if (targetGUID) {
        this.removeGroupFromTable(targetGUID);
      }
    },
    handleRenamingGroup(updatedGroup) {
      const { index } = this.renameTargetGroup;
      this.groups[index].name = updatedGroup.name;
      this.groups[index].displayName = updatedGroup.displayName;
      this.toggleRenameModal();
      this.renameTargetGroup = { target: {} };
    },
    removeGroupFromTable(targetGUID) {
      const index = this.groups.findIndex(group => {
        return group.guid === targetGUID;
      });
      if (index !== -1) {
        this.groups.splice(index, 1);
      }
    },
    groupHREF(group) {
      return {
        href: groupPath(group.guid),
        title: `Group ${group.name}'s details`,
      };
    },
    toggleOptionsPanel() {
      this.showOptionsPanel = !this.showOptionsPanel;
      this.$nextTick().then(() => {
        const focused = this.showOptionsPanel ? this.$refs.optionsPanel : this.$refs.optionsButton;
        focused && focused.focus();
      });
    },
    groupImported(group) {
      // Evaluate if remote group already exists
      // and try to remove it from current page if needed
      // (to be placed on top for user to see it)
      if (group.guid) {
        this.removeGroupFromTable(group.guid);
      }
      this.groupCreated(group);
    },
    groupCreated(group) {
      this.groups.unshift(group);
      this.toggleAddModal();
      this.highlightNewGroup();
    },
    highlightNewGroup() {
      this.highlightFlag = true;
      setTimeout(() => {
        this.highlightFlag = false;
      }, 1000);
    },
    updateSearchOptions({ prefix }) {
      this.searchParameters.currentPage = 1;
      this.searchParameters.prefix = prefix;
      return this.search();
    },
    search() {
      const { prefix, currentPage } = this.searchParameters;
      const timeoutId = setTimeout(() => (this.loading = true), 300);
      return searchGroups(this.serverSettings, {
        prefix,
        includeRemote: false,
        pageNumber: currentPage,
      })
        .then(({ results, currentPage: newCurrentPage, totalPages }) => {
          this.searchParameters.currentPage = newCurrentPage;
          this.groups = results;
          this.totalPages = totalPages;
        })
        .catch(this.setErrorMessageFromAPI)
        .finally(() => {
          clearTimeout(timeoutId);
          this.loading = false;
        });
    },
    gotoPage(direction) {
      switch (direction) {
        case 'first':
          this.searchParameters.currentPage = 1;
          break;
        case 'previous':
          this.searchParameters.currentPage -= 1;
          break;
        case 'next':
          this.searchParameters.currentPage += 1;
          break;
        case 'last':
          this.searchParameters.currentPage = this.totalPages;
          break;
      }
      return this.search();
    },
    ...mapMutations({
      authOriginInit: AUTH_ORIGIN_HELP_INIT,
      setErrorMessageFromAPI: SET_ERROR_MESSAGE_FROM_API,
    }),
    ...mapActions({
      toggleHidden: AUTH_ORIGIN_HELP_UPDATE_HIDDEN,
    }),
  },
};
</script>

<style lang="scss" scoped>
@import 'Styles/shared/_mixins';

.optionsPositioner {
  position: fixed;
  z-index: 10;
  left: 0px;
  width: 100%;
  height: 0px;

  @include transition-property(left, right);
  @include normal-transition-duration();
}
.actionBar {
  margin: 0;
}

.rs-help-toggler__help-icon{
  display: inline-block;
  vertical-align: baseline;
}

.action.edit {
  background-image: url('/images/elements/actionEdit.svg');
}

.title {
  margin-left: 2px;
  padding-left: 2px;
}
</style>

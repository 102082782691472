<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<script setup>
import { JUMP_START_INIT } from '@/store/modules/jumpstart';
import DeploymentWizard from '@/views/content/DeploymentWizard';
import JumpStart from '@/views/content/JumpStart';
import PublishDropdown from '@/views/content/PublishDropdown.vue';
import PublishWizard from '@/views/content/PublishWizard';
import { computed, onBeforeMount } from 'vue';
import { useStore } from 'vuex';

const emit = defineEmits(['resetContent', 'requestPermissions']);

const store = useStore();
const currentUser = computed(() => store.state.currentUser.user);
const serverSettings = computed(() => store.state.server.settings);
const isJumpstartActive = computed(() => store.state.jumpstart.active);
const isPublishingActive = computed(() => store.state.publish.active);
const canPublish = computed(() => currentUser.value.canPublish());
const canPublishGit = computed(() => {
  return canPublish.value && serverSettings.value.gitEnabled;
});
const canRequestPublisherAccess = computed(() => {
  return (
    currentUser.value.isViewer() &&
    !serverSettings.value.viewerKiosk
  );
});

const requestPermissions = () => {
  emit('requestPermissions');
};

onBeforeMount(() => {
  if (serverSettings.value.jumpStartEnabled) {
    store.dispatch(JUMP_START_INIT);
  }
});
</script>

<template>
  <div class="content-list-ops">
    <PublishDropdown
      v-if="canPublish"
      :git-enabled="serverSettings.gitEnabled"
      :git-available="serverSettings.gitAvailable"
      :jump-start-enabled="serverSettings.jumpStartEnabled"
      new-view
    />
    <a
      v-if="canRequestPublisherAccess"
      href="#"
      class="rs-link"
      data-automation="request-publisher-access"
      @click.prevent="requestPermissions"
      @keydown.space.prevent="requestPermissions"
      @keydown.enter.prevent="requestPermissions"
    >
      Publish
    </a>

    <!-- Wizards and Modals -->
    <DeploymentWizard
      v-if="canPublishGit"
      :git-available="serverSettings.gitAvailable"
      @refresh-content-list="emit('resetContent')"
    />
    <PublishWizard v-if="canPublish && isPublishingActive && !isJumpstartActive" />
    <JumpStart v-if="canPublish && serverSettings.jumpStartEnabled && isJumpstartActive" />
  </div>
</template>

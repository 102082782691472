<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import LegacyContentListView from './LegacyContentListView.vue';
import NewContentListView from './NewContentListView.vue';
const store = useStore();
const serverSettings = computed(() => store.state.server.settings);
</script>

<template>
  <div class="band rsc-content">
    <LegacyContentListView
      v-if="!serverSettings.newContentSearchEnabled"
    />
    <NewContentListView
      v-else
    />
  </div>
</template>

<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div
    v-if="loaded"
    class="band"
  >
    <div class="bandContent mainPage">
      <div
        v-if="isCurrentUserProfile"
        class="menu"
      >
        <div class="menuItems">
          <RouterLink
            :to="{ name: 'people.users.profile', params: { guid: currentUser.guid } }"
            class="menuItem active"
            role="button"
            :aria-current="true"
          >
            Profile
          </RouterLink>
          <RouterLink
            :to="{ name: 'people.users.api_keys', params: { guid: currentUser.guid } }"
            class="menuItem"
            role="button"
          >
            API Keys
          </RouterLink>
        </div>
      </div>
      <div class="majorMinorColumnsContainer minorLeft">
        <div class="minorColumn">
          <UserInformation
            :current-user="currentUser"
            :profile-field-permissions="api.profileFieldPermissions"
            :user-profile="api.userProfile"
            :server-settings="serverSettings"
            @refresh-user-profile="fetchUserProfile"
          />
        </div>
        <div class="majorColumn">
          <PublishedContentList :user-guid="guid" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPermissions, getUser } from '@/api/users';
import {
  CLEAR_ACTIVITY_MESSAGE,
  SET_ACTIVITY_MESSAGE,
  SET_ERROR_MESSAGE_FROM_API,
} from '@/store/modules/messages';
import { mapMutations, mapState } from 'vuex';
import PublishedContentList from './PublishedContentList';
import UserInformation from './UserInformation';
import { RouterLink } from 'vue-router';

export default {
  name: 'UserProfileView',
  components: {
    PublishedContentList,
    UserInformation,
    RouterLink,
  },
  props: {
    guid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loaded: false,
      api: {
        profileFieldPermissions: null,
        userProfile: null,
      },
    };
  },
  computed: {
    isCurrentUserProfile() {
      return this.currentUser.guid === this.api.userProfile.guid;
    },
    ...mapState({
      currentUser: state => state.currentUser.user,
      serverSettings: state => state.server.settings,
    }),
  },
  created() {
    const page = this.$route.name;

    this.setActivityMessage({
      page,
      message: 'Loading User Profile...',
    });

    return Promise.all([
      getUser(this.guid),
      getPermissions(this.guid),
    ])
      .then(
        ([
          userProfile,
          profileFieldPermissions,
        ]) => {
          this.api.userProfile = userProfile;
          this.api.profileFieldPermissions = profileFieldPermissions;
          this.loaded = true;
        }
      )
      .catch(this.setErrorMessageFromAPI)
      .finally(() => {
        this.clearActivityMessage();
      });
  },
  methods: {
    ...mapMutations({
      setErrorMessageFromAPI: SET_ERROR_MESSAGE_FROM_API,
      setActivityMessage: SET_ACTIVITY_MESSAGE,
      clearActivityMessage: CLEAR_ACTIVITY_MESSAGE,
    }),
    async fetchUserProfile() {
      return getUser(this.guid)
        .then(userProfile => (this.api.userProfile = userProfile))
        .catch(this.setErrorMessageFromAPI);
    },
  },
};
</script>

<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <RSModal
    v-if="active"
    :subject="modalSubject"
    close-button-label="Close Modal"
    :active="active"
    :aria-label="modalSubject"
    dialog-class="publish-wizard"
    role="dialog"
    data-automation="publish-wizard__modal"
    @close="close"
  >
    <template #content>
      <!--
        Despite this component clearly having a bound "is", this check complains.
        Possibly relevant: https://github.com/vuejs/eslint-plugin-vue/issues/869 
      -->
      <!-- eslint-disable vue/require-component-is -->
      <component
        :is="currentPanel"
        :step="stepNum + 1"
        class="publish-wizard__panel"
      />
      <!-- eslint-enable vue/require-component-is -->
    </template>
    <template #controls>
      <RSButton
        data-automation="publish-wizard__back-button"
        label="Back"
        :class="[
          'publish-wizard__button',
          'publish-wizard__back-button',
          { 'publish-wizard__back-button--hidden': !showBackButton }
        ]"
        type="secondary"
        @click="back"
      />
      <progress
        class="publish-wizard__progress"
        :aria-label="`Step ${stepNum + 1} of ${numSteps}`"
        :max="numSteps"
        :value="stepNum + 1"
      />
      <RSButton
        v-show="showNextButton"
        data-automation="publish-wizard__next-button"
        label="Next"
        class="publish-wizard__button publish-wizard__next-button"
        @click="next"
      />
      <RSButton
        v-show="showDoneButton"
        data-automation="publish-wizard__done-button"
        label="Done"
        class="publish-wizard__button publish-wizard__done-button"
        @click="close"
      />
    </template>
  </RSModal>
</template>

<script>
import {
  PUBLISH_WIZARD_BACK,
  PUBLISH_WIZARD_CLOSE,
  PUBLISH_WIZARD_NEXT,
} from '@/store/modules/publish';
import { mapActions, mapMutations, mapState } from 'vuex';

import {
  JUPYTER_NOTEBOOK,
  JUPYTER_VOILA,
  PIN,
  PLUMBER_API,
  PYTHON_API,
  PYTHON_BOKEH,
  PYTHON_DASH,
  PYTHON_FASTAPI,
  PYTHON_SHINY,
  PYTHON_STREAMLIT,
  PYTHON_VETIVER,
  QUARTO,
  QUARTO_PYTHON,
  QUARTO_PYTHON_SCRIPT,
  QUARTO_R,
  QUARTO_R_SCRIPT,
  QUARTO_WEBSITE,
  RMARKDOWN,
  RMARKDOWN_PARAMS,
  R_VETIVER,
  SHINY_APP,
  SHINY_RMARKDOWN,
} from '@/constants/contentTypes';
import RSButton from '@/elements/RSButton';
import RSModal from '@/elements/RSModal';

import JupyterSteps from './JupyterSteps';
import JupyterVoilaSteps from './JupyterVoilaSteps';
import PinSteps from './PinSteps';
import PlumberAPISteps from './PlumberAPISteps';
import PythonAPISteps from './PythonAPISteps';
import PythonBokehSteps from './PythonBokehSteps';
import PythonDashSteps from './PythonDashSteps';
import PythonFastAPISteps from './PythonFastAPISteps';
import PythonShinySteps from './PythonShinySteps';
import PythonStreamlitSteps from './PythonStreamlitSteps';
import PythonVetiverSteps from './PythonVetiverSteps';
import QuartoPythonScriptSteps from './QuartoPythonScriptSteps';
import QuartoPythonSteps from './QuartoPythonSteps';
import QuartoRScriptSteps from './QuartoRScriptSteps';
import QuartoRSteps from './QuartoRSteps';
import QuartoSteps from './QuartoSteps';
import QuartoWebsiteSteps from './QuartoWebsiteSteps';
import RMarkdownParamsSteps from './RMarkdownParamsSteps';
import RMarkdownSteps from './RMarkdownSteps';
import RVetiverSteps from './RVetiverSteps';
import ShinyAppSteps from './ShinyAppSteps';

const contentTypeInfo = {
  [JUPYTER_NOTEBOOK]: { steps: JupyterSteps, label: 'Publish a Jupyter Notebook' },
  [JUPYTER_VOILA]: { steps: JupyterVoilaSteps, label: 'Publish a Voilà Dashboard' },
  [PIN]: { steps: PinSteps, label: 'Publish a Pin' },
  [PLUMBER_API]: { steps: PlumberAPISteps, label: 'Publish a Plumber API' },
  [PYTHON_API]: { steps: PythonAPISteps, label: 'Publish a Python API' },
  [PYTHON_BOKEH]: { steps: PythonBokehSteps, label: 'Publish a Bokeh App' },
  [PYTHON_DASH]: { steps: PythonDashSteps, label: 'Publish a Dash App' },
  [PYTHON_FASTAPI]: { steps: PythonFastAPISteps, label: 'Publish a Python FastAPI' },
  [PYTHON_SHINY]: { steps: PythonShinySteps, label: 'Publish a Python Shiny App' },
  [PYTHON_STREAMLIT]: { steps: PythonStreamlitSteps, label: 'Publish a Streamlit App' },
  [PYTHON_VETIVER]: { steps: PythonVetiverSteps, label: 'Publish a Vetiver API in Python' },
  [QUARTO]: { steps: QuartoSteps, label: 'Publish Quarto Content' },
  [QUARTO_PYTHON]: { steps: QuartoPythonSteps, label: 'Publish Quarto Content using Python' },
  [QUARTO_PYTHON_SCRIPT]: { steps: QuartoPythonScriptSteps, label: 'Publish a Python Script using Quarto' },
  [QUARTO_R]: { steps: QuartoRSteps, label: 'Publish Quarto Content using R' },
  [QUARTO_R_SCRIPT]: { steps: QuartoRScriptSteps, label: 'Publish an R Script using Quarto' },
  [QUARTO_WEBSITE]: { steps: QuartoWebsiteSteps, label: 'Publish a Quarto website' },
  [RMARKDOWN]: { steps: RMarkdownSteps, label: 'Publish an R Markdown Document' },
  [RMARKDOWN_PARAMS]: { steps: RMarkdownParamsSteps, label: 'Publish a Parameterized R Markdown Document' },
  [R_VETIVER]: { steps: RVetiverSteps, label: 'Publish a Vetiver API in R' },
  [SHINY_APP]: { steps: ShinyAppSteps, label: 'Publish a Shiny App' },
  [SHINY_RMARKDOWN]: { steps: ShinyAppSteps, label: 'Publish a Shiny R Markdown Document' },
};

export default {
  name: 'PublishWizard',
  components: {
    RSModal,
    RSButton,
  },
  computed: {
    ...mapState({
      active: state => state.publish.active,
      stepNum: state => state.publish.stepNum,
      contentType: state => state.publish.contentType,
      copyFrom: state => state.publish.copyFrom,
    }),
    modalSubject() {
      return this.copyFrom
        ? `Publish ${this.copyFrom.title}`
        : `${contentTypeInfo[this.contentType].label}`;
    },
    steps() {
      return this.copyFrom
        ? contentTypeInfo[this.contentType].steps.copyFrom
        : contentTypeInfo[this.contentType].steps.bare;
    },
    numSteps() {
      return this.steps.length;
    },
    isLastStep() {
      return this.stepNum === this.numSteps - 1;
    },
    currentPanel() {
      return this.steps[this.stepNum];
    },
    showBackButton() {
      if (this.stepNum !== 0) {
        return true;
      }
      return (this.copyFrom !== null && this.copyFrom !== undefined);
    },
    showNextButton() {
      return !this.isLastStep;
    },
    showDoneButton() {
      return this.isLastStep;
    },
  },
  methods: {
    ...mapMutations({
      close: PUBLISH_WIZARD_CLOSE,
      next: PUBLISH_WIZARD_NEXT,
    }),
    ...mapActions({
      back: PUBLISH_WIZARD_BACK,
    }),
  },
};
</script>

<style lang="scss">
@import 'Styles/shared/_colors';
@import 'Styles/shared/_mixins';

.publish-wizard {
  white-space: normal;

  &__panel {
    margin: 0 -2rem 15px -2rem;
    border-color: $color-light-grey-3;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-style: solid;

    min-height: 400px;
    background-repeat: no-repeat;
  }

  &__back-button--hidden {
    visibility: hidden;
  }

  > .rs-modal__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: none;

    button:first-child {
      margin-left: 0;
    }
  }

  .publish-wizard__progress {
    appearance: none;
    border-radius: 3px;
    background-color: $color-light-grey-3;

    &::-moz-progress-bar {
      background-color: $color-posit-teal;
    }
    &::-webkit-progress-bar {
      background-color: $color-light-grey-3;
      border-radius: 3px;
    }
    &::-webkit-progress-value {
      background-color: $color-posit-teal;
      border-radius: 3px;
    }
  }
}

.wizard-panel {
  p {
    margin-bottom: 1em;
  }

  &__notice {
    color: #202020;
    border: 1px solid $color-primary;
    background-color: $color-light-blue;
    margin: 1.5rem 0 0 0;
    padding: 1rem;
  }

  &--blue {
    background-color: $color-primary-dark;
    color: $color-white;
    * {
      background-color: $color-primary-dark;
    }
  }

  &--white {
    background-color: $color-white;
    color: $color-dark-grey-3;
    * {
      background-color: $color-white;
    }
  }

  &__content {
    background-color: transparent;
    font-size: 16px;
    margin-left: 30px;
    margin-right: 30px;
    min-height: 522px;

    &--no-title {
      margin-top: 30px;
    }
  }

  &__section {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  &__title {
    font-size: 30px;
    margin-bottom: 15px;
    margin-top: 30px;
    color: $color-primary-dark;

    &--step {
      &:before {
        color: $color-white;
        display: inline-block;
        height: 3rem;
        width: 3rem;
        line-height: 3rem;
        text-align: center;
        border-radius: 3rem;
        background-color: $color-primary-dark;
        font-weight: 400;
        margin-right: 1rem;
      }

      @for $step from 1 through 9 {
        &-#{$step}:before {
          content: '#{$step}';
        }
      }
    }

    &--done {
      color: $color-white;
      padding-left: 3rem;
      background-repeat: no-repeat;
      background-image: url('/images/elements/checkWhite.svg');
      background-size: 3rem 3rem;
      background-position: left center;
    }
  }

  &__intro {
    margin-top: 1.5rem;
  }

  &__subtitle {
    font-weight: bold;
    font-size: 1.0rem;
  }

  &__copy {
    position: relative;
    padding-bottom: 2rem;
  }

  &__copy-code {
    color: $color-terminal;
    display: inline-block;
    align-content: center;
    flex-grow: 1;
    background-color: $color-terminal-background;
    border: 1px solid #aaa;
    border-radius: 0;
    font-size: 0.8rem;
    line-height: 1.3;
    margin-bottom: 0;
    padding: 0.3rem;
  }

  &__copy-button--multiline {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 4px 10px !important;
    font-size: 11px !important;
    min-width: 0 !important;
  }

  &__links {
    li {
      margin-bottom: 0.5rem;
    }

    li:last-child {
      margin-bottom: 0;
    }
  }

  &__outro-link {
    text-decoration: underline;
  }
}

@include for-small-screens() {
  .publish-wizard {
    &__button {
      min-width: auto !important;
    }
  }
}
</style>

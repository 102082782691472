<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<template>
  <RSModal
    v-if="showModal"
    :active="true"
    subject="Integrations"
    data-automation="oauth-modal"
    @close="authModalDismissed = true"
  >
    <template #content>
      <p>For full functionality of this content, please log in below:</p>
      <div
        v-for="(auth, i) in loggedOutAssociations"
        :key="i"
      >
        <div class="integration-name">
          {{ auth.oauthIntegrationDescription }}
        </div>
        <a
          :href="oauthLogin(auth)"
          class="login-button"
        >Login</a>
      </div>
      <div class="rsc-alert info">
        You can manage your integration settings at any time 
        from the <button
          class="link-button"
          @click="runAction"
        >
          Access
        </button> tab in the Content Settings panel.
      </div>
    </template>
    <template #controls>
      <RSButton
        type="secondary"
        label="Cancel"
        @click="authModalDismissed = true"
      />
    </template>
  </RSModal>
</template>

<script>
import {
  CLOSE_PANEL,
  OPEN_PANEL,
  SETTINGS_PANEL,
  TOGGLE_PANELS,
} from '@/store/modules/contentView';
import RSButton from '@/elements/RSButton';
import RSModal from '@/elements/RSModal';
import { getContentAssociations, getUserSessions } from '@/api/oauth';
import { oauthLoginPath } from '@/utils/paths';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'OAuthLoginModal',
  components: {
    RSButton,
    RSModal,
  },
  data() {
    return {
      contentAssociations: [],
      oauthSessions: [],
      authModalDismissed: false,
      loggedOutAssociations: [],
    };
  },
  computed: {
    ...mapState({
      app: state => state.contentView.app,
      serverSettings: state => state.server.settings,
    }),
    showModal() {
      return this.serverSettings.oauthIntegrationsEnabled &&
        !this.authModalDismissed &&
        this.contentAssociations.length > 0 &&
        this.loggedOutAssociations.length > 0;
    },
  },
  async created() {
    await this.oauthInfo();
    this.loginList();
  },
  methods: {
    ...mapActions({
      togglePanels: TOGGLE_PANELS,
    }),
    async oauthInfo() {
      this.oauthSessions = await getUserSessions();
      this.contentAssociations = await getContentAssociations(this.app.guid);
    },
    oauthLogin(auth) {
      return oauthLoginPath({ guid: auth.oauthIntegrationGuid, redirect: window.location.href });
    },
    runAction() {
      this.$router.push({
        name: 'apps.access',
        params: { idOrGuid: this.app.guid },
        replace: true,
      });
      const togglePayload = {
        panel: SETTINGS_PANEL,
        action: this.isOpen ? CLOSE_PANEL : OPEN_PANEL,
      };
      this.togglePanels(togglePayload);
    },
    loginList() {
      const simplifiedSessions = {};
      for (const session of this.oauthSessions) {
        simplifiedSessions[session.oauthIntegrationGuid] = session.loggedIn;
      }

      for (const association of this.contentAssociations) {
        if (!Object.keys(simplifiedSessions).includes(association.oauthIntegrationGuid) ||
          simplifiedSessions[association.oauthIntegrationGuid] === false)
        {
          this.loggedOutAssociations.push(association);
        }
      }
      
      return this.loggedOutAssociations;
    }
  }

};
</script>

<style scoped lang="scss">
@import 'Styles/shared/_colors';
@import 'Styles/shared/_variables';

  .integration-name {
    margin-top: 1em;
  }

  .login-button {
    min-width: 8.75rem;
    margin: 0.5em 0 1.5em;
    padding: 0.7rem 1.1rem;
    font-size: $rs-font-size-normal;
    line-height: 1rem;
    overflow: visible;
    border: none;
    border-radius: 3px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition-duration: 250ms;
    text-align: center;
    background-color: $color-secondary;
    color: $color-secondary-inverse;
    display: inline-block;
    font-weight: normal;
    text-decoration: none;
    &:hover {
      background-color: $color-secondary-hover;
      text-decoration: none;
    }
  }

  .rsc-alert {
    line-height: 1.5em;
    margin-top: 0.7em;
  }
  .link-button {
    background-color: transparent;
    color: $color-posit-blue;
    font-size: 1em;
    padding: 0;
    text-decoration: underline;

    &:hover {
      color: $color-dark-grey-3;
    }
  }
</style>

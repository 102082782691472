// Copyright (C) 2022 by Posit Software, PBC.

import { cloneDeep } from 'lodash';
import { getApp } from '@/api/app';
import { getJob, getJobs, getJobError } from '@/api/jobs';
import { JobTag } from '@/api/dto/job';
import { getBundleData } from '@/api/bundle';

export const LOGS_OVERLAY_FETCH_APP = 'LOGS_OVERLAY_FETCH_APP';
export const LOGS_OVERLAY_FETCH_APP_JOB_LIST = 'LOGS_OVERLAY_FETCH_APP_JOB_LIST';
export const LOGS_OVERLAY_FETCH_APP_JOB = 'LOGS_OVERLAY_FETCH_APP_JOB';
export const LOGS_OVERLAY_RESET_VIEW = 'LOGS_OVERLAY_RESET_VIEW';
export const LOGS_OVERLAY_FETCH_TYPES = 'LOGS_OVERLAY_FETCH_TYPES';
export const LOGS_OVERLAY_FETCH_BUNDLES = 'LOGS_OVERLAY_FETCH_BUNDLES';
export const LOGS_OVERLAY_UPDATE_JOBS = 'LOGS_OVERLAY_UPDATE_JOBS';
export const LOGS_OVERLAY_UPDATE_CURRENT_JOB = 'LOGS_OVERLAY_UPDATE_CURRENT_JOB';
export const LOGS_OVERLAY_SET_APP = 'LOGS_OVERLAY_SET_APP';
export const LOGS_OVERLAY_UPDATE_BUNDLES = 'LOGS_OVERLAY_UPDATE_BUNDLES';
export const LOGS_OVERLAY_UPDATE_TYPES = 'LOGS_OVERLAY_UPDATE_TYPES';
export const LOGS_OVERLAY_CLEAR = 'LOGS_OVERLAY_CLEAR';

function defaultState() {
  return {
    app: null,
    jobs: [],
    currentJob: null,
    createdBundles: {},
    jobTypes: [{ value: 'All', description: 'All logs' }],
  };
}

export default {
  state: defaultState(),
  mutations: {
    [LOGS_OVERLAY_UPDATE_JOBS](state, jobs) {
      state.jobs = jobs;
    },
    [LOGS_OVERLAY_UPDATE_CURRENT_JOB](state, job) {
      state.currentJob = job;
    },
    [LOGS_OVERLAY_CLEAR](state) {
      Object.assign(state, defaultState());
    },
    [LOGS_OVERLAY_SET_APP](state, app) {
      state.app = app;
    },
    [LOGS_OVERLAY_UPDATE_BUNDLES](state, bundles) {
      state.createdBundles = bundles;
    },
    [LOGS_OVERLAY_UPDATE_TYPES](state, types) {
      state.jobTypes = types;
    }
  },
  actions: {
    async [LOGS_OVERLAY_FETCH_APP]({ commit }, appId) {
      const app = await getApp(appId);
      commit(LOGS_OVERLAY_SET_APP, app);
      commit(LOGS_OVERLAY_UPDATE_BUNDLES, {});
    },
    async [LOGS_OVERLAY_FETCH_APP_JOB_LIST]({ state, dispatch, commit }) {
      const jobs = state.app.guid ? await getJobs(state.app.guid) : null;
      if (jobs !== null && jobs.length > 0) {
        dispatch(LOGS_OVERLAY_FETCH_TYPES);
        dispatch(LOGS_OVERLAY_FETCH_BUNDLES);
      }
      commit(LOGS_OVERLAY_UPDATE_JOBS, jobs);
    },
    async [LOGS_OVERLAY_FETCH_APP_JOB]({ commit, state }, key) {
      const job = state.app.guid ? await getJob(state.app.guid, key) : null;

      if (job && job.isError()) {
        const error = await getJobError(state.app.guid, key);
        if (error) {
          job.loggedError = error;
        }
      }

      commit(LOGS_OVERLAY_UPDATE_CURRENT_JOB, job);
    },
    async [LOGS_OVERLAY_RESET_VIEW]({ dispatch, commit, state }) {
      await dispatch(LOGS_OVERLAY_FETCH_APP_JOB_LIST);
      const newCurrentJob = state.jobs !== null && state.jobs.length ? state.jobs[0] : null;
      commit(LOGS_OVERLAY_UPDATE_CURRENT_JOB, newCurrentJob);
    },
    [LOGS_OVERLAY_FETCH_TYPES]({ commit, state }) {
      const types = [{ value: 'All', description: 'All logs' }];
      state.jobs.forEach(job => {
        if (!types.some(type => type.value === job.tag)) {
          types.push({ value: job.tag, description: JobTag.description(job.tag) });
        }
      });
      commit(LOGS_OVERLAY_UPDATE_TYPES, types);
    },
    [LOGS_OVERLAY_FETCH_BUNDLES]({ commit, state }) {
      const bundles = cloneDeep(state.createdBundles);
      const dedupedBundleList = [];
      state.jobs.forEach(job => {
        if (!dedupedBundleList.includes(job.bundleId) && !state.createdBundles[job.bundleId]) {
          dedupedBundleList.push(job.bundleId);
        }
      });
      Promise.all(dedupedBundleList.map(
        bundle => getBundleData(bundle).then(
          res => (bundles[bundle] = res.created_time)
        )
      )).then(
        () => commit(LOGS_OVERLAY_UPDATE_BUNDLES, bundles)
      )
        .catch(err => {
          // Ignore errors due to bundles not present anymore
          if (err?.response?.status !== 404) {
            throw err;
          }
        });
    }
  }
};

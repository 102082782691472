<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div>
    <InstallationAddOrEditDialog
      v-if="showInstallationAddOrEditDialog"
      :installation="activeInstallation"
      @close="toggleInstallationAddOrEditDialog"
      @submit="handleAddOrEditInstallation"
    />
    <RSModalForm
      v-else
      :active="true"
      :subject="dialogTitle"
      class="scroll-y"
      @close="$emit('close')"
      @submit="onSubmit"
    >
      <template #content>
        <fieldset :disabled="processing">
          <RSInputText
            v-model.trim="form.title"
            label="Title"
            :message="errorForTitle"
            name="title"
            data-automation="title"
            autocomplete="off"
            help="Provide a title for this environment."
            @change="userActionDetected('title')"
          />
          <RSInputText
            v-model.trim="form.description"
            label="Description"
            :message="errorForDescription"
            name="description"
            data-automation="description"
            autocomplete="off"
            help="Provide a description which will elaborate on the use and purpose of this environment."
            :lines="4"
            @change="userActionDetected('description')"
          />
          <RSInputText
            v-model.trim="form.name"
            :readonly="isAddOrEdit === 'EDIT'"
            label="Image name (in repository)"
            :message="errorForName"
            name="name"
            data-automation="name"
            help="The location of your image within a container repository.
              Image name must be unique across the defined environments and cannot be adjusted later.
              For example: ghcr.io/rstudio/content-base:r4.1.0-py3.9.2-jammy"
            @change="userActionDetected('name')"
          />
          <div
            class="actionBar showTitles installation-list"
          >
            <label
              for="descriptionLabel"
              class="lh-1"
            >
              Installations Available
            </label>
            <button
              ref="addInstallation"
              class="action new"
              type="button"
              data-automation="add-installation-button"
              @click="onAddInstallation"
            >
              Add Installation
            </button>
          </div>
          <div
            class="rs-field installation-list__table-container"
          >
            <table
              v-if="form.installations && form.installations.length > 0"
              class="installation-list__table"
            >
              <tr class="installation-list__table__header">
                <th class="installation-list__table__type-column__header">
                  Type
                </th>
                <td class="installation-list__table__version-column__header">
                  Version
                </td>
                <td class="installation-list__table__path-column__header">
                  Path
                </td>
                <td class="installation-list__table__action-column__header">
                  &nbsp;
                </td>
              </tr>
              <tr
                v-for="installation in form.installations"
                :key="installation.id"
                class="installation-list__installation"
              >
                <td class="installation-list__table__type-column__data">
                  <div>
                    {{ installation.type }}
                  </div>
                </td>
                <td class="installation-list__table__version-column__data">
                  <div>
                    {{ installation.version.major + '.' + installation.version.minor + '.' + installation.version.patch }}
                  </div>
                </td>
                <td class="installation-list__table__path-column__data">
                  <div class="ws-auto">
                    {{ installation.path }}
                  </div>
                </td>
                <td class="installation-list__table__action-column__data">
                  <div>
                    <button
                      type="button"
                      class="installation-list__edit-button action-button"
                      data-automation="edit-installation-button"
                      @click="onEditInstallation(installation)"
                    >
                      <img
                        src="/images/itemActionEdit.svg"
                        alt="edit environment"
                        class="editActionButton row-action-button"
                      >
                    </button>
                    <button
                      type="button"
                      class="installation-list__delete-button action-button"
                      data-automation="delete-installation-button"
                      @click="onDeleteInstallation(installation)"
                    >
                      <img
                        src="/images/itemActionDelete.svg"
                        alt="remove environment"
                        class="deleteActionButton row-action-button"
                      >
                    </button>
                  </div>
                </td>
              </tr>
            </table>
            <template
              v-if="form.installations && form.installations.length === 0"
            >
              <table
                class="installation-list__table"
              >
                <tr class="installation-list__table__header">
                  <td class="installation-list__table__type-column__header">
                    Type
                  </td>
                  <td class="installation-list__table__version-column__header">
                    Version
                  </td>
                  <td class="installation-list__table__path-column__header">
                    Path
                  </td>
                  <td class="installation-list__table__action-column__header">
                    &nbsp;
                  </td>
                </tr>
              </table>
              <div
                class="installation-list__table--empty"
              >
                Environments are not used by Connect until an installation has been added.
              </div>
            </template>
          </div>
          <Accordion
            class="rs-field"
            :is-open="accordionOpen"
            @toggle="onAccordionToggle"
          >
            <template
              #header
            >
              <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions -->
              <div
                class="accordion-label"
                @click="onAccordionToggle"
                @keyup="onAccordionToggle"
              >
                Advanced
              </div>
            </template>
            <div>
              <RSInputSelect
                v-model="form.matching"
                :options="matchingOptions"
                label="Matching strategy"
                data-automation="matching"
                name="matching"
                help="The matching strategy influences how the server
                  will decide to use this Execution Environment when matching it to content
                  bundles. This is an advanced option and is normally
                  not required."
                @change="userActionDetected('matching')"
              />
              <RSInputText
                v-model.trim="form.supervisor"
                label="Supervisor script path"
                :message="errorForSupervisor"
                name="supervisor"
                data-automation="supervisor"
                help="The absolute path to the supervisor script within the image. Supervisors are
                  used during the initialization of the Execution Environment.
                  This is an advanced option and is normally not required."
                @change="userActionDetected('supervisor')"
              />
            </div>
          </Accordion>
        </fieldset>
      </template>
      <template #controls>
        <RSButton
          id="ucd-submit"
          :label="dialogSubmitLabel"
          data-automation="environment-add-or-edit-dialog-submit"
          :disabled="disableSubmit"
        />
      </template>
    </RSModalForm>
  </div>
</template>

<script>
import RSButton from '@/elements/RSButton';
import RSInputSelect from '@/elements/RSInputSelect';
import RSInputText from '@/elements/RSInputText';
import RSModalForm from '@/elements/RSModalForm';
import InstallationAddOrEditDialog from './InstallationAddOrEditDialog';

import Accordion from '@/components/Accordion';

import { Environment, Installation } from '@/api/dto/environment';
import { onEnvServerError } from '@/utils/environments';
import {
  containsControlWhitespace,
  containsSpaceCharacter,
  invalidAbsoluteUnixFilepath,
  isEmptyValue,
} from '@/utils/validation';

export default {
  name: 'EnvironmentAddOrEditDialog',
  components: {
    RSButton,
    RSInputText,
    RSInputSelect,
    RSModalForm,
    InstallationAddOrEditDialog,
    Accordion,
  },
  props: {
    environment: {
      type: Object,
      required: true,
    },
  },
  emits: ['add', 'close', 'edit'],
  data() {
    return {
      updatedEnvironment: {},
      processing: false,
      activityTimeoutId: null,
      form: {
        title: '',
        name: '',
        description: '',
        matching: 'any',
        supervisor: '',
        installations: [],
      },
      userActivity: {
        title: false,
        name: false,
        description: false,
        matching: false,
        supervisor: false,
        installations: false,
      },
      serverError: {
        name: null,
      },
      status: {
        show: false,
        message: null,
        type: null,
      },
      matchingOptions: [
        {
          label: 'none',
          value: 'none',
        },
        {
          label: 'any',
          value: 'any',
        },
        {
          label: 'exact',
          value: 'exact',
        },
      ],
      showInstallationAddOrEditDialog: false,
      activeInstallation: null,
      nextInstallationKey: 0,
      actionDetectedFlag: false,
      accordionOpen: false,
    };
  },
  computed: {
    isAddOrEdit() {
      return this.environment.name === undefined ? 'ADD' : 'EDIT';
    },
    dialogTitle() {
      return this.isAddOrEdit === 'ADD' ? 'Add Environment' : 'Edit Environment';
    },
    dialogSubmitLabel() {
      return this.isAddOrEdit === 'ADD' ? 'Add' : 'Update';
    },
    disableSubmit() {
      return !this.actionDetectedFlag || !this.inputValid;
    },
    inputValid() {
      return (
        !this.titleInvalid &&
        !this.nameInvalid &&
        !this.descriptionInvalid &&
        !this.supervisorInvalid
      );
    },
    titleInvalid() {
      // value is required
      // the title must be between 1 and 64 characters
      // and cannot contain '\t\n\b\f\r' characters
      return (
        isEmptyValue(this.form.title) ||
        containsControlWhitespace(this.form.title, false) ||
        Boolean(this.form && this.form.title && this.form.title.length > 64)
      );
    },
    errorForTitle() {
      // only show after user has made a change in the field
      return this.titleInvalid && this.userActivity.title
        ? `Title must be provided, be no longer than 64 characters in length,
          and cannot contain the characters: tab, newline, backspace, 
          form-feed or return.`
        : null;
    },
    nameInvalid() {
      // value is required
      // and cannot contain '\t\n\b\f\r' characters
      // a unique name for the environment must be provided - validated by api call
      return (
        isEmptyValue(this.form.name) ||
        containsControlWhitespace(this.form.name, false) ||
        containsSpaceCharacter(this.form.name) ||
        this.serverError.name !== null
      );
    },
    errorForName() {
      if (this.serverError.name !== null) {
        return this.serverError.name;
      }
      return this.nameInvalid && this.userActivity.name
        ? `Image name must be provided, cannot contain the characters: space, tab, newline, backspace,
          form-feed or return and must be unique across Execution Environments.`
        : null;
    },
    descriptionInvalid() {
      // not required
      // cannot contain '\t\b\f\r' characters (but can contain \n)
      // cannot be more than 4096 characters
      return (
        containsControlWhitespace(this.form.description, true) ||
        Boolean(this.form && this.form.description && this.form.description.length > 4096)
      );
    },
    errorForDescription() {
      return this.descriptionInvalid && this.userActivity.description
        ? `Description is optional but if provided, can be no longer than 4096 characters in length,
          and cannot contain the characters: tab, backspace, or form-feed (returns are allowed).`
        : null;
    },
    supervisorInvalid() {
      // not required
      // The installation path must be an absolute Unix filepath (it must start with '/')
      return (
        containsControlWhitespace(this.form.supervisor, false) ||
        invalidAbsoluteUnixFilepath(this.form.supervisor)
      );
    },
    errorForSupervisor() {
      return this.supervisorInvalid && this.userActivity.supervisor
        ? `Supervisor script path is optional but if provided, must start with
          a slash (/) but not end with a slash (/) and cannot contain 
          the characters: tab, newline, backspace, form-feed or return.`
        : null;
    },
  },
  watch: {
    environment() {
      this.updateForm();
    }
  },
  mounted() {
    this.updateForm();
    this.$el.querySelector('#title').focus();
  },
  methods: {
    userActionDetected(fieldVar) {
      this.actionDetectedFlag = true;
      if (fieldVar !== undefined) {
        this.userActivity[fieldVar] = true;
        // This will reset a server error upon any change in a field.
        this.serverError[fieldVar] = null;
      }
    },
    toggleInstallationAddOrEditDialog() {
      this.showInstallationAddOrEditDialog = !this.showInstallationAddOrEditDialog;
      if (!this.showInstallationAddOrEditDialog) {
        this.$nextTick().then(() => this.$refs.addInstallation.focus());
      }
    },
    handleAddOrEditInstallation(installation) {
      if (installation.id === -1) {
        // add
        installation.id = new Date().getTime() - 1000;
        this.form.installations.push(installation);
      } else {
        const ndx = this.form.installations.findIndex(item => item.id === installation.id);
        this.form.installations[ndx] = installation;
      }
      this.sortFormInstalls();
      this.toggleInstallationAddOrEditDialog();
      this.userActionDetected('installations');
    },
    updateForm() {
      if (this.environment.guid !== undefined) {
        this.form.title = this.environment.title;
        this.form.name = this.environment.name;
        this.form.description = this.environment.description;
        this.form.matching = this.environment.matching;
        this.form.supervisor = this.environment.supervisor;
        this.form.installations = [...this.environment.installations]; // shallow copy ok, simple object
        this.sortFormInstalls();
      } else {
        this.form.title = '';
        this.form.name = '';
        this.form.description = '';
        this.form.matching = 'any';
        this.form.supervisor = '';
        this.form.installations = [];
      }
    },
    onSubmit() {
      const environment = new Environment(this.form);

      if (this.isAddOrEdit === 'ADD') {
        this.$emit('add', environment);
      } else {
        environment.guid = this.environment.guid;
        this.$emit('edit', environment);
      }
    },
    sortFormInstalls() {
      if (this.form.installations === undefined || this.form.installations === null) {
        return;
      }
      this.form.installations.sort((a, b) => {
        // Order by installation type, then version.
        // Python, Quarto, R, TensorFlow
        const ordering = ['Python', 'Quarto', 'R', 'TensorFlow'];
        const cmp = ordering.indexOf(a.type) - ordering.indexOf(b.type);
        if (cmp === 0) {
          if (a.version.major > b.version.major) {
            return 1;
          }
          if (a.version.major < b.version.major) {
            return -1;
          }
          if (a.version.minor > b.version.minor) {
            return 1;
          }
          if (a.version.minor < b.version.minor) {
            return -1;
          }
          if (a.version.patch > b.version.patch) {
            return 1;
          }
          if (a.version.patch < b.version.patch) {
            return -1;
          }
          return 0;
        }
        return cmp;
      });
    },
    onEditInstallation(installation) {
      this.activeInstallation = installation;
      this.toggleInstallationAddOrEditDialog();
    },
    onDeleteInstallation(installation) {
      this.form.installations = this.form.installations.filter(item => item.id !== installation.id);
      this.userActionDetected('installations');
    },
    onAddInstallation() {
      this.activeInstallation = new Installation({
        type: '',
        path: '',
        version: {
          major: 0,
          minor: 0,
          patch: 0,
        },
      });

      this.toggleInstallationAddOrEditDialog();
    },
    handleServerError(errorField) {
      if (onEnvServerError(errorField)) {
        this.serverError.name = errorField.msg;
        return true;
      }
      return false;
    },
    onAccordionToggle() {
      // only allow toggle if we are not in error state (and not attempting to close the
      // accordion, as it would possibly hide an error.
      if (this.inputValid || (!this.inputValid && !this.accordionOpen)) {
        this.accordionOpen = !this.accordionOpen;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'Styles/shared/_colors';
@import 'Styles/shared/_mixins';

.scroll-y {
  overflow-y: auto;
}
.lh-1 {
  line-height: 1;
}
.ml-1 {
  margin-left: 1rem;
}
.ws-auto {
  white-space: auto;
}
.installation-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
  margin-right: unset;
  margin-bottom: 0.25rem;

  &__table-container {
    height: 125px;
    border: 1px solid lightgray;
    overflow-y: auto;
  }
  &__table {
    border: none;
    width: 100%;
    table-layout: fixed;

    &__header {
      background-color: aliceblue;
    }
    &__type-column {
      &__header {
        width: 50px;
        padding: 5px 0 5px 5px;
      }
      &__data {
        vertical-align: middle;
        padding-left: 5px;
      }
    }
    &__version-column {
      &__header {
        width: 40px;
      }
      &__data {
        vertical-align: middle;
      }
    }
    &__path-column {
      &__header {
        width: 150px;
      }
      &__data {
        vertical-align: middle;
      }
    }
    &__action-column {
      &__header {
        width: 43px;
      }
      &__data {
        vertical-align: middle;
        padding-left: 5px;
      }
    }
    &--empty {
      height: 5rem;
      align-items: center;
      text-align: center;
      color: $color-dark-grey;
      display: inline-flex;
      margin: 0 5rem;
      line-height: 1.5rem;
    }
  }
}
.action-button {
  padding: 0;
  background-color: $color-white;
  @include control-visible-focus;
  &:hover {
    background-color: $color-button-background-hover;
  }
}
.row-action-button {
  width: 29px; filter: brightness(0) saturate(100%);
}

.accordion-label {
  margin-left: 1.6rem;
}
</style>

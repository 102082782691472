<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<script setup>
import {
  unref,
  reactive,
  computed,
  onBeforeMount,
} from 'vue';
import { useStore } from 'vuex';
import { cloneDeep } from 'lodash';
import { searchUsers } from '@/api/users';
import { getTagsTree } from '@/api/tags';
import {
  ViewType,
  CONTENT_LIST_UPDATE_VIEW_TYPE,
} from '@/store/modules/contentList';
import { SET_ERROR_MESSAGE_FROM_API } from '@/store/modules/messages';
import { useContentSearch, defaultQuery } from '@/composables/contentSearch';
import { useContentFilter } from '@/composables/contentFilter';
import {
  contentTypeFilterOptions,
} from './contentTypes';
import ContentListOps from './ContentListOps.vue';
import ContentTable from './ContentTable.vue';
import ContentBlog from './ContentBlog.vue';
import ContentSearch from './ContentSearch.vue';
import EmptyResults from './EmptyResults.vue';
import RequestPermissionsModal from '@/views/content/RequestPermissionsModal';
import Paginator from '@/components/Paginator.vue';
import Dropdown from '@/components/Dropdown.vue';
import { docsPath } from '@/utils/paths';

const store = useStore();

const localState = reactive({
  searchResults: [],
  usersOptions: [],
  preSelectedUsers: [],
  contentTypeOptions: contentTypeFilterOptions(),
  preSelectedTypes: [],
  tagsOptionsTree: [],
  preSelectedTags: [],
  fetchedTags: false,
  total: 0,
  showEmptyResultsMsg: false,
  showPermissionsModal: false,
});
const serverSettings = computed(() => store.state.server.settings);
const contentListViewType = computed(() => {
  return store.state.contentList.viewType || serverSettings.value.defaultContentListView;
});
const isBlogView = computed(() => contentListViewType.value === ViewType.BLOG);
const isTableView = computed(() => contentListViewType.value === ViewType.TABLE);
const currentUser = computed(() => store.state.currentUser.user);
const canRequestPublisherAccess = computed(() => {
  return (
    currentUser.value.isViewer() &&
    !serverSettings.value.viewerKiosk
  );
});

const togglePermissionsModal = () => {
  localState.showPermissionsModal = !localState.showPermissionsModal;
};

const hideUsersFromViewers = computed(() => {
  return store.state.currentUser.user.isViewer() &&
    serverSettings.value.viewersCanOnlySeeThemselves;
});

// Keep the tags tree response, no need to re-request tags.
const staticTagsTree = [];
const updateViewType = viewType => {
  store.commit(CONTENT_LIST_UPDATE_VIEW_TYPE, viewType);
};

/**
 * Build search users payload.
 * - Only looking for users that can publish (owners).
 * - Do not include remote users, only local users can own content.
 * @param {String} prefix Search prefix
 * @returns {Object} Search users payload.
 */
const searchUsersPayload = (prefix = '') => ({
  prefix,
  userRole: {
    publisher: true,
    administrator: true,
  },
  includeRemote: false,
});

/**
 * Parse users to filters.
 * @param {Array} users Array of users
 * @returns {Array} Filter options to be used on filter dropdown.
 */
const usersToFilters = users => users.map(u => ({
  label: u.fullName,
  value: u.username,
  sub: u.username,
}));

/**
 * Lookup users with proper settings and the given prefix.
 * @param {String} prefix Search prefix
 * @returns {Promise} Users found
 */
const lookupUsers = async(prefix = '') => {
  const currentUserMatch = `${currentUser.value.fullName} ${currentUser.value.username}`.includes(prefix);
  try {
    const users = await searchUsers(
      serverSettings.value,
      searchUsersPayload(prefix),
    );

    // If there is a user filtering in place and current user does not match it
    // just return the provided results.
    if (prefix.length && !currentUserMatch) {
      return users.results;
    }

    // get the curent user, remove them from the list, and then add them back to the top of the list
    const usersOut = users.results.filter(usr => usr.username !== currentUser.value.username);
    usersOut.unshift(unref(currentUser));
    return usersOut;
  } catch (err) {
    store.commit(SET_ERROR_MESSAGE_FROM_API, err);
    return [];
  }
};

/**
 * Lookup for specific users via given usernames.
 * Meant to run when view loads and there are owners present in query,
 * or when there's the need to sync from query input.
 * Assigns results as filters to localState.preSelectedUsers.
 * @param {Array} usernames The already selected owner's usernames
 */
const syncSelectedOwners = async(usernames = []) => {
  if (!usernames.length) {
    // save up one wire travel when no usernames
    localState.preSelectedUsers = [];
    return;
  }

  try {
    const users = await lookupUsers(usernames.join(' '));
    const matchingUsernames = users.filter(usr => usernames.includes(usr.username));
    localState.preSelectedUsers = usersToFilters(matchingUsernames);
  } catch (err) {
    store.commit(SET_ERROR_MESSAGE_FROM_API, err);
  }
};

/**
 * Prepares the owners filters options. Meant to run when view loads and when search query changes.
 * Pulls existing selected owners to be filtered out from the available options.
 * Local state localState.usersOptions and localState.preSelectedUsers are ready after this promise resolves.
 * @param {Array} preselected The already selected owner's usernames
 */
const prepUsers = async(preselected = []) => {
  const atMeIndex = preselected.indexOf('@me');
  // If @me is present, add current user as a current selection for the owners dropdown
  if (atMeIndex > -1) {
    preselected.splice(atMeIndex, 1, currentUser.value.username);
  }
  const [userResults] = await Promise.all([
    lookupUsers(),
    syncSelectedOwners(preselected),
  ]);
  const filteredSelections = userResults.filter(usr => !preselected.includes(usr.username));
  localState.usersOptions = usersToFilters(filteredSelections);
};

/**
 * Prepares the content type options. Meant to run when view loads and when search query changes.
 * Uses the pre-selcted content types to be filtered out from the available options.
 * @param {Array} preselected The already selected content types
 */
const prepContentTypes = (preselected = []) => {
  const allFilters = contentTypeFilterOptions();
  localState.contentTypeOptions = allFilters;
  localState.preSelectedTypes = [];
  if (preselected.length) {
    for (let i = allFilters.length - 1; i >= 0; i--) {
      const filter = allFilters[i];
      if (preselected.includes(filter.value)) {
        localState.contentTypeOptions.splice(i, 1);
        localState.preSelectedTypes.unshift(filter);
      }
    }
  }
};

const buildTagOptions = (tree, parentPath = '') => {
  return tree.map(({ name: tagName, children }) => {
    const path = parentPath ? `${parentPath}/${tagName}` : tagName;
    const tagOption = {
      label: tagName,
      value: path,
    };
    if (children) {
      tagOption.children = buildTagOptions(children, path);
    }
    return tagOption;
  });
};

const prepTags = async(preselected = []) => {
  try {
    if (!localState.fetchedTags) {
      localState.fetchedTags = true;
      const tagsTreeResponse = await getTagsTree();
      staticTagsTree.push(...buildTagOptions(tagsTreeResponse));
    }
    localState.tagsOptionsTree = cloneDeep(staticTagsTree);
    localState.preSelectedTags = preselected.map(path => {
      return {
        label: path.split('/').pop(),
        value: path,
      };
    });
  } catch (err) {
    store.commit(SET_ERROR_MESSAGE_FROM_API, err);
  }
};

/**
 * Method to handle and update state with incoming search results
 * @param {Object} payload
 * @param {Number} payload.total
 * @param {Array} payload.results
 */
const onSearchResults = ({ total, results = [] } = {}) => {
  localState.searchResults = results;
  localState.total = total;
  localState.showEmptyResultsMsg = total === 0;
};

/**
 * Method to handle page change to page number n
 * @param {Number} n
 */
const onPageChange = n => {
  page.value = n;
  search();
};

/**
 * Method to reset paging
 */
const resetPage = () => {
  page.value = undefined;
};

const {
  query,
  lastQueryUsed,
  page,
  perPage,
  search,
  subscribeToRouteQueryChange,
} = useContentSearch({
  onResults: onSearchResults,
});

const {
  filterRef: ownerFilterRef,
  syncFrom: syncOwnerFrom,
  syncFilterToRef: syncOwnerTo,
} = useContentFilter('owner', []);

const {
  filterRef: typeFilterRef,
  syncFrom: syncTypeFrom,
  syncFilterToRef: syncTypeTo,
} = useContentFilter('type', []);

const {
  filterRef: tagsFilterRef,
  syncFrom: syncTagsFrom,
  syncFilterToRef: syncTagsTo,
} = useContentFilter('tag', []);

const hasCustomSearchQuery = computed(() =>
  !!lastQueryUsed.value.length && lastQueryUsed.value !== defaultQuery);

/**
 * Wrapper method for resetting pagination and then searching.
 * @return {Promise} Search promise
 */
const resetPageAndSearch = () => {
  resetPage();
  return search();
};

/**
 * We want to sync all data in filter dropdowns consistently
 * on search query input change (via typing or hitting clear).
 */
const syncFiltersWithQueryChange = () => {
  syncOwnerFrom(query.value);
  syncTypeFrom(query.value);
  syncTagsFrom(query.value);
  prepUsers([...ownerFilterRef.value]);
  prepContentTypes([...typeFilterRef.value]);
  prepTags([...tagsFilterRef.value]);
};
subscribeToRouteQueryChange(() => {
  syncFiltersWithQueryChange();
});

/**
 * Search method triggered by query input submit.
 * Updates filter values from the latest search query value.
 */
const searchViaQueryInput = async() => {
  try {
    await resetPageAndSearch();
    syncFiltersWithQueryChange();
  } catch (err) {
    store.commit(SET_ERROR_MESSAGE_FROM_API, err);
  }
};

/**
 * Method to update dropdown owner options. Owner options need to refresh and filter out current selections.
 * Either search prefix changed in dropdown, or something changed in selected owners.
 * @param {String} dropdownPrefix The current search prefix in use within the dropdown that changed.
 */
const updateOwnerOptions = async(dropdownPrefix = '') => {
  let users = await lookupUsers(dropdownPrefix);
  if (ownerFilterRef.value.length) {
    const atMeIsPresent = ownerFilterRef.value.includes('@me');
    users = users.filter(usr => {
      const shouldKeep = !ownerFilterRef.value.includes(usr.username);
      if (!atMeIsPresent) {
        return shouldKeep;
      }
      // If @me exists within owner filter ref,
      // current user already exists on pre-selected values... remove it.
      return shouldKeep && usr.username !== currentUser.value.username;
    });
  }
  localState.usersOptions = usersToFilters(users);
};

/**
 * Method to update dropdown content type options.
 * Either search prefix changed in dropdown, or something changed in selected content types.
 * @param {String} dropdownPrefix The current search prefix in use within the dropdown that changed.
 */
const updateTypesOptions = (dropdownPrefix = '') => {
  let opts = contentTypeFilterOptions();
  if (dropdownPrefix) {
    opts = opts
      .filter(opt => `${opt.label} ${opt.value}`.toLowerCase().includes(dropdownPrefix?.toLowerCase()))
      .map(opt => ({ ...opt, hidden: false }));
  }
  localState.contentTypeOptions = opts.filter(opt => !typeFilterRef.value.includes(opt.value));
};

/**
 * Traverse all branches of a given tree to find matches based on the provided matchFn.
 * @param {Array} tree The tree to traverse and find matches.
 * @param {Function} matchFn Function to be used to compare and define which elements should be included as a match.
 * Arguments include an object as { label, value }.
 * @param {Boolean} returnAsTree True by default. When disabled, matches found are returned as a one dimensional Array.
 * @returns {Array} Matches found while traversing the tree, results are nested as a reflection of the original tree by default.
 * Returned as a one dimensional array when returnAsTree is false.
 */
const traverseTree = (tree, matchFn, returnAsTree = true) => {
  const branchResults = [];
  tree.forEach(({ label, value, children }) => {
    let insertMatch = false;
    let matchingChildren = [];
    const branchEntry = { label, value };

    if (children && children.length) {
      matchingChildren = traverseTree(children, matchFn, returnAsTree);
    }

    if (matchingChildren.length) {
      if (returnAsTree) {
        branchEntry.children = matchingChildren;
        insertMatch = true;
      } else {
        branchResults.push(...matchingChildren);
      }
    }

    if (matchFn({ label, value })) {
      branchEntry.highlight = true;
      insertMatch = true;
    }

    if (insertMatch) {
      branchResults.push(branchEntry);
    }
  });
  return branchResults;
};

/**
 * Method to update dropdown tags tree.
 * Either search prefix changed in dropdown, or something changed in selected tags.
 * @param {String} dropdownPrefix The current search prefix in use within the dropdown that changed.
 */
const updateTagOptions = (dropdownPrefix = '') => {
  if (!dropdownPrefix) {
    localState.tagsOptionsTree = cloneDeep(staticTagsTree);
    return;
  }

  const resultsTree = traverseTree(
    staticTagsTree,
    ({ label }) => label.toLowerCase().includes(dropdownPrefix.toLowerCase()),
  );
  localState.tagsOptionsTree = resultsTree;
};

/**
 * Method to handle tags tree specifics.
 * Currently designed to update the tag values when a broad tag term have many tags selected at once
 * and one of them is then unchecked.
 *
 * @example
 * // A query with 'tag:blue'
 * query = 'tag:blue';
 *
 * // Shows all the following as selected matches.
 * // - colors/blue
 * // - apartment/room/blue
 * // - crayons/blue
 * // - students/teams/blue
 *
 * // When an event notifies about un-checking one of these.
 * updateRemainingTagChecks({ label: 'blue', value: 'crayons/blue' });
 *
 * // Query (and related models) are updated to exact matches of the remaining tags.
 * query === 'tag:colors/blue,department/room/blue,students/teams/blue'
 *
 * @param {Object} uncheckedOption The option being unchecked.
 */
const updateRemainingTagChecks = uncheckedOption => {
  const uncheckedLabel = uncheckedOption.label.toLowerCase();
  const uncheckedValue = uncheckedOption.value.toLowerCase();
  const remainingMatches = traverseTree(
    staticTagsTree,
    ({ label, value }) => {
      return (
        label.toLowerCase() === uncheckedLabel && // we want all matching options by label
        value.toLowerCase() !== uncheckedValue // but not the one that is currently being unchecked
      );
    },
    false,
  );

  // Update selected tags model - remove the current broad match value and insert the remaining matches
  tagsFilterRef.value = tagsFilterRef.value.filter(t => t.toLowerCase() !== uncheckedLabel);
  tagsFilterRef.value.push(...remainingMatches.map(m => m.value));

  // Update pre-selected tags for tags dropdown to update
  localState.preSelectedTags = tagsFilterRef.value.map(path => {
    return {
      label: path.split('/').pop(),
      value: path,
    };
  });

  // Finally update query and trigger search
  syncTagsTo(query);
  resetPageAndSearch();
};

const clearSearch = async() => {
  resetPage();
  query.value = '';
  syncFiltersWithQueryChange();
  search();
};

/**
 * At content type dropdown changes, sync the query input value.
 * Triggers search too with the updated values.
 */
const syncFromTypeDropdown = () => {
  resetPage();
  syncTypeTo(query);
  resetPageAndSearch();
};

/**
 * At owner dropdown changes, sync the query input value.
 * Triggers search too with the updated values.
 */
const syncFromOwnerDropdown = () => {
  syncOwnerTo(query);
  resetPageAndSearch();
};

/**
 * Tags dropdown changes, sync the query input value.
 * Triggers search too with the updated values.
 */
const syncFromTagsDropdown = () => {
  syncTagsTo(query);
  resetPageAndSearch();
};

onBeforeMount(() => {
  prepUsers([...ownerFilterRef.value]);
  prepContentTypes([...typeFilterRef.value]);
  prepTags([...tagsFilterRef.value]);
});
</script>

<template>
  <div
    data-automation="content-list"
    class="bandContent mainPage requiresAuth new-content-list"
  >
    <!-- eslint-disable vue/no-v-html -->
    <div
      v-if="serverSettings.loggedInWarning"
      class="rsc-alert warning"
      data-automation="logged-in-warning"
      v-html="serverSettings.loggedInWarning"
    />
    <!-- eslint-enable vue/no-v-html -->

    <section
      data-automation="new-content-list__header"
      class="new-content-list__header"
    >
      <h1
        class="pageTitle"
        data-automation="content-list-title"
        tabindex="-1"
      >
        Content
      </h1>

      <!-- Publishing & Permissions controls, wizards and modals -->
      <ContentListOps
        @reset-content="clearSearch(true)"
        @request-permissions="togglePermissionsModal"
      />
    </section>

    <form
      class="new-content-list__search"
      data-automation="content-search__form"
      @submit.prevent.stop="searchViaQueryInput"
    >
      <ContentSearch
        v-model="query"
        class="new-content-list__search-box"
        @submit="searchViaQueryInput"
        @clear="clearSearch"
      />

      <fieldset
        id="optionDropdowns"
        class="new-content-list__search-filters"
      >
        <label for="optionDropdowns">Filter options:</label>
        <Dropdown
          v-model="typeFilterRef"
          name="filter-types"
          label="Type"
          search-label="Find a type"
          data-automation="content-filter__content-type"
          :options="localState.contentTypeOptions"
          :pre-selected="localState.preSelectedTypes"
          :search="updateTypesOptions"
          @selection-change="updateTypesOptions"
          @update:model-value="syncFromTypeDropdown"
        />
        <Dropdown
          v-if="!hideUsersFromViewers"
          v-model="ownerFilterRef"
          name="filter-users"
          label="Owner"
          search-label="Find an owner"
          data-automation="content-filter__users"
          :options="localState.usersOptions"
          :pre-selected="localState.preSelectedUsers"
          :aliases="{ '@me': currentUser.username }"
          :search="updateOwnerOptions"
          @selection-change="updateOwnerOptions"
          @update:model-value="syncFromOwnerDropdown"
        />
        <Dropdown
          v-model="tagsFilterRef"
          name="filter-tags"
          label="Tag"
          search-label="Find a tag"
          data-automation="content-filter__tags"
          :preserve-tree="true"
          :options="localState.tagsOptionsTree"
          :pre-selected="localState.preSelectedTags"
          :search="updateTagOptions"
          @tree-check-specifics="updateRemainingTagChecks"
          @update:model-value="syncFromTagsDropdown"
        />
        <a
          :href="docsPath('user/viewing-content/#searching-and-filtering-content')"
          target="_blank"
          class="new-content-list__search-filters-help"
        >
          Advanced search and filters
        </a>
      </fieldset>
    </form>

    <section
      v-if="localState.total"
      class="new-content-list__view-pres"
      data-automation="new-content-list-presentation-btns"
    >
      <span>
        {{ localState.total }} results
      </span>
      <div
        class="new-content-list__view-pres-buttons"
      >
        <button
          title="Compact view"
          class="compact-view-btn"
          data-automation="switch-to-tableview"
          :class="{ current: isTableView }"
          :aria-pressed="isTableView ? 'true' : 'false'"
          @click="updateViewType(ViewType.TABLE)"
        />
        <button
          title="Expanded view"
          class="expanded-view-btn"
          data-automation="switch-to-blogview"
          :class="{ current: isBlogView }"
          :aria-pressed="isBlogView ? 'true' : 'false'"
          @click="updateViewType(ViewType.BLOG)"
        />
      </div>
    </section>

    <ContentTable
      v-if="isTableView"
      :applications="localState.searchResults"
    />
    <ContentBlog
      v-if="isBlogView"
      :applications="localState.searchResults"
    />
    <EmptyResults
      v-if="localState.showEmptyResultsMsg"
      :is-searching="hasCustomSearchQuery"
      @request-permissions="togglePermissionsModal"
    />
    <Paginator
      v-else
      :page="page || 1"
      :per-page="perPage"
      :total="localState.total"
      @change="onPageChange"
    />
    <RequestPermissionsModal
      v-if="canRequestPublisherAccess"
      privilege="publisher"
      :show-modal="localState.showPermissionsModal"
      @close="togglePermissionsModal"
    />
  </div>
</template>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';
@import 'Styles/shared/_variables';
@import 'Styles/shared/_mixins';

.new-content-list {
  min-height: 80vh;
  max-width: 1200px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.8rem;

    .pageTitle {
      margin-bottom: 0;
    }
  }

  &__search {
    display: flex;
    flex-direction: column;

    &-filters {
      color: $color-dark-grey;
      display: flex;
      align-items: center;
      padding: 0.6rem 0.4rem;

      & > * {
        display: block;
      }

      &-help {
        margin-left: auto;
        padding-right: 1.2rem;
        background-image: url('/images/external-link.svg');
        background-position: right center;
        background-size: 1.0rem;
        background-repeat: no-repeat;
      }

      & > :not(:last-child) {
        margin-right: 0.5rem;
      }
    }
  }

  &__view-pres {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.6rem 0.4rem;

    &-buttons {
      display: flex;

      & > * {
        margin-left: 0.5rem;
      }
    }

    button {
      background-color: $color-primary-light;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 1.6rem;
      padding: 0;
      line-height: 1.44rem;
      width: 1.44rem;
      height: 1.44rem;

      &.current {
        background-color: $color-posit-blue;
      }
    }

    .compact-view-btn {
      background-image: url('/images/table-view-icon-alt.svg');

      &.current {
        background-image: url('/images/table-view-icon.svg');
      }
    }

    .expanded-view-btn {
      background-image: url('/images/blog-view-icon-alt.svg');

      &.current {
        background-image: url('/images/blog-view-icon.svg');
      }
    }
  }
}
</style>

// Copyright (C) 2024 by Posit Software, PBC.

const contentTypes = [
  {
    label: 'API',
    value: 'apis',
    children: [
      {
        label: 'Plumber',
        value: 'api',
      },
      {
        label: 'Python ASGI',
        value: 'python-fastapi',
      },
      {
        label: 'Python WSGI',
        value: 'python-api',
      },
      {
        label: 'TensorFlow Model API',
        value: 'tensorflow-saved-model',
      },
    ],
  },
  {
    label: 'Application',
    value: 'app',
    children: [
      {
        label: 'Shiny for R',
        value: 'shiny',
      },
      {
        label: 'Shiny for Python',
        value: 'python-shiny',
      },
      {
        label: 'Dash',
        value: 'python-dash',
      },
      {
        label: 'Streamlit',
        value: 'python-streamlit',
      },
      {
        label: 'Bokeh',
        value: 'python-bokeh',
      },
    ],
  },
  {
    label: 'Jupyter',
    value: 'jupyter',
    children: [
      {
        label: 'Jupyter Notebook',
        value: 'jupyter-static',
      },
      {
        label: 'Voilà',
        value: 'jupyter-voila',
      },
    ]
  },
  {
    label: 'Quarto',
    value: 'quarto',
    children: [
      {
        label: 'Shiny Quarto',
        value: 'quarto-shiny',
      },
      {
        label: 'Static Quarto',
        value: 'quarto-static',
      },
    ]
  },
  {
    label: 'R Markdown',
    value: 'rmd',
    children: [
      {
        label: 'R Markdown (Shiny)',
        value: 'rmd-shiny',
      },
      {
        label: 'R Markdown (Static)',
        value: 'rmd-static',
      },
    ]
  },
  {
    label: 'Pin',
    value: 'pin',
  },
  {
    label: 'Other',
    value: 'static',
    children: [
      {
        label: 'Unknown',
        value: 'unknown',
      },
    ]
  },
];

function includesContentType(outer, contentType) {
  if (outer.value === contentType) {
    return true;
  }

  if (outer.children) {
    return outer.children.some(child => includesContentType(child, contentType));
  }

  return false;
}

export const contentTypeLabel = mode => {
  for (const outer of contentTypes) {
    if (includesContentType(outer, mode)) {
      return outer.label;
    }
  }

  return 'Unknown';
};

/**
 * Factory function to get content type filters.
 * @param {Array} types Content types to filter on (defaults to all)
 * @returns {Array} Array of content type filters as { label, value }
 */
export const contentTypeFilterOptions = (types = contentTypes, { hidden = false } = {}) => {
  const options = [];

  types.forEach(type => {
    options.push({
      label: type.label,
      value: type.value,
      sub: type.value,
      hidden,
    });

    if (type.children) {
      options.push(...contentTypeFilterOptions(type.children, { hidden: true }));
    }
  });

  return options.sort((a, b) => a.label.localeCompare(b.label));
};

export default contentTypes;

<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="wizard-panel wizard-panel__jupyter-intro wizard-panel--white">
    <div class="wizard-panel__content wizard-panel__content--no-title">
      <p>
        Python users can publish to {{ systemDisplayName }} using Jupyter Notebooks
        , a popular tool for literate programming. Rendered notebooks can
        be deployed to {{ systemDisplayName }} for easy distribution, or the source notebook
        and environment can be published making it easy to refresh,
        schedule, and distribute.
      </p>
    </div>
  </div>
</template>

<script>
import PanelMixin from '../PanelMixin';
export default {
  name: 'JupyterNotebookIntro',
  mixins: [PanelMixin],
};
</script>

<style lang="scss" scoped>
.wizard-panel {
  &__jupyter-intro {
    background-image: url('./background.png');
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 100% auto;
  }
}
</style>

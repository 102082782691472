<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div
    v-if="reportResults"
    class="rsc-serverstatus-report"
    data-automation="server-status-report-document"
    tabindex="0"
  >
    <h2 ref="top">
      Posit Connect Server Status Report for <code>{{ reportResults.run.hostname }}</code>
    </h2>
    {{
      new Intl.DateTimeFormat(undefined, {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZoneName: 'short' }).format(reportResults.run.startTime)
    }}
    <div>
      <div
        role="button"
        tabindex="0"
        class="back-to-top"
        @click="backToTop"
        @keyup.enter="backToTop"
        @keyup.space="backToTop"
      >
        <span
          role="img"
          aria-label="Back to Top"
        >
          ↑
        </span>
        <span class="result-link">
          Back to Top
        </span>
      </div>
      <h3>
        Summary
      </h3>
      <p class="passed">
        <span>✅</span> {{ reportResults.run.passed }} passed
      </p>
      <p class="failed">
        <span>⚠️</span> {{ warnings }}
      </p>
      <p
        v-if="report.status === 'canceled'"
        class="failed"
      >
        This report was canceled. Results may be incomplete.
      </p>
      <div
        v-for="(group, name, i) in groupedResults"
        :key="i"
      >
        <h4>
          <span
            class="result-link"
            :onclick="'document.getElementById(\'' + 'group' + i + '\').scrollIntoView()'"
          >
            {{ group.localizedName }}
            <span class="args">
              {{ group.args ? " (" + group.args + ")" : "" }}
            </span>
          </span>
        </h4>
        <ul>
          <li
            v-for="(result, j) in group.results"
            :key="j"
          >
            <span
              class="result-link"
              :class="result.passed ? 'passed' : 'failed'"
              :onclick="'document.getElementById(\'' + 'group' + i + 'test' + j + '\').scrollIntoView()'"
            >
              <span
                role="img"
                :aria-label="result.passed ? 'passed' : 'failed'"
              >{{ result.passed ? "✅ " : "⚠️ " }}</span> {{ result.test.localizedName }}
            </span>
          </li>
        </ul>
      </div>
    </div>
    <div>
      <!-- Begin groups iteration -->
      <div
        v-for="(group, name, i) in groupedResults"
        :key="i"
      >
        <div class="result-header">
          <h3
            :id="'group' + i"
          >
            Group: {{ group.localizedName }}
          </h3>
          <div class="args">
            {{ group.args ? " (" + group.args + ")" : "" }}
          </div>
        </div>
        <div
          v-for="(result, j) in group.results"
          :key="j"
        >
          <div class="result-header">
            <h4
              :id="'group' + i + 'test' + j"
            >
              Test: {{ result.test.localizedName }}
            </h4>
            <div class="args">
              {{ result.test.args ? " (" + result.test.args + ")" : "" }}
            </div>
            <div :class="result.passed ? 'passed' : 'failed'">
              {{ result.passed ? '✅ PASSED' : '⚠️ WARNING' }}
            </div>
          </div>
          <p class="result-description">
            {{ result.test.description }}
          </p>
          <p
            v-if="!result.passed"
            class="result-error-text"
          >
            {{ result.test.errorText }}
          </p>
          <div v-if="result.output">
            <h5>Output</h5>
            <pre tabindex="0">{{ result.output }}</pre>
          </div>
          <div v-if="result.error">
            <h5>Error</h5>
            <pre tabindex="0">{{ result.error }}</pre>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isAPIErrorMessage } from '@/api/error';
import { getSelfTestRunResults } from '@/api/selfTests';
import { SET_ERROR_MESSAGE_FROM_API } from '@/store/modules/messages';
import pluralize from '@/utils/pluralize';
import { localOffset } from '@/utils/timezone';
import { mapMutations } from 'vuex';
import { toCamelCase } from './utils';

export default {
  name: 'ServerStatusReportDocument',
  props: {
    report: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      reportResults: null,
      offset: localOffset(),
    };
  },
  computed: {
    groupedResults: function() {
      const groupNames = {
        baseSandbox: 'Process Sandbox',
        packratRestoreSandbox: 'R Environment Restore',
        pythonRestoreSandbox: 'Python Environment Restore',
        server: 'Connect Server',
        rmarkdownSandbox: 'RMarkdown Sandbox',
      };
      const testLabels = {
        connectVersions: {
          name: 'Posit Connect Versions',
          description: 'Prints the versions of Posit Connect on active hosts.',
          error: 'Some hosts are running inconsistent versions of Posit Connect. Please ensure that all hosts are running the same version.',
        },
        connectNotificationsConfig: {
          name: 'Posit Connect Notifications Settings',
          description: 'Checks if the Connect configuration contains valid Notification settings.',
          error: 'Your configuration contains Notification settings that may be invalid.',
        },
        connectDeprecatedSettings: {
          name: 'Posit Connect Deprecated Settings',
          description: 'Checks if the Connect configuration uses any deprecated settings.',
          error: 'Your configuration is using deprecated settings. Please update your configuration.',
        },
        connectLdapGroupsSources: {
          name: 'LDAP Group Source Consistency',
          description: 'Checks Posit Connect\'s LDAP groups for inconsistent sources.',
          error: 'Posit Connect is currently using a mixture of local and remote LDAP groups. Please see the Admin Guide\'s section on Group Management, and the appendix on the User Management CLI, for more information.',
        },
        connectLicense: {
          name: 'Posit Connect License',
          description: 'Checks the status of your Posit Connect license.',
          error: 'Your license may be expired or close to expiration.',
        },
        cranConnectivity: {
          name: 'CRAN Connectivity',
          description: 'Verifies that Posit Connect can reach CRAN.',
          error: 'Posit Connect could not reach CRAN. If this server has restricted internet access and uses a local R package repository, you can safely ignore this failure.',
        },
        databaseLatency: {
          name: 'Database Latency',
          description: 'Checks the latency of a simple database query.',
          error: 'Database requests look longer than expected.',
        },
        disk: {
          name: 'Disk',
          description: 'Checks for sufficient free disk space.',
          error: 'There was less than 10% free disk space in one of the following locations: `/`, `/tmp`, or the Posit Connect data directory.',
        },
        echo: {
          name: 'Echo',
          description: 'Runs a trivial command in Posit Connect\'s process sandbox.',
          error: 'Posit Connect was unable to run a trivial command in its process sandbox.',
        },
        environment: {
          name: 'Environment',
          description: 'Prints the environment variables that are visible on the Posit Connect server.',
          error: '',
        },
        installedPackages: {
          name: 'Installed R Packages',
          description: 'Lists the packages available to this version of R.',
          error: '',
        },
        linuxVersion: {
          name: 'Linux Version',
          description: 'Prints information about the server\'s Linux distribution.',
          error: '',
        },
        memory: {
          name: 'Memory',
          description: 'Checks for sufficient free memory.',
          error: 'Less than 20% of RAM or swap space was free when this test ran.',
        },
        mounts: {
          name: 'Mounts',
          description: 'Lists filesystem mounts that are configured in the sandbox.',
          error: '',
        },
        options: {
          name: 'Options',
          description: 'Prints this R installation\'s options. This includes any options set in R configuration files, such as .Rprofile.',
          error: '',
        },
        pip: {
          name: 'Pip',
          description: 'Creates a virtual environment, prints information about the resulting environment\'s pip configuration, and verifies that pip can download a package from the configured repository.',
          error: 'There was a problem verifying pip functionality in a virtual environment.',
        },
        pypiConnectivity: {
          name: 'PyPI Connectivity',
          description: 'Verifies that the default PyPI repositories are reachable from within Posit Connect\'s sandbox.',
          error: 'Posit Connect was unable to reach the default PyPI repositories. If this server has restricted internet access and uses a local Python package repository, you can safely ignore this failure.',
        },
        pythonPackages: {
          name: 'Installed Python Packages',
          description: 'Lists the packages available to this Python installation.',
          error: '',
        },
        pythonVersion: {
          name: 'Python Version',
          description: 'Prints the version number reported by this Python installation.',
          error: '',
        },
        queueDepth: {
          name: 'Queue',
          description: 'Checks the number of scheduled reports queued.',
          error: 'A large number of queued reports may indicate that jobs are not completing as expected.',
        },
        rPackageRepository: {
          name: 'R Package Repository',
          description: 'Verifies that R package repositories can be reached from within Posit Connect\'s sandbox.',
          error: 'There was a problem trying to reach this R package repository.',
        },
        rVersion: {
          name: 'R Version',
          description: 'Prints the version number reported by this R installation.',
          error: '',
        },
        selfConnectivity: {
          name: 'Self Connectivity',
          description: 'Checks whether Posit Connect can reach its configured server addresses.',
          error: 'There was a problem trying to reach this configured server address. This might be expected, depending on your network configuration and security policies.',
        },
        virtualenv: {
          name: 'Virtual Environment',
          description: 'Verifies that this Python installation can create a virtual environment in Posit Connect\'s sandbox.',
          error: 'Unable to create a virtual environment using this Python installation.',
        },
      };

      // This function should just get ALL the data into the format I want.
      const groupedResults = this.reportResults.results.reduce((groups, result) => {
        const testName = toCamelCase(result.test.name);
        const group = (groups[result.group.name + result.group.args] || {
          // Default empty group
          args: result.group.args,
          passed: 0,
          failed: 0,
          results: [],
          localizedName: groupNames[toCamelCase(result.group.name)],
        });

        if (result.passed === true) {
          group.passed++;
        } else {
          group.failed++;
        }

        // Gather strings
        result.test.localizedName = testLabels[testName].name;
        result.test.description = testLabels[testName].description;
        result.test.errorText = testLabels[testName].error;
        group.results.push(result);
        groups[result.group.name + result.group.args] = group;
        return groups;
      }, {});
      return groupedResults;
    },
    warnings() {
      return `${this.reportResults.run.failed} ${pluralize(this.reportResults.run.failed, 'warning', 'warnings')}`;
    }
  },
  watch: {
    report: function() {
      this.pollReportResults();
    }
  },
  created() {
    this.pollReportResults();
  },
  methods: {
    ...mapMutations({
      setErrorMessageFromAPI: SET_ERROR_MESSAGE_FROM_API,
    }),
    async pollReportResults() {
      if (!this.report) {
        return;
      }

      const previousReportResults = this.reportResults;
      try {
        this.reportResults = await getSelfTestRunResults(this.report.id);
        if (['requested', 'running'].includes(this.reportResults.run.status)) {
          setTimeout(this.pollReportResults, 3000);
        }
      } catch (error) {
        if (isAPIErrorMessage(error)) {
          this.setErrorMessageFromAPI(error);
          this.reportResults = previousReportResults;
        } else {
          throw error;
        }
      }
    },
    backToTop() {
      this.$refs.top.scrollIntoView();
    }
  },
};
</script>

<style lang="scss" scoped>
* {
  scroll-margin-top: 60px;
}

.rsc-serverstatus-report {
  margin-top: 20px;

  /* Here through "End replicated styles" should be present in
  makeReportDocumentHTML() and ServerStatusReportDocument.vue */
  ul {
    padding-left: 20px;
  }

  li {
    margin: 0px !important;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 1rem !important;
    margin-bottom: 0.5rem !important;
    font-weight: 500;
  }

  pre {
    line-height: 1.5rem;
  }

  .passed {
    color: hsl(115, 73%, 31%);
    font-weight: 700;
  }

  .failed {
    color: hsl(40, 79%, 34%);
    font-weight: 700;
  }

  .result-header {
    display: flex;
    align-items: baseline;
    justify-content: start;
    gap: 1rem;
    margin-top: 20px;
  }

  .result-link {
    cursor: pointer;
  }

  .result-link:hover {
      text-decoration: underline;
  }

  .result-error-text {
    font-weight: 700;
  }

  .back-to-top {
    position: fixed;
    right: 32px;
    bottom: 20px;
    background: white;
    border-radius:13px;
    height: 26px;
    line-height: 26px;
    display: inline-block;
    text-align: center;
    padding-right: 8px;
  }

  .back-to-top:hover {
    cursor: pointer;
  }

  .back-to-top [role=img] {
    font-weight: 700;
    background:#333333;
    color: white;
    border-radius:50%;
    height: 26px;
    width: 26px;
    line-height: 26px;
    display: inline-block;
    text-align: center;
    margin-right: 0px;
  }

  .args {
    font-size: 0.9rem;
    color: #555;
  }
  /* End replicated styles */

  &__input {
    margin-bottom: 0.9rem;
  }

  &__section {
    margin-bottom: 3rem;
  }

    &-header {
      margin-bottom: 0.6rem;
      padding: 0.6rem 0;
      align-items: flex-end;
      justify-content: space-between;
      display: flex;
    }
}
</style>

// Copyright (C) 2023 by Posit Software, PBC.

import {
  createVariant,
  deleteRun,
  deleteVariant,
  getParameterControls,
  getVariantParameters,
  getVariant,
  getVariants,
  updateVariant,
  renderVariantValues,
  setVariantOverrides
} from '@/api/parameterization';
import { LOAD_RENDERINGS_HISTORY } from '@/store/modules/contentView';
import { LOGS_OVERLAY_RESET_VIEW } from '@/store/modules/logsOverlay';
import { SHOW_ERROR_MESSAGE } from '@/store/modules/messages';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import { refreshReport } from '../../api/parameterization';
import { taskToPromise } from '../../api/tasks';

export const PARAMETERIZATION_ADD_VARIANT = 'PARAMETERIZATION_ADD_VARIANT';
export const PARAMETERIZATION_CREATE_VARIANT = 'PARAMETERIZATION_CREATE_VARIANT';
export const PARAMETERIZATION_CONFIRM_SELECT_VARIANT = 'PARAMETERIZATION_CONFIRM_SELECT_VARIANT';
export const PARAMETERIZATION_CURRENT_RUN = 'PARAMETERIZATION_CURRENT_RUN';
export const PARAMETERIZATION_CURRENT_VARIANT = 'PARAMETERIZATION_CURRENT_VARIANT';
export const PARAMETERIZATION_DELETE_RUN = 'PARAMETERIZATION_DELETE_RUN';
export const PARAMETERIZATION_DELETE_VARIANT = 'PARAMETERIZATION_DELETE_VARIANT';
export const PARAMETERIZATION_RESET_VARIANTS = 'PARAMETERIZATION_RESET_VARIANTS';
export const PARAMETERIZATION_FETCH_PARAMETERS = 'PARAMETERIZATION_FETCH_PARAMETERS';
export const PARAMETERIZATION_FETCH_VALUES = 'PARAMETERIZATION_FETCH_VALUES';
export const PARAMETERIZATION_FETCH_VARIANT = 'PARAMETERIZATION_FETCH_VARIANT';
export const PARAMETERIZATION_FETCH_VARIANTS = 'PARAMETERIZATION_FETCH_VARIANTS';
export const PARAMETERIZATION_POLL_REPORT = 'PARAMETERIZATION_POLL_REPORT';
export const PARAMETERIZATION_REFRESH_REPORT = 'PARAMETERIZATION_REFRESH_REPORT';
export const PARAMETERIZATION_RENAME_VARIANT = 'PARAMETERIZATION_RENAME_VARIANT';
export const PARAMETERIZATION_REVERT_VALUES = 'PARAMETERIZATION_REVERT_VALUES';
export const PARAMETERIZATION_RUN_REPORT = 'PARAMETERIZATION_RUN_REPORT';
export const PARAMETERIZATION_SAVE_AS = 'PARAMETERIZATION_SAVE_AS';
export const PARAMETERIZATION_SAVE_VARIANT_VALUES = 'PARAMETERIZATION_SAVE_VARIANT_VALUES';
export const PARAMETERIZATION_SELECT_RUN = 'PARAMETERIZATION_SELECT_RUN';
export const PARAMETERIZATION_SELECT_VARIANT = 'PARAMETERIZATION_SELECT_VARIANT';
export const PARAMETERIZATION_SET_APP = 'PARAMETERIZATION_SET_APP';
export const PARAMETERIZATION_SET_DIRTY = 'PARAMETERIZATION_SET_DIRTY';
export const PARAMETERIZATION_SET_BUSY = 'PARAMETERIZATION_SET_BUSY';
export const PARAMETERIZATION_SET_INITIAL_VARIANT = 'PARAMETERIZATION_SET_INITIAL_VARIANT';
export const PARAMETERIZATION_SET_ORIGINAL_VALUES = 'PARAMETERIZATION_SET_ORIGINAL_VALUES';
export const PARAMETERIZATION_SET_PARAMETERS = 'PARAMETERIZATION_SET_PARAMETERS';
export const PARAMETERIZATION_SET_VARIANTS = 'PARAMETERIZATION_SET_VARIANTS';
export const PARAMETERIZATION_SET_VALUES = 'PARAMETERIZATION_SET_VALUES';
export const PARAMETERIZATION_SET_VALUE = 'PARAMETERIZATION_SET_VALUE';
export const PARAMETERIZATION_SELECT_DEFAULT_VARIANT = 'PARAMETERIZATION_SELECT_DEFAULT_VARIANT';
export const PARAMETERIZATION_UPDATE_VARIANT = 'PARAMETERIZATION_UPDATE_VARIANT';

const defaultState = {
  app: null,
  currentVariant: {},
  currentRun: {},
  error: null,
  initialVariantId: null,
  isBusy: false,
  isRunDirty: false,
  isVariantDirty: false,
  originalValues: {},
  parameters: [],
  pendingSelectVariant: null,
  runs: [],
  values: {},
  variants: [],
};

const findDefaultVariant = variants => (
  variants.find(variant => variant.isDefault)
);

export default {
  state: defaultState,
  mutations: {
    [PARAMETERIZATION_SET_APP]: (state, app) => {
      state.app = app;
    },
    [PARAMETERIZATION_SET_BUSY]: (state, isBusy) => {
      state.isBusy = isBusy;
    },
    [PARAMETERIZATION_ADD_VARIANT]: (state, newVariant) => {
      state.variants = [...state.variants, newVariant];
      state.isVariantDirty = false;
    },
    [PARAMETERIZATION_RENAME_VARIANT]: (state, newName) => {
      state.currentVariant.name = newName;
    },
    [PARAMETERIZATION_SET_INITIAL_VARIANT]: (state, id) => {
      state.initialVariantId = Number(id);
    },
    [PARAMETERIZATION_SET_VARIANTS]: (state, payload) => {
      // If no variants pulled, keep state as is
      if (isEmpty(payload)) {
        return;
      }
      state.variants = payload;
      if (isEmpty(state.currentVariant) && state.initialVariantId) {
        if (state.initialVariantId) {
          state.currentVariant =
            state.variants.find(variant => variant.id === state.initialVariantId);
          state.initialVariantId = null;
        }
      }

      if (isEmpty(state.currentVariant)) {
        state.currentVariant = findDefaultVariant(state.variants);
      }
    },
    [PARAMETERIZATION_RESET_VARIANTS]: state => {
      state.variants = [];
      state.currentVariant = {};
    },
    [PARAMETERIZATION_CURRENT_VARIANT]: (state, id) => {
      state.currentVariant = state.variants.find(variant => variant.id === id);
      state.isVariantDirty = false;
      state.isRunDirty = false;
    },
    [PARAMETERIZATION_CURRENT_RUN]: (state, id) => {
      state.currentRun = state.runs.find(r => r.id === id);
    },
    [PARAMETERIZATION_DELETE_RUN]: (state, targetId) => {
      const targetRunPos = state.runs.findIndex(r => r.id === targetId);
      state.runs = state.runs.filter(r => r.id !== targetId);

      if (isEmpty(state.runs)) {
        state.currentRun = {};
        state.values = cloneDeep(state.originalValues);
        return;
      }

      state.currentRun = state.runs[Math.min(targetRunPos, state.runs.length - 1)];
      state.values = cloneDeep(state.currentRun.values);
    },
    [PARAMETERIZATION_UPDATE_VARIANT]: (state, variant) => {
      state.variants = state.variants.map(v => (
        v.id === variant.id ? variant : v
      ));
      if (state.currentVariant.id === variant.id) {
        state.currentVariant = variant;
      }
    },
    [PARAMETERIZATION_REVERT_VALUES]: state => {
      state.values = cloneDeep(state.originalValues);
      state.isVariantDirty = false;
      state.isRunDirty = false;
    },
    [PARAMETERIZATION_SET_DIRTY]: (state, isDirty) => {
      state.isVariantDirty = isDirty;
      state.isRunDirty = isDirty;
    },
    [PARAMETERIZATION_SET_PARAMETERS]: (state, parameters) => {
      state.parameters = parameters;
    },
    [PARAMETERIZATION_SET_VALUES]: (state, values) => {
      state.values = cloneDeep(values);
    },
    [PARAMETERIZATION_SET_ORIGINAL_VALUES]: (state, values) => {
      state.originalValues = cloneDeep(values);
    },
    [PARAMETERIZATION_SET_VALUE]: (state, { parameter, value }) => {
      state.values[parameter] = value;
      state.isVariantDirty = true;
      state.isRunDirty = true;
    },
    [PARAMETERIZATION_RUN_REPORT]: (state, completedRun) => {
      const newRun = cloneDeep(completedRun);
      state.runs = [...state.runs, newRun];
      state.currentRun = newRun;
      state.isRunDirty = false;
    },
  },
  actions: {
    [PARAMETERIZATION_CREATE_VARIANT]: ({ commit, dispatch }, { fromVariantId, newName }) => (
      createVariant(fromVariantId, newName)
        .then(variant => {
          commit(PARAMETERIZATION_ADD_VARIANT, variant);
          dispatch(PARAMETERIZATION_FETCH_VARIANTS, variant.appId);
          commit(PARAMETERIZATION_CURRENT_VARIANT, variant.id);

          const newVariantHref = window.location.href.replace(/\/\d+$/, `/${variant.id}`);
          window.location.replace(newVariantHref);

          // TODO: remove this stubbed code when using valid API request.
          return setVariantOverrides(variant.id).then(variantData => console.debug('ALL DONE!', variantData));
        })
    ),
    [PARAMETERIZATION_FETCH_VARIANT]: ({ commit }, variantId) => {
      return getVariant(variantId)
        .then(variant => {
          commit(PARAMETERIZATION_UPDATE_VARIANT, variant);
          return variant;
        });
    },
    [PARAMETERIZATION_FETCH_VARIANTS]: ({ commit, dispatch, rootState }, appId) => {
      return getVariants(appId)
        .then(variants => {
          commit(PARAMETERIZATION_SET_VARIANTS, variants);
          // Now that we are using some of thos Vuex module actions in production code
          // We don't want to fetch values if new params is not enabled
          if (rootState.server.settings.newParameterizationEnabled) {
            dispatch(PARAMETERIZATION_FETCH_VALUES);
          }
          return variants;
        });
    },
    [PARAMETERIZATION_FETCH_PARAMETERS]: ({ commit, state }) => {
      return getParameterControls(state.app.guid)
        .then(parameters => {
          commit(PARAMETERIZATION_SET_PARAMETERS, parameters);
          return parameters;
        });
    },
    [PARAMETERIZATION_FETCH_VALUES]: ({ commit, state }) => {
      return getVariantParameters(state.app.guid, state.currentVariant.id)
        .then(values => {
          commit(PARAMETERIZATION_SET_VALUES, values);
          commit(PARAMETERIZATION_SET_ORIGINAL_VALUES, values);
          return values;
        })
        .catch(err => {
          // TODO: replace with error handling when valid API request is available.
          console.debug('Could Not Fetch Variant Values:', err);
        });
    },
    [PARAMETERIZATION_POLL_REPORT]: async({ commit, dispatch }, { taskId, variantId }) => {
      commit(PARAMETERIZATION_SET_BUSY, true);

      const onPoll = data => {
        if (!data.finished) {
          return data;
        }

        if (data.code === 0) {
          // report is ready
          dispatch(PARAMETERIZATION_FETCH_VARIANT, variantId)
            .then(() => commit(PARAMETERIZATION_SET_BUSY, false));
          return data;
        } else if (data.error) {
          // report failed
          dispatch(SHOW_ERROR_MESSAGE, { message: data.error });
          commit(PARAMETERIZATION_SET_BUSY, false);
          return data;
        }
        dispatch(SHOW_ERROR_MESSAGE, { message: data.error });
        commit(PARAMETERIZATION_SET_BUSY, false);
        return data;
      };

      await taskToPromise(taskId, onPoll);
    },
    [PARAMETERIZATION_REFRESH_REPORT]: ({ commit, dispatch, state: { currentVariant } }) => {
      commit(PARAMETERIZATION_SET_BUSY, true);
      return refreshReport(currentVariant.id)
        .then(job => {
          // start polling until the report is ready
          return dispatch(
            PARAMETERIZATION_POLL_REPORT,
            { taskId: job.id, variantId: currentVariant.id }
          );
        })
        .then(() => {
          // refresh report logs
          return dispatch(LOGS_OVERLAY_RESET_VIEW);
        })
        .catch(err => {
          dispatch(SHOW_ERROR_MESSAGE, { message: err });
        });
    },
    [PARAMETERIZATION_RENAME_VARIANT]: ({ state, commit }, newName) => {
      const renamedVariant = { ...state.currentVariant, name: newName };
      updateVariant(renamedVariant);
      commit(PARAMETERIZATION_RENAME_VARIANT, newName);
    },
    [PARAMETERIZATION_SELECT_VARIANT]: ({ commit, dispatch, rootState }, id) => {
      commit(PARAMETERIZATION_SET_BUSY, true);
      commit(PARAMETERIZATION_CURRENT_VARIANT, id);
      // Now that we are using some of thos Vuex module actions in production code
      // We don't want to fetch values if new params is not enabled
      if (rootState.server.settings.newParameterizationEnabled) {
        dispatch(PARAMETERIZATION_FETCH_VALUES);
      }
      dispatch(LOAD_RENDERINGS_HISTORY, id)
        .finally(() => {
          commit(PARAMETERIZATION_SET_BUSY, false);
        });
    },
    [PARAMETERIZATION_SELECT_DEFAULT_VARIANT]: ({ state, dispatch }) => {
      const defaultVrnt = findDefaultVariant(state.variants);
      dispatch(PARAMETERIZATION_SELECT_VARIANT, defaultVrnt.id);
    },
    [PARAMETERIZATION_SELECT_RUN]: ({ commit }, { id, values }) => {
      commit(PARAMETERIZATION_CURRENT_RUN, id);
      commit(PARAMETERIZATION_SET_VALUES, values);
    },
    [PARAMETERIZATION_RUN_REPORT]: ({ commit, dispatch }, { id, values }) => {
      commit(PARAMETERIZATION_SET_BUSY, true);
      return renderVariantValues(id, values)
        .then(newRun => {
          commit(PARAMETERIZATION_RUN_REPORT, newRun);
        })
        .catch(err => {
          commit(PARAMETERIZATION_SET_BUSY, false);
          dispatch(SHOW_ERROR_MESSAGE, { message: err });
          return err;
        });
    },
    [PARAMETERIZATION_SAVE_VARIANT_VALUES]: (_commit, { values }) => {
      // TODO: replace this with valid API request
      console.debug('SAVE_VARIANT_VALUES>>>>', values);
    },
    [PARAMETERIZATION_SAVE_AS]: (_commit, { values }) => {
      // TODO: replace this with valid API request
      console.debug('SAVE_AS>>>>', values);
    },
    [PARAMETERIZATION_DELETE_VARIANT]: async({ dispatch, state }, { appId, variantId }) => {
      const defaultVariant = findDefaultVariant(state.variants);
      if (defaultVariant.id === variantId) {
        console.error('ERROR: cannot delete default variant');
        return;
      }

      await deleteVariant(variantId);
      await dispatch(PARAMETERIZATION_FETCH_VARIANTS, appId);
      dispatch(PARAMETERIZATION_SELECT_DEFAULT_VARIANT);
    },
    [PARAMETERIZATION_DELETE_RUN]: ({ commit }, targetId) => {
      return deleteRun(targetId)
        .then(() => {
          commit(PARAMETERIZATION_DELETE_RUN, targetId);
          commit(PARAMETERIZATION_SET_BUSY, true);
        })
        .catch(() => {
          commit(PARAMETERIZATION_SET_BUSY, false);
        })
        .finally(() => {
          commit(PARAMETERIZATION_SET_BUSY, false);
        });
    },
  }
};

<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="wizard-panel wizard-panel__plumber-intro wizard-panel--blue">
    <div class="wizard-panel__content wizard-panel__content--no-title">
      <p>
        Deploy a model for other systems to use in production via a Plumber
        API. Plumber and {{ systemDisplayName }} make it easy to create and deploy RESTful
        Web APIs using R.
      </p>
      <p>
        Let's walk through how to publish one.
      </p>
    </div>
  </div>
</template>

<script>
import PanelMixin from '../PanelMixin';

export default {
  name: 'PlumberAPIIntro',
  mixins: [PanelMixin],
};
</script>

<style lang="scss" scoped>
.wizard-panel {
  &__plumber-intro {
    background-image: url('./background.png');
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 100% auto;
  }
}
</style>

<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div
    :class="parentErrorClass"
    class="rs-horizontal rsc-metrics__timeframe"
  >
    <label
      class="rs-field"
      for="metrics-units"
    >
      Show history for
    </label>
    <RSInputNumber
      v-model.number="unit"
      label="Unit"
      :show-label="false"
      :small="true"
      data-automation="metrics-unit"
      name="metrics-unit"
      min="1"
      @change="changed"
    />
    <RSInputSelect
      v-model="range"
      label="Range"
      :show-label="false"
      :options="rangeOptions"
      data-automation="metrics-range"
      name="metrics-range"
      @change="changed"
    />
    <div
      v-show="v$.$invalid"
      class="rs-field__error"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import RSInputNumber from '@/elements/RSInputNumber';
import RSInputSelect from '@/elements/RSInputSelect';
import { debounce } from '@/utils/debounce';
import { useVuelidate } from '@vuelidate/core';
import * as Validators from '@vuelidate/validators';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(isSameOrAfter);

// We have a valid start measurement and want to compare to the start of
// 1980. rrdtool only supports dates after 1980-01-01.
//
// parameters:
//   range: time interval, e.g., 'hours', 'days', 'weeks', 'months', 'years'
//   unit:  count of `range` intervals
const isAfter1980 = ({ range, unit }) => (
  dayjs()
    .subtract(unit, range)
    .isSameOrAfter(dayjs('1980-01-01T00:00:00.000Z'))
);

export default {
  name: 'TimeframeSelector',
  components: { RSInputNumber, RSInputSelect },
  emits: ['timeChange'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      // unit and range defaults must match defaults in the consumer component
      unit: 1,
      range: 'days',
      rangeOptions: [
        { label: 'Hours', value: 'hours' },
        { label: 'Days', value: 'days' },
        { label: 'Weeks', value: 'weeks' },
        { label: 'Months', value: 'months' },
        { label: 'Years', value: 'years' },
      ],
    };
  },
  computed: {
    errorClass() {
      return this.v$.$invalid ? 'error' : '';
    },
    parentErrorClass() {
      return this.v$.$invalid ? 'rsc-metrics__timeframe--error' : '';
    },
    errorMessage() {
      if (this.v$.$errors[0]?.$validator === 'tooEarly') {
        return 'Specify a time period that does not extend beyond 1980';
      } else if (this.v$.$errors[0]?.$validator === 'required') {
        return 'A time period must be specified';
      } else if (this.v$.$errors[0]?.$validator === 'minimum') {
        return 'A time period greater than 0 must be specified';
      } return null;
    }
  },
  methods: {
    changed: debounce(300, function() {
      this.actualChanged();
    }),
    actualChanged() {
      this.v$.$touch();

      if (this.v$.$invalid) {
        return;
      }
      this.$emit('timeChange', { unit: this.unit, range: this.range });
    },
  },
  validations: {
    unit: {
      required: Validators.required,
      minimum: Validators.minValue(1),
      tooEarly: (value, vm) => isAfter1980({ unit: value, range: vm.range }),
    },
  },
};
</script>

<style scoped lang="scss">
@import 'Styles/shared/_colors';

.rsc-metrics {
  &__timeframe {
    input {
      width: 5rem;
      height: 32px;
    }

    select {
      font-size: 0.85rem;
      padding: 0.25rem 0.4rem;
      font-family: 'Lato', sans-serif;
      font-weight: normal;
    }

    &--error {
      .rs-input {
        border-color: $color-error;
      }
    }

    .rs-field:not(:last-child) {
      margin: 0 0.3rem 0 0;
    }
  }
}
</style>

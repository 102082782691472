<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="optionsPositioner">
    <div class="band">
      <div class="innards bandContent">
        <!-- eslint-disable vue/no-unused-refs -->
        <div
          ref="optionsPanel"
          :style="style"
          class="optionsPanel"
        >
          <!-- eslint-enable vue/no-unused-refs -->
          <div class="rsc-options-panel__header">
            <h2 class="rsc-options-panel__header-title">
              Options
            </h2>
            <button
              aria-label="Hide options panel"
              class="paneCloser"
              @click.prevent="$emit('close')"
            />
          </div>

          <div class="rsc-options-panel__input-group">
            <RSInputSearch
              ref="searchInput"
              v-model.trim="prefix"
              :show-label="false"
              label="Search for users"
              placeholder="Search for a user..."
              :small="true"
              :show-clear="Boolean(prefix)"
              name="uop-search"
              data-automation="uop-search-input"
              icon="search"
              @change="onPrefixChange"
              @clear="clearPrefix"
            />

            <div class="rs-field">
              <p v-if="canSearchById">
                Use the Search field to search users by username, name, or
                by their <strong>exact</strong> remote identity (UniqueID).
              </p>
              <p v-else>
                Use the Search field to search users by username or name.
              </p>
            </div>

            <div class="rs-field">
              <RSCheckboxGroup
                v-if="isAdmin"
                title="STATUS"
                :options="accountStatusOptions"
                help="Status"
                name="uop-status"
                data-automation="uop-status"
                @change="onChange"
              />
            </div>

            <div class="rs-field">
              <RSCheckboxGroup
                title="ROLE"
                :options="userRoleOptions"
                name="uop-role"
                data-automation="uop-role"
                @change="onChange"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RSCheckboxGroup from '@/elements/RSCheckboxGroup';
import RSInputSearch from '@/elements/RSInputSearch';

import OptionsPanel from '@/components/OptionsPanel';
import { debounce } from '@/utils/debounce';

export default {
  name: 'UsersOptionPanel',
  components: { RSCheckboxGroup, RSInputSearch },
  extends: OptionsPanel,
  props: {
    isAdmin: {
      type: Boolean,
      required: true,
    },
    canSearchById: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['change', 'close'],
  data() {
    return {
      prefix: '',
      accountStatusOptions: [
        {
          id: 'licensed',
          label: 'Licensed',
          checked: false,
        },
        {
          id: 'locked',
          label: 'Locked',
          checked: false,
        },
        {
          id: 'inactive',
          label: 'Inactive',
          checked: false,
        },
      ],
      userRoleOptions: [
        {
          id: 'administrator',
          label: 'Administrators',
          checked: false,
        },
        {
          id: 'publisher',
          label: 'Publishers',
          checked: false,
        },
        {
          id: 'viewer',
          label: 'Viewers',
          checked: false,
        },
      ],
    };
  },
  computed: {
    searchDescription() {
      return this.canSearchById ? 'users.options.info.searchWithUniqueID' : 'users.options.info.search';
    },
  },
  methods: {
    focus() {
      this.$nextTick().then(() => this.$refs.searchInput.focusElement());
    },
    onPrefixChange: debounce(300, function() {
      this.onChange();
    }),
    clearPrefix() {
      this.prefix = '';
      this.onChange();
    },
    onChange() {
      this.$emit('change', {
        prefix: this.prefix,
        accountStatus: {
          licensed: this.accountStatusOptions[0].checked,
          locked: this.accountStatusOptions[1].checked,
          inactive: this.accountStatusOptions[2].checked,
        },
        userRole: {
          administrator: this.userRoleOptions[0].checked,
          publisher: this.userRoleOptions[1].checked,
          viewer: this.userRoleOptions[2].checked,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import 'Styles/shared/_mixins';

.optionsPositioner {
  position: fixed;
  z-index: 10;
  left: 0px;
  width: 100%;
  height: 0px;

  @include transition-property(left, right);
  @include normal-transition-duration();
}
</style>

<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="band">
    <div class="bandContent mainPage">
      <div class="rs-authbox">
        <header class="rs-authbox__header">
          <div
            class="rs-authbox__title"
            data-automation="set-password-choose-password"
          >
            Choose a new password
          </div>
        </header>
        <form class="rs-authbox__form">
          <RSInputPassword
            ref="password1"
            v-model="form.password"
            label="New password"
            :disabled="!canSetPassword"
            :message="errorMessage('password')"
            data-automation="set-password-1"
            name="password"
            message-type="error"
            @change="formChangeHandler"
          >
            <template #help>
              <ul>
                <li>
                  Don't base it on the names associated with this user.
                </li>
                <li>
                  Don't base it on common English names.
                </li>
                <li>
                  Don't use easy-to-guess passwords such as "password".
                </li>
                <li>
                  Don't use dates or sequential numbers.
                </li>
                <li>
                  Letters or keystrokes on a keyboard are considered weak.
                </li>
                <li>
                  Character variety (upper/lower case, digits or symbols) do not guarantee password strength.
                </li>
                <li>
                  "l33t speak" doesn't necessarily make a password harder to guess.
                </li>
              </ul>
            </template>
          </RSInputPassword>
          <RSInputPassword
            v-model="form.repeatNewPassword"
            label="New password (again)"
            :disabled="!canSetPassword"
            :message="errorMessage('repeatNewPassword')"
            data-automation="set-password-2"
            name="repeatNewPassword"
            message-type="error"
            @change="formChangeHandler"
          />
          <RSButton
            ref="button"
            :data-automation="button.dataAutomation"
            :label="button.label"
            :disabled="!token"
            type="primary"
            @click.prevent="button.handler"
          />
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { setPassword } from '@/api/authentication';
import RSButton from '@/elements/RSButton';
import RSInputPassword from '@/elements/RSInputPassword';
import { CURRENT_USER_LOAD } from '@/store/modules/currentUser';
import {
  CLEAR_STATUS_MESSAGE,
  SET_ERROR_MESSAGE_FROM_API,
  SHOW_ERROR_MESSAGE,
  SHOW_INFO_MESSAGE,
} from '@/store/modules/messages';
import { getHashQueryParameter } from '@/utils/paths';
import * as Validators from '@/utils/validators';
import { useVuelidate } from '@vuelidate/core';
import { mapActions, mapMutations, mapState } from 'vuex';
import { ValidationMessages } from '../../utils/validation';

export default {
  name: 'SetPasswordView',
  components: { RSButton, RSInputPassword },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      isPasswordSet: false,
      hasApiError: false,
      form: {
        password: '',
        repeatNewPassword: '',
      },
    };
  },
  validations() {
    return this.formValidations();
  },
  computed: {
    token() {
      const [token] = getHashQueryParameter('resettoken') || [];
      return token;
    },
    canSetPassword() {
      return this.token && !this.isPasswordSet;
    },
    button() {
      const submitButton = {
        label: 'Set Password',
        handler: this.setNewPassword,
        dataAutomation: 'set-password-submit',
      };
      const navigateButton = {
        label: `Proceed to ${this.systemDisplayName}`,
        handler: this.redirect,
        dataAutomation: 'set-password-goto-connect',
      };
      return this.isPasswordSet ? navigateButton : submitButton;
    },
    ...mapState({
      systemDisplayName: state => state.server.settings.systemDisplayName,
    }),
  },
  mounted() {
    this.clearStatusMessage();
    if (!this.token) {
      this.setErrorMessage({
        message: 'Missing token in reset password link. Please try using the provided link again.',
      });
    }
    this.$refs.password1.focusElement();
  },
  methods: {
    ...mapMutations({
      clearStatusMessage: CLEAR_STATUS_MESSAGE,
      setErrorMessageFromAPI: SET_ERROR_MESSAGE_FROM_API,
    }),
    ...mapActions({
      reloadCurrentUser: CURRENT_USER_LOAD,
      setInfoMessage: SHOW_INFO_MESSAGE,
      setErrorMessage: SHOW_ERROR_MESSAGE,
    }),
    errorMessage(field) {
      const fieldValidators = this.v$.form[field];

      if (!fieldValidators.$error) {
        // no error
        return null;
      }

      // return the first failed validator
      const errorKey = fieldValidators.$errors[0].$validator;
      return ValidationMessages[field][errorKey];
    },
    formChangeHandler() {
      if (this.hasApiError) {
        this.clearStatusMessage();
        this.hasApiError = false;
      }
    },
    setNewPassword() {
      // trigger validation before API submit and bail if form is not valid
      this.v$.form.$touch();
      if (this.v$.form.$invalid) {
        return Promise.resolve();
      }
      this.clearStatusMessage();

      return setPassword(this.token, this.form.password)
        .then(() => {
          this.isPasswordSet = true;
          this.setInfoMessage({
            message: 'Your new password has been successfully saved.',
            autoHide: false,
          });
          this.$refs.button.focusElement();
        })
        .catch(e => {
          this.hasApiError = true;
          this.setErrorMessageFromAPI(e);
        });
    },
    async redirect() {
      await this.reloadCurrentUser();
      this.$router.push({ name: 'root' });
    },
    formValidations() {
      return {
        form: {
          password: Validators.password(),
          repeatNewPassword: Validators.repeatPassword(this.form.password),
        },
      };
    },
  },
};
</script>

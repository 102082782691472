<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="wizard-panel wizard-panel__rmarkdown-intro wizard-panel--blue">
    <div class="wizard-panel__content wizard-panel__content--no-title">
      <p>
        <span>
          R Markdown is the underpinning for persuasive, dynamic, data-driven
          documents. Combine text, tables and visualizations in a seamless
          story. Schedule updates, and email the results automatically to
          your colleagues.
        </span>
      </p>
      <p>
        <span>
          Let's walk through how to publish one.
        </span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RMarkdownIntro',
};
</script>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';

.wizard-panel {
  &__rmarkdown-intro {
    background-image: url('./background.png');
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 100% auto;
  }
  &__content, &__content * {
    background-color: transparent;
    span {
      background-color: $color-primary-dark;
      display: inline;
    }
  }

}
</style>

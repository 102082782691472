// Copyright (C) 2023 by Posit Software, PBC.

import DownloadExample from './panels/DownloadExample';
import OpenExample from './panels/OpenExample';
import OpenIDE from './panels/OpenIDE';
import PublishRConsole from './panels/PublishRConsole';
import QuartoOutro from './panels/QuartoOutro';

export default {
  bare: [],
  copyFrom: [
    DownloadExample,
    OpenIDE,
    OpenExample,
    PublishRConsole,
    QuartoOutro,
  ],
};

// Copyright (C) 2024 by Posit Software, PBC.

import {
  createTag,
  createTagVersion,
  deleteTag,
  getTagsTree,
} from '@/api/tags';

/**
 * Tags State network activity process options
 */
export const networkProcess = {
  SYNC: 'sync',
  FETCH: 'fetch',
  CREATE: 'create',
  DELETE: 'delete',
  UPDATE: 'update',
};

export const UPDATE_TAGS_TREE = 'UPDATE_TAGS_TREE';
export const INSERT_NEW_CATEGORY = 'INSERT_NEW_CATEGORY';
export const SET_DELETE_REQUEST = 'SET_DELETE_REQUEST';
export const FOCUS_NEW_GROUP = 'FOCUS_NEW_GROUP';
export const RESET_FOCUS_FLAG = 'RESET_FOCUS_FLAG';
export const RESET_DELETE_REQUEST = 'RESET_DELETE_REQUEST';
export const UPDATE_REQUEST_STATUS = 'UPDATE_REQUEST_STATUS';
export const RESET_REQUEST_STATUS = 'RESET_REQUEST_STATUS';

export const FETCH_TAGS_TREE = 'FETCH_TAGS_TREE';
export const CREATE_NEW_TAG = 'CREATE_NEW_TAG';
export const UPDATE_TAG = 'UPDATE_TAG';
export const DELETE_TAG = 'DELETE_TAG';
export const SET_NETWORK_ACTIVITY = 'SET_NETWORK_ACTIVITY';
export const RESET_NETWORK_ACTIVITY = 'RESET_NETWORK_ACTIVITY';

export const defaultState = () => ({
  tagsTree: [],
  deletingTag: {
    name: '',
    payload: {},
  },
  currentRequest: {
    active: false,
    message: '',
    timeout: null,
  },
  focusOnNewGroup: false,
});

export default {
  state: defaultState(),
  mutations: {
    [UPDATE_TAGS_TREE](state, tree) {
      state.tagsTree = tree;
    },
    [INSERT_NEW_CATEGORY](state, { discardFocus, newCategory }) {
      state.focusOnNewGroup = !discardFocus;
      state.tagsTree.push(newCategory);
    },
    [UPDATE_REQUEST_STATUS](state, { message, active }) {
      state.currentRequest.message = message;
      state.currentRequest.active = active;
    },
    [RESET_REQUEST_STATUS](state) {
      state.currentRequest.message = '';
      state.currentRequest.active = false;
    },
    [FOCUS_NEW_GROUP](state) {
      state.focusOnNewGroup = true;
    },
    [RESET_FOCUS_FLAG](state) {
      state.focusOnNewGroup = false;
    },
    [SET_DELETE_REQUEST](state, payload) {
      state.deletingTag.name = payload.name || '';
      state.deletingTag.payload = payload;
    },
    [RESET_DELETE_REQUEST](state) {
      state.deletingTag.name = '';
      state.deletingTag.payload = {};
    },
  },
  actions: {
    [FETCH_TAGS_TREE]({ state, commit }) {
      const activity = state.tagsTree.length
        ? networkProcess.SYNC
        : networkProcess.FETCH;
      const messages = {
        [networkProcess.SYNC]: 'Syncing tags...',
        [networkProcess.FETCH]: 'Loading tags...',
      };
      const activityTimeout = setTimeout(() => {
        commit(UPDATE_REQUEST_STATUS, {
          message: messages[activity],
          active: true,
        });
      }, 300);
      return getTagsTree()
        .then(tree => {
          commit(UPDATE_TAGS_TREE, tree);
        })
        .finally(() => {
          commit(UPDATE_REQUEST_STATUS, {
            message: '',
            active: false,
          });
          clearTimeout(activityTimeout);
        });
    },
    [CREATE_NEW_TAG](
      { commit, dispatch },
      { name, parentTag = null, discardFocus = false }, // eslint-disable-line no-shadow
    ) {
      if (!name) {
        return Promise.resolve();
      }
      const reqPayload = { name };
      if (parentTag) {
        reqPayload.parentId = parentTag.id;
      }

      const activityTimeout = setTimeout(() => {
        commit(UPDATE_REQUEST_STATUS, {
          message: 'Creating tag...',
          active: true,
        });
      }, 300);

      return createTag(reqPayload)
        .then(newTag => {
          const newTagNormalized = { ...newTag, children: [] };
          if (!parentTag) {
            // New category, set flag to focus on add children input
            commit(INSERT_NEW_CATEGORY, { discardFocus, newCategory: newTagNormalized });
          } else {
            if (!parentTag.children.length) {
              // Created new group?, set flag to focus on add children input
              commit(FOCUS_NEW_GROUP);
            }
            return dispatch(FETCH_TAGS_TREE);
          }
        })
        .finally(() => {
          commit(RESET_REQUEST_STATUS);
          clearTimeout(activityTimeout);
        });
    },
    [UPDATE_TAG]({ commit, dispatch }, { name, currentTag }) { // eslint-disable-line no-shadow
      // Only save when there are changes in the tag's name
      if (name === currentTag.name) {
        return Promise.resolve();
      }
      const activityTimeout = setTimeout(() => {
        commit(UPDATE_REQUEST_STATUS, {
          message: 'Updating tag...',
          active: true,
        });
      }, 300);
      return createTagVersion({
        name,
        id: currentTag.id,
        version: currentTag.version,
      })
        .then(() => {
          dispatch(FETCH_TAGS_TREE);
        })
        .finally(() => {
          commit(RESET_REQUEST_STATUS);
          clearTimeout(activityTimeout);
        });
    },
    [DELETE_TAG]({ state, commit, dispatch }) {
      const { id, version } = state.deletingTag.payload;
      const activityTimeout = setTimeout(() => {
        commit(UPDATE_REQUEST_STATUS, {
          message: 'Deleting tag...',
          active: true,
        });
      }, 300);
      return deleteTag({ id, version })
        .then(() => {
          dispatch(FETCH_TAGS_TREE);
          commit(RESET_DELETE_REQUEST);
        })
        .finally(() => {
          commit(RESET_REQUEST_STATUS);
          clearTimeout(activityTimeout);
        });
    },
  },
};

<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="wizard-panel wizard-panel__run-example wizard-panel--white">
    <div class="wizard-panel__content">
      <StepHeading :step="step">
        Run the Example
      </StepHeading>

      <p
        class="wizard-panel__run-example-description"
      >
        In the IDE, open
        <code>{{ source }}</code>
        and run the example by pressing the
        <span class="emphasize">{{ buttonAction }}</span>
        button in the Source pane header.
      </p>
      <img
        :src="imageSrc"
        alt="Screenshot of RStudio IDE Source pane header."
        class="wizard-panel__run-example-image"
      >
      <p class="wizard-panel__notice">
        <span class="emphasize">
          Note:
        </span>
        The RStudio IDE will prompt you to install any required packages
        — this may take a few minutes.
      </p>
    </div>
  </div>
</template>

<script>
import {
  JUPYTER_NOTEBOOK,
  JUPYTER_VOILA,
  PIN,
  PLOT,
  PLUMBER_API,
  PYTHON_API,
  PYTHON_BOKEH,
  PYTHON_DASH,
  PYTHON_FASTAPI,
  PYTHON_SHINY,
  PYTHON_STREAMLIT,
  PYTHON_VETIVER,
  QUARTO,
  QUARTO_PYTHON,
  QUARTO_PYTHON_SCRIPT,
  QUARTO_R,
  QUARTO_R_SCRIPT,
  QUARTO_WEBSITE,
  RMARKDOWN,
  RMARKDOWN_PARAMS,
  R_VETIVER,
  SHINY_APP,
  SHINY_RMARKDOWN,
  TENSOR_FLOW,
} from '@/constants/contentTypes';
import StepHeading from '@/views/content/PublishWizard/StepHeading';
import { mapState } from 'vuex';
import PanelMixin from '../PanelMixin';

const RUN_ACTIONS = {
  [JUPYTER_NOTEBOOK]: 'TODO',
  [JUPYTER_VOILA]: null,
  [PIN]: 'Knit',
  [PLOT]: 'TODO',
  [PLUMBER_API]: 'Run API',
  [RMARKDOWN]: 'Knit',
  [RMARKDOWN_PARAMS]: 'Knit',
  [SHINY_APP]: 'Run App',
  [SHINY_RMARKDOWN]: 'Run Document',
  [TENSOR_FLOW]: null,
  [R_VETIVER]: 'Run Document',
  [PYTHON_API]: null,
  [PYTHON_FASTAPI]: null,
  [PYTHON_SHINY]: null,
  [PYTHON_DASH]: null,
  [PYTHON_STREAMLIT]: null,
  [PYTHON_BOKEH]: null,
  [PYTHON_VETIVER]: 'Run',
  [QUARTO]: 'Render',
  [QUARTO_R]: 'Render',
  [QUARTO_R_SCRIPT]: 'Render',
  [QUARTO_PYTHON]: 'Render',
  [QUARTO_PYTHON_SCRIPT]: 'Render',
  [QUARTO_WEBSITE]: 'Render',
};

export default {
  name: 'RunExample',
  components: { StepHeading },
  mixins: [PanelMixin],
  computed: {
    ...mapState({
      example: state => state.publish.copyFrom,
    }),
    buttonAction() {
      return RUN_ACTIONS[this.example.type];
    },
    imageSrc() {
      return `images/jumpstart/screenshot-${this.example.type}.en.png`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';

.wizard-panel {
  &__run-example {
    &-description {
      margin-bottom: 0.5rem;
    }

    &-image {
      width: 100%;
    }
  }
}
</style>

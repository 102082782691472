<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<!--
  TabSelector presents a set of tabs that a user can select from. The selected tab is highlighted.

  The tabs are defined with an `id` and a `label`:

  const tabs = [
  { id: 'modified', label: 'Modified' },
  { id: 'all', label: 'All' },
  ];

  <TabSelector
  name="server-settings"
  :tabs="tabs"
  :active-tab-id="activeTabId"
  @update:activeTabId="setActiveTab"
  />

  When a tab is selected, `update:activeTabId` is emitted with the `id` of the selected tab.
-->

<script setup>
const props = defineProps({
  activeTabId: { type: String, required: true },
  name: { type: String, required: true },
  tabs: { type: Array, required: true },
});

const emit = defineEmits(['update:activeTabId']);
</script>

<template>
  <div
    class="tab-selector"
    data-automation="tab-selector"
  >
    <label
      v-for="tab in tabs"
      :key="tab.id"
      :for="tab.id"
      class="tab"
      :class="{ active: tab.id === props.activeTabId }"
    >
      <input
        :id="tab.id"
        :name="props.name"
        type="radio"
        :value="tab.id"
        :checked="tab.id === props.activeTabId"
        @change="emit('update:activeTabId', $event.target.value)"
      >
      {{ tab.label }}
    </label>
  </div>
</template>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';
@import 'Styles/shared/_mixins';

.tab-selector {
  display: flex;

  &:has(:focus-visible) {
    @include control-focus(25px);
  }
}

.tab {
  align-content: center;
  background-color: $color-white;
  border: 1px solid $color-rounded-button-border;
  color: $color-button-text;
  height: 1.7rem;
  min-width: 5rem;
  padding: 0 1rem;
  text-align: center;

  input[type=radio] {
    appearance: none;
    outline: none;
    position: absolute;
    opacity: 0;
  }

  &.active {
    background-color: $color-posit-blue;
    color: $color-white;

  }
  &:first-of-type {
    border-radius: 25px 0 0 25px;
    border-right: none;
  }
  &:last-of-type {
    border-left: none;
    border-radius: 0 25px 25px 0;
  }
}
</style>

<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="wizard-panel wizard-panel__plumber-file wizard-panel--white">
    <div class="wizard-panel__content">
      <StepHeading :step="step">
        Open a Plumber API File
      </StepHeading>
      <p>
        If you already have a Plumber API file, open it. If not, go ahead
        and make one.
      </p>
      <p>
        <a
          href="https://www.rplumber.io/docs/"
          target="_blank"
        >
          Learn more about Plumber
        </a>
      </p>
    </div>
  </div>
</template>

<script>
import StepHeading from '@/views/content/PublishWizard/StepHeading';
import PanelMixin from '../PanelMixin';

export default {
  name: 'PlumberAPIFile',
  components: { StepHeading },
  mixins: [PanelMixin],
};
</script>

<style lang="scss" scoped>
.wizard-panel {
  &__plumber-file {
    background-image: url('./background.png');
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: 100% auto;
  }
}
</style>

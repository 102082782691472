<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div>
    <div
      class="tagEditor majorColumn"
      data-automation="admin-tag-viewer"
    >
      <!-- Delete confirmation modal -->
      <RSModal
        v-if="isDeletingTag"
        :active="true"
        :subject="deleteTagLabel"
        :close-button-label="`No, do not delete tag ${deletingTagName}`"
        @close="cancelDelete"
      >
        <template #content>
          {{ `Are you sure that you want to delete ${deletingTagName}? You can't undo this action.` }}
        </template>
        <template #controls>
          <RSButton
            :label="`Yes, delete tag ${deletingTagName}`"
            :tabindex="isDeletingTag ? 0 : -1"
            type="primary"
            data-automation="tag-confirm-delete"
            @click="confirmDelete"
          />
        </template>
      </RSModal>

      <div class="flex">
        <div class="actionBar">
          <h1
            ref="title"
            class="sectionTitle focusedTitle"
            tabindex="-1"
          >
            Tags Schema
          </h1>
          <ToggleButton
            v-show="showHelpIcon"
            :is-on="showHelp"
            toggle-class="toggleInfo"
            @toggled="toggleShowHelp"
          />
        </div>
        <div class="tagEditor actionBar inline showTitles">
          <BaseButton
            v-if="showHelpIcon"
            label="New Category"
            :disabled="isProcessing"
            button-class="action newCategory"
            data-automation="tags-create-category-btn"
            @clicked="showNewCategoryForm"
          />
          <RSButton
            v-else
            label="Create Your Schema"
            :disabled="isProcessing"
            type="primary"
            data-automation="tags-start-schema-btn"
            @click="showNewCategoryForm"
          />
        </div>
      </div>

      <EmbeddedStatusMessage
        v-show="isProcessing"
        :show-close="false"
        :message="processingMessage"
        type="activity"
      />

      <TagsHelpInfo
        v-show="showHelp"
        :example-categories="exampleCategories"
      />

      <TagsGettingStarted
        v-if="showGettingStarted"
      />

      <div
        class="tag-categories-container"
      >
        <div
          class="tag-categories-container__tree"
        >
          <TagsCategory
            v-for="category in tagsTree"
            :key="'category-' + category.id"
            :category="category"
          />
          <div
            v-show="isAddingCategory"
            class="tag-categories-container__form"
          >
            <input
              ref="newCategoryInput"
              v-model.trim="newCategoryName"
              placeholder="New Category"
              aria-label="New Category"
              class="tag-categories-container__form-input"
              type="text"
              data-automation="tags-new-category-input"
              @keypress.enter="submitNewCategory"
              @blur="submitCategoryCheck($event)"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/BaseButton';
import EmbeddedStatusMessage from '@/components/EmbeddedStatusMessage';
import ToggleButton from '@/components/ToggleButton';
import RSButton from '@/elements/RSButton';
import RSModal from '@/elements/RSModal';
import {
  CREATE_NEW_TAG,
  DELETE_TAG,
  FETCH_TAGS_TREE,
  RESET_DELETE_REQUEST,
} from '@/store/modules/adminTags';
import { SET_ERROR_MESSAGE_FROM_API } from '@/store/modules/messages';
import { mapActions, mapMutations, mapState } from 'vuex';
import TagsCategory from './tags/TagsCategory';
import TagsGettingStarted from './tags/TagsGettingStarted';
import TagsHelpInfo from './tags/TagsHelpInfo';

export default {
  name: 'AdminTagsView',
  components: {
    BaseButton,
    RSButton,
    RSModal,
    TagsGettingStarted,
    TagsHelpInfo,
    TagsCategory,
    EmbeddedStatusMessage,
    ToggleButton,
  },
  data() {
    return {
      isAddingCategory: false,
      newCategoryName: '',
      showHelp: false,
      lockSaveCategory: false,
    };
  },
  computed: {
    ...mapState({
      tagsTree: state => state.adminTags.tagsTree,
      deletingTag: state => state.adminTags.deletingTag,
      currentRequest: state => state.adminTags.currentRequest,
    }),
    deleteTagLabel() {
      return `Delete tag "${this.deletingTagName}"`;
    },
    deletingTagName() {
      return this.deletingTag.name;
    },
    isDeletingTag() {
      return Boolean(this.deletingTag.name);
    },
    hasTags() {
      return Boolean(this.tagsTree.length);
    },
    showGettingStarted() {
      return !this.isProcessing && !this.hasTags && !this.isAddingCategory;
    },
    showHelpIcon() {
      return this.hasTags || this.isAddingCategory;
    },
    isProcessing() {
      return this.currentRequest.active;
    },
    processingMessage() {
      return this.currentRequest.message;
    },
    exampleCategories() {
      return this.tagsTree.slice(0, 2).map(c => c.name);
    },
  },
  watch: {
    hasTags() {
      if (!this.hasTags) {
        this.showHelp = false;
      }
    },
  },
  created() {
    this.init();
  },
  mounted() {
    this.$refs.title.focus();
  },
  methods: {
    ...mapMutations({
      cancelDelete: RESET_DELETE_REQUEST,
      setErrorMessageFromAPI: SET_ERROR_MESSAGE_FROM_API,
    }),
    ...mapActions({
      fetchTree: FETCH_TAGS_TREE,
      createTag: CREATE_NEW_TAG,
      confirmDelete: DELETE_TAG,
    }),
    init() {
      return this.fetchTree()
        .catch(this.setErrorMessageFromAPI);
    },
    showNewCategoryForm() {
      this.isAddingCategory = true;
      // Wait for input to display before trying to focus on it
      this.$nextTick().then(() => {
        this.$refs.newCategoryInput.focus();
      });
    },
    submitCategoryCheck(ev) {
      if (this.newCategoryName !== '') {
        this.submitNewCategory(ev, true);
      }
    },
    submitNewCategory(ev, onBlur = false) {
      // Lock when a submission is already set, when @keypress resolution triggers @blur
      if (this.lockSaveCategory) {
        return;
      }
      this.lockSaveCategory = true;
      return this.createTag({ name: this.newCategoryName, discardFocus: onBlur })
        .then(() => {
          this.newCategoryName = '';
        })
        .catch(this.setErrorMessageFromAPI)
        .finally(() => {
          this.isAddingCategory = false;
          this.lockSaveCategory = false;
        });
    },
    toggleShowHelp() {
      this.showHelp = !this.showHelp;
    },
  },
};
</script>

<style lang="scss" scoped>
.actionBar {
  display: flex;
  align-items: center;
  .toggleInfo {
    margin: 0 0 7px 4px;
  }
}
</style>

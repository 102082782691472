<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="rsc-content-list__header">
    <div class="rsc-content-list__header-top-row">
      <h1
        ref="pageTitle"
        class="pageTitle"
        data-automation="content-list-title"
        tabindex="-1"
      >
        {{ title }}
      </h1>
      <div class="rsc-content-list__header-controls">
        <div class="rsc-content-list__header-view-toggle actionBar showTitles">
          <button
            v-show="hasContent"
            title="Expanded View"
            :class="{ current: isBlogView }"
            :aria-pressed="isBlogView ? 'true' : 'false'"
            class="action blogView"
            data-automation="switch-to-blogview"
            @click="showBlogView"
          />
          <button
            v-show="hasContent"
            title="Compact View"
            :class="{ current: isTableView }"
            :aria-pressed="isTableView ? 'true' : 'false'"
            class="action radio tableView"
            data-automation="switch-to-tableview"
            @click="showTableView"
          />
          <PublishDropdown
            v-if="canPublish"
            ref="publishDropdown"
            :git-enabled="serverSettings.gitEnabled"
            :git-available="serverSettings.gitAvailable"
            :jump-start-enabled="serverSettings.jumpStartEnabled"
          />
          <a
            v-if="canRequestPublisherAccess"
            href="#"
            class="rs-link"
            data-automation="request-publisher-access"
            @click.prevent="toggleModal"
            @keydown.space.prevent="toggleModal"
            @keydown.enter.prevent="toggleModal"
          >
            Publish
          </a>
          <button
            v-show="!contentList.isOptionsVisible"
            ref="showOptionsButton"
            title="Show Options Panel"
            class="action toggleOptions"
            @click="toggleOptions"
          >
            <span class="actionTitle">
              Options
            </span>
          </button>
        </div>
      </div>
    </div>
    <!-- categories[]<{id, name, tags[]<id, name, path>}> -->
    <TagsSelectedFilters
      :tags="tagsPaths"
      @deselect-all="deselectAllTags"
      @deselect="deselectTag"
    />
    <!-- eslint-disable vue/no-v-html -->
    <div
      v-if="hasNotice"
      class="rsc-alert warning"
      data-automation="logged-in-warning"
      v-html="serverSettings.loggedInWarning"
    />
    <!-- eslint-enable vue/no-v-html -->
    <DeploymentWizard
      v-if="canPublishGit"
      :git-available="serverSettings.gitAvailable"
      @refresh-content-list="refreshContentList"
    />
    <PublishWizard v-if="canPublish && isPublishingActive && !isJumpstartActive" />
    <JumpStart v-if="canPublish && serverSettings.jumpStartEnabled && isJumpstartActive" />
    <RequestPermissionsModal
      v-if="canRequestPublisherAccess"
      privilege="publisher"
      :show-modal="showModal"
      @close="toggleModal"
    />
  </div>
</template>

<script>
import AppRoles from '@/api/dto/appRole';
import UserRoles from '@/api/dto/userRole';
import TagsSelectedFilters from '@/components/TagsSelectedFilters';
import {
  CONTENT_LIST_RESET_FILTERS,
  CONTENT_LIST_SET_DESELECT_TAG,
  CONTENT_LIST_TOGGLE_OPTIONS,
  CONTENT_LIST_UPDATE_FILTER,
  CONTENT_LIST_UPDATE_START_PAGE,
  CONTENT_LIST_UPDATE_VIEW_TYPE,
  FilterType,
  ViewType,
} from '@/store/modules/contentList';
import { JUMP_START_INIT } from '@/store/modules/jumpstart';
import { contentTypes } from '@/utils/contentList';
import DeploymentWizard from '@/views/content/DeploymentWizard';
import JumpStart from '@/views/content/JumpStart';
import PublishDropdown from '@/views/content/PublishDropdown';
import PublishWizard from '@/views/content/PublishWizard';
import RequestPermissionsModal from '@/views/content/RequestPermissionsModal';
import { mapActions, mapMutations, mapState } from 'vuex';
import { removeFilterTag } from './contentListUtils';
import ContentTypes from './contentType';

const titleOptions = [
  {
    label: ({ contentType }) => `Your matching ${contentType}`,
    isEditor: true,
    hasSelectedTags: true,
    hasSearch: false,
  },
  {
    label: ({ search, contentType }) => `Results for "${search}" in your matching ${contentType}`,
    isEditor: true,
    hasSelectedTags: true,
    hasSearch: true,
  },
  {
    label: ({ contentType }) => `Your ${contentType}`,
    isEditor: true,
    hasSelectedTags: false,
    hasSearch: false,
  },
  {
    label: ({ search, contentType }) => `Results for "${search}" in your ${contentType}`,
    isEditor: true,
    hasSelectedTags: false,
    hasSearch: true,
  },
  {
    label: ({ contentType }) => `Matching ${contentType}`,
    isEditor: false,
    hasSelectedTags: true,
    hasSearch: false,
  },
  {
    label: ({ search, contentType }) => `Results for "${search}" in matching ${contentType}`,
    isEditor: false,
    hasSelectedTags: true,
    hasSearch: true,
  },
  {
    label: ({ contentType }) => contentType,
    isEditor: false,
    hasSelectedTags: false,
    hasSearch: false,
  },
  {
    label: ({ search, contentType }) => `Results for "${search}" in ${contentType}`,
    isEditor: false,
    hasSelectedTags: false,
    hasSearch: true,
  },
];

export default {
  name: 'ContentListHeader',
  components: {
    TagsSelectedFilters,
    PublishDropdown,
    DeploymentWizard,
    PublishWizard,
    JumpStart,
    RequestPermissionsModal,
  },
  props: {
    userRole: {
      type: Number,
      required: true,
    },
    hasContent: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['viewChange', 'refresh'],
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    ...mapState({
      contentList: state => state.contentList,
      isJumpstartActive: state => state.jumpstart.active,
      isOptionsVisible: state => state.contentList.isOptionsVisible,
      isPublishingActive: state => state.publish.active,
      isDeployWizardActive: state => state.deployWizard.active,
      serverSettings: state => state.server.settings,
    }),
    isTableView() {
      return this.contentList.viewType === ViewType.TABLE;
    },
    isBlogView() {
      return this.contentList.viewType === ViewType.BLOG;
    },
    canPublish() {
      return this.userRole >= UserRoles.Publisher;
    },
    canRequestPublisherAccess() {
      return (
        this.userRole === UserRoles.Viewer &&
        !this.serverSettings.viewerKiosk
      );
    },
    hasNotice() {
      return this.serverSettings.loggedInWarning;
    },
    canPublishGit() {
      return this.canPublish && this.serverSettings.gitEnabled;
    },
    tagsPaths() {
      const tags = this.contentList.filters.tags;
      const categoryIds = Object.keys(tags);
      return categoryIds.length
        ? categoryIds.map(id => ({
          id,
          name: tags[id].categoryName,
          tags: tags[id].paths.map(path => ({
            ...path,
            name: path.label,
          })),
        }))
        : [];
    },
    title() {
      const {
        visibility,
        tags,
        search,
        contentType,
      } = this.contentList.filters;
      const title = titleOptions.find(
        ({ isEditor, hasSelectedTags, hasSearch }) => (
          isEditor === AppRoles.isEditor(visibility) &&
          hasSelectedTags === Boolean(Object.keys(tags).length) &&
          hasSearch === Boolean(search)
        )
      );

      const contentTypeLabel = ContentTypes.isAll(contentType)
        ? 'Content'
        : contentTypes[contentType];
      const params = { contentType: contentTypeLabel, search: search.trim() };
      return title.label(params);
    },
  },
  watch: {
    isJumpstartActive(isActive) {
      if (!isActive) { this.focusPublishDropwdown(); }
    },
    isDeployWizardActive(isActive) {
      if (!isActive) { this.focusPublishDropwdown(); }
    },
    isOptionsVisible(isVisible) {
      if (!isVisible) {
        this.$nextTick().then(() => this.$refs.showOptionsButton.focus());
      }
    },
    isPublishingActive(isActive) {
      if (!isActive) { this.focusPublishDropwdown(); }
    },
  },
  mounted() {
    this.focusPageTitle();
    if (this.serverSettings.jumpStartEnabled) {
      this.initializeJumpStart();
    }
  },
  methods: {
    ...mapMutations({
      updateViewType: CONTENT_LIST_UPDATE_VIEW_TYPE,
      toggleOptions: CONTENT_LIST_TOGGLE_OPTIONS,
      resetFilters: CONTENT_LIST_RESET_FILTERS,
      updateStartPage: CONTENT_LIST_UPDATE_START_PAGE,
      updateFilter: CONTENT_LIST_UPDATE_FILTER,
      setDeselectTag: CONTENT_LIST_SET_DESELECT_TAG,
    }),
    ...mapActions({
      initializeJumpStart: JUMP_START_INIT,
    }),
    focusPageTitle() {
      this.$nextTick().then(() => this.$refs.pageTitle.focus());
    },
    focusPublishDropwdown() {
      this.$refs.publishDropdown.focusButton();
    },
    refreshContentList() {
      // clear any filters set so the newly published content shows at the top
      // and reset back to the first page
      this.resetFilters();
      this.updateStartPage(0);
      this.$emit('refresh');
    },
    showBlogView() {
      this.updateViewType(ViewType.BLOG);
      this.$emit('viewChange');
    },
    showTableView() {
      this.updateViewType(ViewType.TABLE);
      this.$emit('viewChange');
    },
    deselectAllTags() {
      this.updateFilter({ type: FilterType.TAGS, value: {} });
      this.updateStartPage(0);
      this.$emit('refresh');
    },
    deselectTag({ categoryId, tagId }) {
      const tags = JSON.parse(JSON.stringify(this.contentList.filters.tags));
      removeFilterTag({ tags, categoryId, tagId });
      this.setDeselectTag({ tagId, categoryId: Number(categoryId) });
      this.updateFilter({ type: FilterType.TAGS, value: tags });
      this.updateStartPage(0); // reset back to the first page
      this.$emit('refresh');
    },
    toggleModal() {
      this.showModal = !this.showModal;
    },
  },
};
</script>

<style lang="scss">
@import 'Styles/shared/_mixins';

.pageTitle {
  @include control-visible-focus;
  border-radius: 3px;
  padding: .2rem .3rem ;
  margin-bottom: .5rem;
}
</style>

<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div
    :class="componentClass"
    role="button"
    tabindex="-1"
    @click.stop="handleSelection(true)"
    @keypress.stop="handleSelection(true)"
  >
    <div
      :class="labelClass"
    >
      <div class="tag-record__label-icon" />
      <input
        v-model.trim="value"
        :class="inputClass"
        data-automation="tag-name"
        aria-label="Tag Name"
        @focus="handleSelection(false)"
        @blur="handleBlur($event, submitUpdateIntent)"
        @keypress.enter="submitUpdateIntent"
      >
      <span
        v-if="!isEditing"
        role="button"
        tabindex="-1"
        class="tag-record__label-text"
        @click="focusOnEditAttempt"
        @keypress.stop="focusOnEditAttempt"
      >
        {{ label }}
      </span>
    </div>
    <div
      v-show="isAddingSubtag"
      class="tag-record__extend"
    >
      <AddItemInput
        ref="subtagInput"
        v-model="newSubtagName"
        :placeholder="addSubtagLabel"
        @blur="submitNewTag(true)"
        @submit="submitNewTag"
      />
    </div>
    <div
      v-show="isSelected"
      class="tag-record__controls"
    >
      <button
        :aria-label="addSubtagLabel"
        class="tag-record__add-sub-tag"
        data-automation="tag-add-sub-tag"
        @blur="handleBlur"
        @click.stop="showSubtagField"
        @keypress.stop
      />
      <button
        :aria-label="deleteLabel"
        class="tag-record__delete"
        data-automation="tag-delete-btn"
        @blur="handleBlur"
        @click.stop="submitDeleteIntent"
        @keypress.stop
      />
    </div>
  </div>
</template>

<script>
import AddItemInput from '@/components/AddItemInput';
import TagSelectableBase from './TagSelectableBase';

const tagClass = {
  base: 'tag-record',
  selected: 'tag-record--selected',
  label: 'tag-record__label',
  extendingLabel: 'tag-record__label--extending',
  input: 'tag-record__label-input',
  editingInput: 'tag-record__label-input--editing',
};

export default {
  name: 'TagItem',
  components: {
    AddItemInput,
  },
  extends: TagSelectableBase,
  props: {
    label: {
      type: String,
      required: true,
    },
    addSubtagLabel: {
      type: String,
      required: true,
    },
    deleteLabel: {
      type: String,
      required: true,
    },
  },
  emits: ['delete', 'extend', 'update'],
  data() {
    return {
      value: this.label,
      isAddingSubtag: false,
      newSubtagName: '',
    };
  },
  computed: {
    componentClass() {
      const classMap = [tagClass.base];
      if (this.isSelected) {
        classMap.push(tagClass.selected);
      }
      return classMap;
    },
    labelClass() {
      const classMap = [tagClass.label];
      if (this.isAddingSubtag) {
        classMap.push(tagClass.extendingLabel);
      }
      return classMap;
    },
    inputClass() {
      const classMap = [tagClass.input];
      if (this.isEditing) {
        classMap.push(tagClass.editingInput);
      }
      return classMap;
    },
  },
  methods: {
    showSubtagField() {
      this.isAddingSubtag = true;
      // Wait for subtag input to show/exist
      this.$nextTick().then(() => {
        this.isSelected = false;
        this.$refs.subtagInput.focus();
      });
    },
    submitNewTag(onBlur) {
      this.$emit('extend', { name: this.newSubtagName, onBlur });
    },
    submitUpdateIntent() {
      this.$emit('update', this.value);
    },
    submitDeleteIntent() {
      this.$emit('delete');
    },
  },
};
</script>

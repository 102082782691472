// Copyright (C) 2022 by Posit Software, PBC.

import DownloadExample from './panels/DownloadExample';
import OpenExample from './panels/OpenExample';
import OpenIDE from './panels/OpenIDE';
import PlumberAPIFile from './panels/PlumberAPIFile';
import PlumberAPIIntro from './panels/PlumberAPIIntro';
import PlumberAPIOutro from './panels/PlumberAPIOutro';
import PublishIDE from './panels/PublishIDE';
import RunExample from './panels/RunExample';

export default {
  bare: [PlumberAPIIntro, OpenIDE, PlumberAPIFile, PublishIDE, PlumberAPIOutro],
  copyFrom: [
    DownloadExample,
    OpenIDE,
    OpenExample,
    RunExample,
    PublishIDE,
    PlumberAPIOutro,
  ],
};

// Copyright (C) 2022 by Posit Software, PBC.

import DownloadExample from './panels/DownloadExample';
import InstallRSConnectJupyter from './panels/InstallRSConnectJupyter';
import JupyterNotebookFile from './panels/JupyterNotebookFile';
import JupyterNotebookIntro from './panels/JupyterNotebookIntro';
import JupyterNotebookOpen from './panels/JupyterNotebookOpen';
import JupyterNotebookOutro from './panels/JupyterNotebookOutro';
import PublishJupyter from './panels/PublishJupyter';

export default {
  bare: [
    JupyterNotebookIntro,
    InstallRSConnectJupyter,
    JupyterNotebookFile,
    PublishJupyter,
    JupyterNotebookOutro,
  ],
  copyFrom: [
    DownloadExample,
    InstallRSConnectJupyter,
    JupyterNotebookOpen,
    PublishJupyter,
    JupyterNotebookOutro,
  ],
};

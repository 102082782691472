<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<template>
  <div
    :data-automation="dataAutomation"
    class="rs-field"
  >
    <div
      v-if="showLabel"
      class="rs-field__help"
    >
      <label
        ref="inputSelectLabel"
        :for="name"
        class="rs-field__help-label"
      >
        <slot name="label">
          {{ label }}
        </slot>
      </label>
      <i
        v-if="hasHelp"
        :class="{active: helpShown}"
        class="rs-field__help-icon"
        aria-label="More Information"
        role="button"
        tabindex="0"
        @click="toggleHelp"
        @keypress="toggleHelp"
      />
    </div>
    <div
      v-if="helpShown"
      class="rs-field__text"
    >
      <span v-if="$slots.help">
        <slot name="help" />
      </span>
      <span v-else>{{ help }}</span>
    </div>
    <div class="rs-field__control">
      <!-- eslint-disable-next-line vuejs-accessibility/no-onchange -->
      <select
        v-bind="$attrs"
        :id="name"
        :name="name"
        :value="modelValue"
        :disabled="disabled"
        :aria-label="name"
        :class="{ error: hasError, warning: hasWarning, info: hasInfo }"
        class="rs-input"
        :aria-describedby="`${ name }-message`"
        :aria-invalid="hasError"
        @change="changeHandler"
      >
        <option
          v-for="option in options"
          :key="option.label"
          :disabled="option.disabled === true"
          :value="option.value"
        >
          {{ option.label }}
        </option>
      </select>
    </div>
    <!-- message text -->
    <div
      v-if="hasMessage"
      :id="`${ name }-message`"
      :class="{ 'rs-field__error': hasError, 'rs-field__warning': hasWarning, 'rs-field__info': hasInfo }"
    >
      <span v-if="$slots.message">
        <slot name="message" />
      </span>
      <span v-else>{{ message }}</span>
    </div>
  </div>
</template>

<script>
const ErrorMessage = 'error';
const WarningMessage = 'warning';
const InfoMessage = 'info';

export default {
  name: 'RSInputSelect',
  inheritAttrs: false,
  props: {
    modelValue: {
      type: String,
      default: null
    },
    dataAutomation: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    help: {
      type: String,
      default: null
    },
    options: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    message: {
      type: String,
      default: null
    },
    messageType: {
      type: String,
      default: ErrorMessage
    },
  },
  emits: ['change', 'update:modelValue'],
  data() {
    return {
      helpShown: false
    };
  },
  computed: {
    hasMessage() {
      return Boolean(this.message) || Boolean(this.$slots.message);
    },
    hasHelp() {
      return Boolean(this.help) || Boolean(this.$slots.help);
    },
    hasError() {
      return this.hasMessage && this.messageType === ErrorMessage;
    },
    hasWarning() {
      return this.hasMessage && this.messageType === WarningMessage;
    },
    hasInfo() {
      return this.hasMessage && this.messageType === InfoMessage;
    },
  },
  mounted() {
    if (!(this.label || this.$slots.label)) {
      throw new Error('Missing label - please provide it as a prop or slot');
    }
  },
  methods: {
    changeHandler(ev) {
      this.$emit('change', ev.target.value);
      this.$emit('update:modelValue', ev.target.value);
    },
    toggleHelp() {
      this.helpShown = !this.helpShown;
    },
    focusElement() {
      this.$refs.inputSelectLabel.focus();
    }
  }
};
</script>

<style scoped lang="scss">
@import 'Styles/shared/_colors';
@import 'Styles/shared/_variables';
@import 'Styles/shared/_mixins';

.rs-field {
  position: relative;

  &:not(:last-child) {
    margin-bottom: 0.9rem;
  }

  &__text {
    font-size: $rs-font-size-small;
    border: 1px solid $color-light-grey-3;
    background-color: $color-light-grey-2;
    margin: 3px 0 5px 0;
    padding: 0.5rem;
    line-height: 1.5;

    p, ul, ol, li {
      margin: 0.3rem 0;
      padding: 0;
    }

    ul, ol {
      padding-left: 1rem;
    }
  }

  &__control {
    & .rs-input {
      flex: 1;
      border: 1px solid $color-medium-grey;
      &:disabled {
        @include control-disabled-input;
      }
    }
  }

  &__error {
    @include message;
    color: $color-error;
  }

  &__warning {
    @include message;
    color: $color-warning;
  }

  &__info {
    @include message;

    &-label {
      font-size: 0.9rem;
    }
  }

  &__help {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.25rem;

    &-label {
      @include label;
    }

    &-icon {
      width: $rs-icon-size-smaller;
      height: $rs-icon-size-smaller;
      margin: 0;
      padding: 0;
      background-color: transparent;
      border: none;
      background-repeat: no-repeat;
      background-size: $rs-icon-size-smaller $rs-icon-size-smaller*2;
      background-position: 0 0;
      background-image: url(/images/elements/actionToggleInfo.svg);
      cursor: pointer;

      &.active {
        background-position: 0 (-$rs-icon-size-smaller);
      }

      &:focus {
        @include control-focus;
        border-radius: 50%;
      }
    }
  }
}

.rs-input {
  width: 100%;
  max-width: 100%;
  font-size: $rs-font-size-normal;
  margin: 0;
  padding: 0.4rem 0.6rem;
  background-color: #fff;
  color: $color-secondary-inverse;
  appearance: none;
  background: url(/images/elements/iconDownArrow.svg) no-repeat right 2px center;
  background-size: 1.2rem;

  &:focus {
    @include control-focus;
  }

  @include message-state;
}

select.rs-input {
  height: 2rem;
  padding: 0.3rem 1.4rem 0.3rem 0.4rem;
}
</style>

<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="admin-queue">
    <div class="flex">
      <h1
        ref="title"
        class="sectionTitle focusedTitle"
        tabindex="-1"
      >
        Queue
      </h1>
    </div>
    <div
      v-if="hasRecords"
    >
      <RSTable
        :columns="tableHeaders"
      >
        <RSTableRow
          v-for="(record, rowIndex) in queueRecords"
          :key="record.id"
          :row-id="record.id"
          :deletable="true"
          @delete="deleteQueueHandler(record.id, rowIndex)"
        >
          <RSTableCell
            v-for="recordKey in queueRecordOrderedKeys"
            :key="`${record.id}-${recordKey}`"
          >
            <pre
              v-if="recordKey === 'payload'"
              class="queue-payload"
            >{{ record[recordKey] }}</pre>
            <span v-else>
              {{ record[recordKey] }}
            </span>
          </RSTableCell>
        </RSTableRow>
      </RSTable>
    </div>
    <div
      v-else
      class="emptyListMessage"
    >
      No results.
    </div>
  </div>
</template>

<script>
import { deleteQueueProcess, getQueue } from '@/api/queue';
import RSTable from '@/elements/RSTable';
import RSTableCell from '@/elements/RSTableCell';
import RSTableRow from '@/elements/RSTableRow';
import { SET_ERROR_MESSAGE_FROM_API } from '@/store/modules/messages';
import { mapMutations } from 'vuex';

// Keys used to loop columns in order
const queueRecordOrderedKeys = [
  'id',
  'queue',
  'priority',
  'type',
  'active',
  'payload',
  'permit',
  'heartbeat',
  'hostname',
  'createdTime',
];

const columnHeaders = {
  id: 'ID',
  queue: 'Queue',
  priority: 'Priority',
  type: 'Type',
  active: 'Active',
  payload: 'Payload',
  permit: 'Permit',
  heartbeat: 'Permit Expiration',
  hostname: 'Hostname',
  createdTime: 'Created Time',
};

export default {
  name: 'AdminQueueView',
  components: {
    RSTable,
    RSTableRow,
    RSTableCell,
  },
  data() {
    return {
      queueRecords: [],
      fetchInterval: null,
    };
  },
  computed: {
    hasRecords() {
      return Boolean(this.queueRecords.length);
    },
  },
  created() {
    this.tableHeaders = queueRecordOrderedKeys.map(columnkey => {
      const label = columnHeaders[columnkey];
      if (columnkey === 'createdTime') {
        return {
          label,
          size: 2,
        };
      }
      return label;
    });
    this.queueRecordOrderedKeys = queueRecordOrderedKeys;
    this.init();
  },
  mounted() {
    this.$refs.title.focus();
  },
  beforeUnmount() {
    clearInterval(this.fetchInterval);
  },
  methods: {
    ...mapMutations({
      setErrorMessageFromAPI: SET_ERROR_MESSAGE_FROM_API,
    }),
    init() {
      const fetchCaller = this.fetchQueue.bind(this);
      this.fetchQueue();
      this.fetchInterval = setInterval(fetchCaller, 5000);
    },
    fetchQueue() {
      return getQueue()
        .then(records => {
          this.queueRecords = records;
        })
        .catch(this.setErrorMessageFromAPI);
    },
    deleteQueueHandler(processId, rowIndex) {
      return deleteQueueProcess(processId)
        .then(() => {
          this.queueRecords.splice(rowIndex, 1);
        })
        .catch(this.setErrorMessageFromAPI);
    },
  },
};
</script>

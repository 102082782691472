<!-- Copyright (C) 2023 by Posit Software, PBC. -->
<template>
  <div
    v-if="appSettings"
    class="params-panel contentPanel"
    :class="{ closed: !showPanel }"
  >
    <div v-if="showPanel">
      <div
        v-if="showProcessing"
        class="contentMessageContainer"
        data-automation="params-processing"
      >
        <div class="genMessage">
          <div class="genMessageSpinnerContainer">
            <Spinner />
          </div>
          Processing...
        </div>
      </div>

      <div
        v-show="!showProcessing"
        class="settingsPane"
        data-automation="legacy-params-pane"
      >
        <div class="formSection">
          <div
            data-automation="params-title"
          >
            Parameters for {{ variantName }}
          </div>
        </div>

        <ParametersEditor
          v-if="canEdit || canViewerCustomize"
          :url="paramsOverrides.url"
        />

        <div
          v-if="showNotEditable"
          data-automation="params-not-editable"
        >
          <p class="params-panel__description">
            You do not have privileges to customize parameters for this report.
            The configured parameter values are displayed below.
          </p>
          <pre
            class="paramOverrides"
            data-automation="params-not-editable__pre"
          >{{ overrideValues }}</pre>
        </div>

        <div class="formSection">
          <p class="params-panel__render-info">
            <span v-if="currentVariant.renderTime">
              Output last rendered on {{ lastRenderedTime }}.
            </span>
            <span v-else>
              Output using the current parameters not generated.
            </span>
          </p>
        </div>

        <ParametersActions v-if="canEdit || canViewerOnDemand" />
      </div>
    </div>

    <button
      aria-label="Open"
      class="params-panel__toggler"
      data-automation="parameters-panel-toggle"
      @click="open"
    />
    <button
      aria-label="Close"
      class="params-panel__close panelClose"
      data-automation="parameters-panel-close"
      @click="close"
    />

    <RenameVariantModal v-if="renamingVariant" />
    <SaveAsModal v-if="savingVariant" />

    <!-- Confirm unsaved changes modal -->
    <ConfirmModal
      v-if="showUnsavedChangesModal"
      subject="Confirm Action"
      details="You have unsaved changes that will be lost if you close the input panel. Are you sure you wish to continue?"
      @confirm="confirmUnsaved"
      @cancel="cancelUnsaved"
    />
  </div>
</template>

<script>
import { getApplicationsSettings } from '@/api/serverSettings';
import ConfirmModal from '@/components/ConfirmModal';
import Spinner from '@/components/Spinner';
import {
  CLOSE_PANEL,
  OPEN_PANEL,
  PARAMETERS_PANEL,
  TOGGLE_PANELS,
} from '@/store/modules/contentView';
import {
  LEGACY_PARAMS_CLEAR,
  LEGACY_PARAMS_CONFIRM_IGNORE_CHANGES,
  LEGACY_PARAMS_SET_UNSAVED_MODAL,
  LEGACY_PARAMS_START,
} from '@/store/modules/legacyParams';
import { SET_ERROR_MESSAGE_FROM_API } from '@/store/modules/messages';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { mapActions, mapMutations, mapState } from 'vuex';
import ParametersActions from './ParametersActions';
import ParametersEditor from './ParametersEditor';
import RenameVariantModal from './RenameVariantModal';
import SaveAsModal from './SaveAsModal';

export default {
  name: 'LegacyParametersPanel',
  components: {
    Spinner,
    ParametersEditor,
    ParametersActions,
    RenameVariantModal,
    SaveAsModal,
    ConfirmModal,
  },
  data: () => ({
    appSettings: null,
    overrides: null,
  }),
  computed: {
    ...mapState({
      currentUser: state => state.currentUser.user,
      app: state => state.contentView.app,
      showPanel: state => state.contentView.showParametersPanel,
      currentVariant: state => state.parameterization.currentVariant,

      isNew: state => state.legacyParams.isNew,
      isBusy: state => state.legacyParams.isBusy,
      paramsForm: state => state.legacyParams.form,
      paramsOverrides: state => state.legacyParams.overrides,
      renamingVariant: state => state.legacyParams.renamingVariant,
      savingVariant: state => state.legacyParams.savingVariant,
      showUnsavedChangesModal: state => state.legacyParams.unsavedChanges.showModal,
    }),
    isReady() {
      return this.paramsForm.loaded && !this.isBusy;
    },
    canEdit() {
      return this.currentUser.isAppEditor(this.app);
    },
    canViewerOnDemand() {
      return this.currentUser.canViewApp(this.app) && this.appSettings.viewerOnDemandReports;
    },
    canViewerCustomize() {
      return this.currentUser.canViewApp(this.app) && this.appSettings.viewerCustomizedReports;
    },
    variantName() {
      return this.currentVariant?.name || '';
    },
    lastRenderedTime() {
      return dayjs(this.currentVariant.renderTime).format('MMM D, YYYY h:mma ([GMT]Z)');
    },
    showProcessing() {
      return !this.isReady && !this.showNotEditable;
    },
    showNotEditable() {
      if (this.canEdit) {
        return false;
      }
      return !this.canViewerCustomize;
    },
    overrideValues() {
      const noCustomParams = 'No custom parameters';

      if (isEmpty(this.paramsOverrides) || !this.paramsOverrides.values) {
        return noCustomParams;
      }

      try {
        return JSON.stringify(JSON.parse(this.paramsOverrides.values), null, 2);
      } catch {
        return noCustomParams;
      }
    },
  },
  watch: {
    currentVariant(newVariant) {
      if (this.showPanel) {
        this.clearParams();
        this.startParams(newVariant.id);
      }
    },
  },
  created() {
    getApplicationsSettings()
      .then(settings => {
        this.appSettings = settings;
      })
      .catch(this.setErrorMessageFromAPI);
  },
  methods: {
    ...mapActions({
      startParams: LEGACY_PARAMS_START,
      togglePanels: TOGGLE_PANELS,
      ignoreChanges: LEGACY_PARAMS_CONFIRM_IGNORE_CHANGES,
    }),
    ...mapMutations({
      clearParams: LEGACY_PARAMS_CLEAR,
      setUnsavedModal: LEGACY_PARAMS_SET_UNSAVED_MODAL,
      setErrorMessageFromAPI: SET_ERROR_MESSAGE_FROM_API,
    }),
    open() {
      this.togglePanels({ panel: PARAMETERS_PANEL, action: OPEN_PANEL });
      this.startParams(this.currentVariant.id);
    },
    close() {
      this.togglePanels({ panel: PARAMETERS_PANEL, action: CLOSE_PANEL });

      // If params form isn't dirty, we can clear up state right away
      // else, the toggle panels action will call for the confirm modal.
      if (!this.isNew && !this.paramsForm.dirty) {
        this.clearParams();
      }
    },
    async confirmUnsaved() {
      const unsavedNewVariant = this.isNew;
      await this.ignoreChanges();

      // If it was an unsaved new variant, navigate to current variant
      // which should be the default variant in state at this point
      if (unsavedNewVariant) {
        this.$router.push({ params: { id: this.currentVariant.id } });
      }
    },
    cancelUnsaved() {
      this.setUnsavedModal({ show: false });
    },
  }
};
</script>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';
@import 'Styles/shared/_variables';
@import 'Styles/shared/_mixins';

.params-panel {
  left: 0;
  width: $params-panel-width;
  background-color: $color-white;
  border-right: 1px solid $color-light-grey-2;

  z-index: 20;

  @include transition-property(left);
  @include normal-transition-duration();

  &__toggler {
    display: none;
    position:absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    width: $params-panel-closed-width;
    background-color: $color-light-grey;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 40%;
      left: $params-panel-closed-width;
      width: 30px;
      height: 85px;

      background-image: url(/images/elements/paramsToggler.png);
      background-size: 100% 100%;

      text-indent: -9999px;
    }
  }

  &__close {
    position:absolute;
    top: 10px;
    right: 16px;
  }

  &.closed {
    left: $params-panel-closed-left;
    overflow: visible;

    .params-panel__toggler {
      display: block;
    }
  }

  &__render-info {
    line-height: normal;
  }

  &__description {
    margin-bottom: 1rem;
  }
}
</style>

<!-- Copyright (C) 2022 by Posit Software, PBC. -->
<template>
  <div
    aria-live="polite"
    role="alert"
    :class="{
      statusMessage: true,
      aboveActions: true,
      showing: shouldRenderMessage,
      activity: isActivityMessage,
      error: isErrorMessage,
      info: isInfoMessage,
      warning: isWarningMessage,
      fadeOut,
      fadeActive: isFadeActive,
      sizeSmall: isSmall
    }"
  >
    <div
      v-if="shouldRenderMessage"
      class="message"
    >
      <div
        v-if="isActivityMessage"
        class="messageSpinnerContainer"
      >
        <Spinner />
      </div>
      <!-- eslint-disable vue/no-v-html -->
      <span
        :class="{
          sizeSmall: isSmall,
          truncate
        }"
        class="messageText"
        v-html="message"
      />
      <!-- eslint-enable vue/no-v-html -->
    </div>
    <div
      v-if="showCloseButton"
      aria-label="Close"
      class="messageClose"
      role="button"
      tabindex="0"
      @click="closeMessage"
      @keydown.space="closeMessage"
    />
  </div>
</template>

<script>
import Spinner from '@/components/Spinner.vue';
import isEmpty from 'lodash/isEmpty';

export const ActivityMessage = 'activity';
export const ErrorMessage = 'error';
export const InfoMessage = 'info';
export const WarningMessage = 'warning';

export const SizeLarge = 'large';
export const SizeSmall = 'small';

export default {
  name: 'EmbeddedStatusMessage',
  components: {
    Spinner,
  },
  props: {
    message: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: null,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: SizeLarge,
    },
    fadeOut: {
      type: Boolean,
      default: false,
    },
    truncate: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['close'],
  computed: {
    isActivityMessage() {
      return this.type === ActivityMessage;
    },
    isErrorMessage() {
      return this.type === ErrorMessage;
    },
    isInfoMessage() {
      return this.type === InfoMessage;
    },
    isWarningMessage() {
      return this.type === WarningMessage;
    },
    hasMessage() {
      return !isEmpty(this.message);
    },
    showCloseButton() {
      return this.showClose && Boolean(this.message);
    },
    isSmall() {
      return this.size === SizeSmall;
    },
    isFadeActive() {
      return this.hasMessage && this.fadeOut;
    },
    shouldRenderMessage() {
      return this.hasMessage || this.fadeOut;
    },
  },
  methods: {
    closeMessage() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
@import 'Styles/shared/_colors';
@import 'Styles/shared/_variables';
@import 'Styles/shared/_mixins';

.statusMessage {
  border-radius: 3px;
  position: relative;
  max-height: 0px;
  overflow: hidden;

  @include normal-transition-duration();
  @include transition-property(max-height, margin);

  .message {
    align-items: center;
    border-top: 1px dotted $color-light-grey-4;
    color: $color-white;
    display: flex;
    height: auto;
    justify-content: center;
    line-height: 26px;
    margin-top: 0px;
    opacity: 1;
    padding: 4px 45px;
    transition: opacity 3s ease-in;

    a {
      color: $color-white;
    }

    .messageText{
      &.truncate {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &.sizeSmall {
        line-height: 1.4;
        padding: .5em 0;
      }
    }
  }

  &.showing {
    max-height: 100px;
  }

  &.activity {
    @include transition-delay(0.5s);
    .message {
      background-color: $color-light-grey;
      color: $color-dark-grey-3;

      .messageSpinnerContainer {
        display: inline-block;
      }
    }
  }

  &.warning {
    .message {
      background-color: $color-warning-background;
      color: $color-warning;
    }
  }

  &.error {
    .message {
      background-color: $color-error-background;
      color: $color-error;
    }
  }

  &.info {
    .message {
      background-color: lighten($color-alert-info, 15%);
      color: darken($color-posit-green, 15%);
    }
  }

  &.status {
    .message {
      background-color: $color-light-grey;
      color: $color-dark-grey-3;
    }
    #messageClose, .messageClose {
      background-color: transparent;
    }
  }

  #messageClose, .messageClose {
    position: absolute;
    right: .5em;
    top: 2px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  }

  .messageSpinnerContainer {
    display: none;
    width: 25px;
    height: 25px;
    margin: -5px 5px -5px 0;
  }

  &.sizeSmall {
    .message {
      font-size: 13px;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &.fadeOut:not(.fadeActive) {
    .message {
      border: none;
      background-color: transparent;
    }
  }

  &.fadeActive {
    .message {
      opacity: 0;
    }
  }
}
</style>

<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div>
    <EmbeddedStatusMessage
      v-if="loading"
      message="Loading access settings..."
      :show-close="false"
      type="activity"
      data-automation="loading"
    />
    <div
      v-if="!loading"
      class="formSection itemDate"
    >
      <div
        class="created"
        data-automation="settings-info-created"
      >
        <span class="emphasize">Created:</span> {{ created }}
      </div>
      <div
        class="updated"
        data-automation="settings-info-last-deployed"
      >
        <span class="emphasize">Last Deployed:</span> {{ lastDeployed }}
      </div>
      <div
        class="type"
        data-automation="settings-info-type"
      >
        <span class="emphasize">Content Type:</span> {{ typeDescription }}
      </div>
      <div
        class="guid"
        data-automation="settings-info-app-guid"
      >
        <span class="emphasize">GUID:</span> {{ appGuid }}
        <img
          src="/images/copy.svg"
          class="copy-guid"
          alt="Copy GUID"
          tabindex="0"
          role="button"
          @click="copyGUID"
          @keydown.space="copyGUID"
        >
      </div>
      <div
        class="app-id"
        data-automation="settings-info-app-id"
      >
        <span class="emphasize">Content ID:</span> {{ appId }}
      </div>
      <div
        v-show="rVersion"
        class="rversion"
        data-automation="settings-info-rversion"
      >
        <span class="emphasize">R Version:</span> {{ rVersion }}
      </div>
      <div
        v-show="pyVersion"
        class="pyversion"
        data-automation="settings-info-pyversion"
      >
        <span class="emphasize">Python Version:</span> {{ pyVersion }}
      </div>
      <div
        v-show="quartoVersion"
        class="quartoversion"
        data-automation="settings-info-quartoversion"
      >
        <span class="emphasize">Quarto Version:</span> {{ quartoVersion }}
      </div>
      <div
        v-show="imageName && clusterName"
        class="imageNameWithCluster"
        data-automation="settings-info-imageNameWithCluster"
      >
        <span class="emphasize">Last Execution:</span>
        {{ `Used the ${imageName} image within the ${clusterName} cluster` }}
      </div>
    </div>
  </div>
</template>

<script>
import EmbeddedStatusMessage from '@/components/EmbeddedStatusMessage';
import { copyToClipboard } from '@/utils/clipboard';
import dayjs from 'dayjs';
import { mapState } from 'vuex';

export default {
  name: 'ContentInfo',
  components: {
    EmbeddedStatusMessage
  },
  computed: {
    ...mapState({
      created: ({ infoSettings: { app: { createdTime } } }) => dayjs(createdTime)
        .format('MMM D, YYYY h:mma ([GMT]Z)'),
      lastDeployed: ({ infoSettings: { app: { lastDeployedTime } } }) => dayjs(lastDeployedTime)
        .format('MMM D, YYYY h:mma ([GMT]Z)'),
      typeDescription: state => state.infoSettings.app.typeDescription,
      appGuid: state => state.infoSettings.app.guid,
      appId: state => state.infoSettings.app.id,
      rVersion: state => state.infoSettings.app.rVersion,
      pyVersion: state => state.infoSettings.app.pyVersion,
      quartoVersion: state => state.infoSettings.app.quartoVersion,
      imageName: state => state.infoSettings.app.imageName,
      clusterName: state => state.infoSettings.app.clusterName,
    }),
    loading() {
      return this.created === null;
    }
  },
  methods: {
    async copyGUID() {
      await copyToClipboard({ value: this.appGuid });
    }
  }
};
</script>

<style scoped lang="scss">
@import 'Styles/shared/_colors';
@import 'Styles/shared/_mixins';

.copy-guid {
  @include control-visible-focus;
  padding: 4px;
  margin: 0 0 -4px 1px;
  &:hover {
    background: $color-light-grey-3;
  }
}
</style>

<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div>
    <button
      v-if="isAuthenticated"
      class="userLink profileButton"
      data-automation="profile-button"
      aria-label="Profile Menu"
      @click="showUserSidePanel"
    >
      <UserBadge
        v-if="currentUser.guid"
        :username="currentUser.username"
        :first-name="currentUser.firstName"
        :last-name="currentUser.lastName"
        :clickable="false"
        data-automation="user-badge"
      />
    </button>
    <div
      v-if="showFullMenuForAnon"
      aria-label="Login Menu"
      class="menu aux"
      data-automation="aux-menu"
    >
      <div class="menuItems">
        <RouterLink
          v-if="!isLoginPage"
          data-automation="signin"
          :to="{ name: 'login_view' }"
          class="menuItem"
        >
          Log In
        </RouterLink>
        <RouterLink
          v-if="showRegisterLink"
          data-automation="signup"
          class="menuItem"
          :to="{ name: 'register_view' }"
        >
          Sign Up
        </RouterLink>
      </div>
    </div>
  </div>
</template>

<script>
import UserBadge from '@/components/UserBadge.vue';
import { SHOW_USER_PANEL } from '@/store/modules/navigation';
import { mapMutations, mapState } from 'vuex';
import { RouterLink } from 'vue-router';

export default {
  name: 'CurrentUser',
  components: {
    UserBadge,
    RouterLink,
  },
  props: {
    serverSettings: {
      type: Object,
      default: () => ({
        selfRegistration: false,
        authentication: {
          externalAuth: false,
          handlesLogin: false,
        },
      }),
    },
  },
  computed: {
    ...mapState({
      currentUser: state => state.currentUser.user,
      isAuthenticated: state => state.currentUser.isAuthenticated,
    }),
    currentRouteName() {
      return this.$route.name;
    },
    isLoginPage() {
      return this.currentRouteName === 'login_view';
    },
    isRegisterPage() {
      return this.currentRouteName === 'register_view';
    },
    isContentPage() {
      return [
        'apps',
        'apps.access',
        'apps.access.variant'
      ].includes(this.currentRouteName);
    },
    showRegisterLink() {
      return this.serverSettings.selfRegistration
        && !this.isRegisterPage
        && !this.serverSettings.authentication.externalAuth
        && !this.isContentPage;
    },
    showFullMenuForAnon() {
      if (!Object.keys(this.serverSettings).length) {
        return false;
      }
      return !this.isAuthenticated && this.serverSettings.authentication.handlesLogin;
    }
  },
  methods: {
    ...mapMutations({
      openUserPanel: SHOW_USER_PANEL
    }),
    showUserSidePanel() {
      this.openUserPanel(true);
      setTimeout(() => {
        const profileButton = document.querySelector('.menuItem.profile');
        profileButton?.focus();
      }, 200);
    },
  }
};
</script>

<style scoped lang="scss">
@import 'Styles/shared/_colors';
@import 'Styles/shared/_variables';
@import 'Styles/shared/_mixins';

.userLink {
  background-color: $color-white;
  display: inline-block;
  height: 34px;
  padding: 0;
  @include control-visible-focus;
}
.menuItems {
  display: inline-block !important;
}
.profileButton {
  padding: 0;
  background-color: transparent;
}

.menu {
  font-weight: inherit;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;

  &.aux {
    flex: 2 0 auto;
    -ms-flex: 2 0 auto;
  }

  .menuItem, .menuItem * {
    @include transition-property(opacity, background-color);
    @include normal-transition-duration();
  }

  .menuItem {
    line-height: inherit;
    background-color: transparent;
    display: inline-block;
    padding: 6px 15px;
    margin-right: 3px;
    vertical-align: middle;
    cursor: pointer;
    color: inherit;
    border-radius: $small-border-radius;

    &:hover {
      text-decoration: none;
      background-color: $color-light-grey;
    }
  }
}

@media screen and (max-width: 1023px) {
  .menu.aux .menuItems {
    display: none;
  }
}
</style>

<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div
    class="access"
    data-automation="app-settings__access"
  >
    <div
      v-if="loadingError"
      class="formSection"
      data-automation="loading-error"
    >
      <p>An error occurred while loading the access settings.</p>
    </div>

    <ConfirmationPanel
      :enabled="enableConfirmation"
      :visible="showConfirmation"
      @save="save"
      @discard="discard"
    />
    <MessageBox
      v-if="requestByToken"
      small
      :closeable="requestMsgCanBeClosed"
      @close="clearPermissionRequest"
    >
      <!-- eslint-disable-next-line vue/no-v-html -->
      <span v-html="requestByTokenMsg" />
    </MessageBox>

    <!-- OAuth -->
    <div
      v-if="serverSettings.oauthIntegrationsEnabled && contentAssociations.length !== 0"
      class="formSection spaceAfter"
      data-automation="oauth-integrations"
    >
      <div class="groupHeadings">
        Integrations
      </div>
      <div
        v-for="(auth, i) in oauthSessionAuth"
        :key="i"
      >
        <div>{{ auth[0] }}</div>
        <a
          :href="auth[2]"
          class="login-button"
        >{{ auth[1] }}</a>
      </div>
    </div>
    <div>
      <!-- Sharing --> 
      <RSInformationToggle
        class="spaceAfter"
      >
        <template #title>
          <span class="groupHeadings">
            Sharing
          </span>
        </template>
        <template #help>
          <div class="spaceBefore spaceAfter">
            <h4>Who can view or change this content.</h4>

            <ul class="access-help">
              <li>
                Give specific people and groups access to your content by adding them as
                viewers or collaborators.
              </li>
              <li>
                Search by their name and use the drop-down
                to specify what privileges they should receive.
              </li>
              <li>
                Adjust viewer and collaborator permissions after adding people by using the
                drop-down next to their name.
              </li>
            </ul>
            <p
              v-if="anonymousServersUnlicensedHelp"
              class="anonymous-servers-unlicensed-help"
            >
              The product license prohibits access to interactive content without login.
              <a
                :href="licensingDocumentation"
                target="_blank"
              >
                Learn more.
              </a>
            </p>

            <p v-if="anonymousServersVerificationHelp">
              The product license allows interactive content to be hosted
              publicly online without login. Posit Connect periodically checks
              that public access content is available online. Content that
              cannot be verified will be restricted to logged-in users.
              <a
                :href="licensingDocumentation"
                target="_blank"
              >
                Learn more.
              </a>
            </p>

            <p v-if="anonymousBrandingHelp">
              The product license displays a "Powered by Posit Connect" badge on content that
              permits access without log in.
              <a
                :href="licensingDocumentation"
                target="_blank"
              >
                Learn more.
              </a>
            </p>
          </div>
        </template>
      </RSInformationToggle>

      <div class="formSection">
        <AccessType
          v-if="showAccessType && type"
          :read-only="!canEditSettings"
          :visible-types="visibleAccessTypes"
          :is-worker-app="isWorkerApp"
          :anonymous-branding="anonymousBranding"
        />

        <p
          v-if="showPublicContentInfoLink"
          class="access-type-admin-guide-link"
        >
          See the
          <a :href="publicAccessAdminGuideURL">
            Posit Connect Admin Guide
          </a>
          for more information about public-access content verification.
        </p>

        <p
          v-if="showPublicContentProblemLink"
          class="access-type-admin-guide-link"
        >
          Resolving the validation problem may require a change in this server's
          network configuration. See the
          <a :href="publicAccessAdminGuideURL">
            Posit Connect Admin Guide
          </a>
          for more information.
        </p>

        <SearchBox
          v-if="showSearchBox"
          label="Share with"
          :server-settings="serverSettings"
          :exclusion-set="new Set([ownerGuid])"
          :remote-lookup="false"
          data-automation="as-search"
          name="as-add-access"
          type="all"
          @select="addPrincipal"
        >
          <template #help>
            <strong>Can't find something?</strong>

            <p v-if="cantFindSomethingMsg === 'forAdmin'">
              Users will show up here as they log in. You can also add
              <RouterLink
                class="add-links"
                :to="{ name: 'people.users' }"
              >
                users
              </RouterLink>
              and
              <RouterLink
                class="add-links"
                :to="{ name: 'people.groups' }"
              >
                groups
              </RouterLink>
              yourself.
            </p>

            <p v-if="cantFindSomethingMsg === 'forUsersPermissions'">
              Users will show up here as they log in. Otherwise users or groups need to be added to Connect first.
              You can also add
              <RouterLink
                class="add-links"
                :to="{ name: 'people.users' }"
              >
                users
              </RouterLink>
              yourself.
            </p>

            <p v-if="cantFindSomethingMsg === 'forGroupsPermissions'">
              Users will show up here as they log in. Otherwise users or groups need to be added to Connect first.
              You can also add
              <RouterLink
                class="add-links"
                :to="{ name: 'people.groups' }"
              >
                groups
              </RouterLink>
              yourself.
            </p>

            <p v-if="cantFindSomethingMsg === 'forNoPermissions'">
              Users will show up here as they log in.
              Otherwise users or groups need to be added to Connect by an administrator first.
            </p>
          </template>
        </SearchBox>

        <PrincipalList
          v-if="showPrincipalList && owner"
          :principals="activePrincipals"
          :can-edit-permissions="canEditPermissions"
          :current-user-guid="currentUser.guid"
          :owner="owner"
          :content-type="contentType"
          @update-principal="updatePrincipal"
          @remove-principal="removePrincipal"
        />
      </div>
    </div>

    <!-- Vanity URL -->
    <div>
      <RSInformationToggle>
        <template #title>
          <span class="groupHeadings">
            Content URL
          </span>
        </template>
        <template #help>
          <p>
            A custom URL can be created to access this content.
          </p>
          <p>
            Your custom path will be appended to the URL of your Posit Connect server
            to form a complete URL for this content. The
            <a
              :href="contentUrlDocumentation"
              target="_blank"
            >
              User Guide
            </a>
            explains what custom URL
            paths are permitted.
          </p>
          <p>
            Note that Posit Connect may be configured to restrict
            this option only to Administrators.
          </p>
        </template>
      </RSInformationToggle>

      <ContentUrl
        v-if="showContentUrl"
        v-model="activeCustomUrl"
        :app-guid="app.guid"
        :can-customize="canAddCustomUrl"
        :can-edit-settings="canEditSettings"
        :disable-copy="customUrlIsChanging"
      />
    </div>

    <!-- RunAs & Service Account help text -->
    <div
      v-if="doneLoading && app && app.isExecutable()"
      data-automation="runas-moved-help"
    >
      <span class="groupHeadings">
        Process Execution
      </span>
      <p class="spaceBefore">
        These settings have moved to the Runtime tab.
      </p>
      <hr class="rs-divider">
    </div>

    <!-- Execution Environment help text -->
    <div
      v-if="showExecutionEnvironment"
      data-automation="exec-env-moved-help"
    >
      <span class="groupHeadings">
        Execution Environment
      </span>
      <p class="spaceBefore">
        These settings have moved to the Runtime tab.
      </p>
      <hr class="rs-divider">
    </div>

    <!-- Supportive popups and messages -->
    <EmbeddedStatusMessage
      v-if="loading"
      message="Loading runtime settings..."
      :show-close="false"
      type="activity"
      data-automation="loading"
    />
    <BecomeViewerWarning
      v-if="showBecomeViewerWarning"
      :is-admin="isAdmin"
      @close="closeBecomeViewerWarning"
      @confirm="confirmBecomeViewer"
    />
    <RemovePrincipalWarning
      v-if="showRemovePrincipalWarning"
      :is-admin="isAdmin"
      @close="closeRemovePrincipalWarning"
      @confirm="confirmRemovePrincipal"
    />
  </div>
</template>

<script>
import ConfirmationPanel from '@/views/content/settings/ConfirmationPanel';
import AccessType from './AccessType';
import PublicContentStatuses from '@/api/dto/publicContentStatus';
import BecomeViewerWarning from './BecomeViewerWarning';
import ContentUrl from './ContentUrl';
import PrincipalList from './PrincipalList';
import RemovePrincipalWarning from './RemovePrincipalWarning';
import SearchBox from './SearchBox';

import {
  addGroup,
  addUser,
  removeGroup,
  removeUser,
  updateContent,
} from '@/api/app';
import { setCustomUrl, deleteCustomUrl } from '@/api/customUrl';
import AccessTypes from '@/api/dto/accessType';
import AppRoles from '@/api/dto/appRole';
import { User } from '@/api/dto/user';
import ApiErrors from '@/api/errorCodes';
import { addNewRemoteGroup } from '@/api/groups';
import { getContentAssociations, getUserSessions } from '@/api/oauth';
import { getRequestAccessByToken } from '@/api/permissions';
import { ExecutionTypeK8S, getApplicationsSettings } from '@/api/serverSettings';
import { addNewRemoteUser, getUser } from '@/api/users';
import EmbeddedStatusMessage from '@/components/EmbeddedStatusMessage.vue';
import MessageBox from '@/components/MessageBox';
import RSInformationToggle from '@/elements/RSInformationToggle';
import {
  ACCESS_SETTINGS_UPDATE_MODE,
  ACCESS_SETTINGS_UPDATE_TYPE,
  ModeType,
} from '@/store/modules/accessSettings';
import {
  LOAD_CONTENT_VIEW,
  SET_CONTENT_FRAME_RELOADING,
} from '@/store/modules/contentView';
import {
  CLEAR_STATUS_MESSAGE,
  SET_ERROR_MESSAGE_FROM_API,
  SHOW_ERROR_MESSAGE,
} from '@/store/modules/messages';
import { docsPath, getHashQueryParameter, joinPaths, oauthLoginPath, oauthLogoutPath } from '@/utils/paths';
import upperFirst from 'lodash/upperFirst';
import { mapActions, mapMutations, mapState } from 'vuex';
import { RouterLink } from 'vue-router';

export default {
  name: 'AccessSettings',
  components: {
    ContentUrl,
    ConfirmationPanel,
    SearchBox,
    MessageBox,
    AccessType,
    PrincipalList,
    BecomeViewerWarning,
    RemovePrincipalWarning,
    EmbeddedStatusMessage,
    RSInformationToggle,
    RouterLink,
  },
  data() {
    return {
      loading: true,
      loadingError: false,
      canAddCustomUrl: false,
      canEditSettings: false,
      canEditPermissions: false,
      contentType: '',
      owner: null,
      isAdmin: false,
      isPublisher: false,
      isWorkerApp: false,
      anonymousBranding: false,
      anonymousServersUnlicensedHelp: false,
      anonymousServersVerificationHelp: false,
      anonymousBrandingHelp: false,
      showPublicContentInfoLink: false,
      showPublicContentProblemLink: false,
      visibleAccessTypes: [],
      ownerGuid: null,
      isExecutable: false,
      initial: {
        type: null,
        mode: null,
        principals: [],
        customUrl: null,
      },
      activePrincipals: [],
      activeCustomUrl: null,
      initialized: new Promise(() => {}),
      isSaving: false,
      showBecomeViewerWarning: false,
      showRemovePrincipalWarning: false,
      pendingBecomeViewerPrincipal: null,
      pendingRemovePrincipal: null,
      requestByToken: null,
      contentAssociations: [],
      oauthSessions: [],
    };
  },
  computed: {
    ...mapState({
      app: state => state.contentView.app,
      currentUser: state => state.currentUser.user,
      activeState: state => state.accessSettings,
      serverSettings: state => state.server.settings,
      currentVariant: state => state.parameterization.currentVariant,
      type: state => state.accessSettings.type,
    }),
    doneLoading() {
      return !this.loading && !this.loadingError;
    },
    showAccessType() {
      return this.doneLoading;
    },
    showSearchBox() {
      return this.doneLoading && this.canEditPermissions;
    },
    showPrincipalList() {
      return this.doneLoading;
    },
    showContentUrl() {
      return this.doneLoading && this.app;
    },
    typeIsChanging() {
      return this.initial.type !== this.activeState.type;
    },
    customUrlIsChanging() {
      return this.initial.customUrl?.path !== this.activeCustomUrl;
    },
    listIsChanging() {
      return this.activePrincipals.some(this.isChanged);
    },
    showExecutionEnvironment() {
      if (!this.app) {
        return false;
      }

      return (
        this.doneLoading
        && this.app.isExecutable()
        && (this.currentUser.isAdmin() || this.currentUser.isPublisher())
        && this.serverSettings.executionType === ExecutionTypeK8S
      );
    },
    showConfirmation() {
      return this.doneLoading && (
        this.typeIsChanging ||
        this.listIsChanging ||
        this.customUrlIsChanging
      );
    },
    enableConfirmation() {
      return this.showConfirmation && !this.isSaving;
    },
    cantFindSomethingMsg() {
      const canAddGroups =
        this.currentUser.canCreateGroup(this.serverSettings) ||
        this.currentUser.canAddRemoteGroup(this.serverSettings);
      const canAddUsers =
        this.currentUser.canAddNewUser(this.serverSettings) ||
        this.currentUser.canAddRemoteUser(this.serverSettings);

      if (this.isAdmin || (canAddGroups && canAddUsers)) {
        return 'forAdmin';
      } else if (canAddUsers) {
        return 'forUsersPermissions';
      } else if (canAddGroups) {
        return 'forGroupsPermissions';
      }
      return 'forNoPermissions';
    },
    requestByTokenMsg() {
      if (!this.requestByToken) {
        return null;
      }

      if (this.requestByToken.approved) {
        return `<strong>${
          this.requestByToken.user.displayName
        }</strong> was already added as a <strong>${this.getRequestRole()}</strong>`;
      }
      return `<strong>${
        this.requestByToken.user.displayName
      }</strong> has been added as a <strong>${
        this.getRequestRole()
      }</strong>. You must Save for the changes to take effect.`;
    },
    requestMsgCanBeClosed() {
      return this.requestByToken && this.requestByToken.approved;
    },
    licensingDocumentation() {
      return docsPath('admin/licensing/');
    },
    contentUrlDocumentation() {
      return docsPath('user/content-settings/#custom-url');
    },
    publicAccessAdminGuideURL() {
      return docsPath('admin/licensing/#public-access-content-verification');
    },
    oauthSessionAuth() {
      const simplifiedSessions = {};
      const authList = [];
      for (const session of this.oauthSessions) {
        simplifiedSessions[session.oauthIntegrationGuid] = session.loggedIn;
      }

      for (const association of this.contentAssociations) {
        if (!Object.keys(simplifiedSessions).includes(association.oauthIntegrationGuid) ||
          simplifiedSessions[association.oauthIntegrationGuid] === false)
        {
          authList.push([association.oauthIntegrationDescription, 'Login', oauthLoginPath(
            { guid: association.oauthIntegrationGuid, redirect: window.location.href }
          )]);
        }
        else {
          authList.push([association.oauthIntegrationDescription, 'Logout', oauthLogoutPath(association.oauthIntegrationGuid)]);
        }
      }

      return authList;
    },
  },
  created() {
    this.init();
  },
  methods: {
    ...mapMutations({
      updateType: ACCESS_SETTINGS_UPDATE_TYPE,
      updateMode: ACCESS_SETTINGS_UPDATE_MODE,
      reloadFrame: SET_CONTENT_FRAME_RELOADING,
      clearStatusMessage: CLEAR_STATUS_MESSAGE,
      setErrorMessageFromAPI: SET_ERROR_MESSAGE_FROM_API,
    }),
    ...mapActions({
      resetApp: LOAD_CONTENT_VIEW,
      setErrorMessage: SHOW_ERROR_MESSAGE,
    }),
    init() {
      // When admin self-grants access via embedded content
      // Re-fetch this component's data to update the ACL.
      this.$onAdminSelfGrantAccessDo(this.reloadAccessData);
      this.loading = true;
      this.loadingError = false;
      this.clearStatusMessage();
      this.initialized = this.getData()
        .then(this.oauthInfo)
        .then(this.prefillPermissionRequestIfAny)
        .catch(e => {
          this.loadingError = true;
          this.setErrorMessageFromAPI(e);
        })
        .finally(() => (this.loading = false));
    },
    getData() {
      // TODO: getting applications settings can be done in
      // within the store action resolving the content data -> LOAD_CONTENT_VIEW
      return getApplicationsSettings()
        .then(appSettings => {
          const { app, currentUser, serverSettings } = this;
          if (!app) {
            return;
          }

          this.ownerGuid = app.ownerGuid;
          this.isExecutable = app.isExecutable();
          this.contentType = app.contentType();
          this.canEditSettings = currentUser.canEditAppSettings(app);
          this.canEditPermissions = currentUser.canEditAppPermissions(app);
          this.canAddCustomUrl = currentUser.canAddVanities();
          this.isAdmin = currentUser.isAdmin();
          this.isPublisher = currentUser.isPublisher();

          this.visibleAccessTypes = appSettings.accessTypes;

          this.isWorkerApp = this.app.hasWorker();
          this.anonymousBranding = serverSettings.license.anonymousBranding;
          this.anonymousBrandingHelp = !this.isWorkerApp && this.anonymousBranding;

          // Compute help values for worker apps (static and rendered content
          // will have PublicContentStatuses.None)
          if (this.isWorkerApp) {
            this.anonymousServersUnlicensedHelp = (
              app.publicContentStatus === PublicContentStatuses.Unlicensed
            );
            this.anonymousServersVerificationHelp = (
              app.publicContentStatus === PublicContentStatuses.Restricted ||
              app.publicContentStatus === PublicContentStatuses.Warning ||
              app.publicContentStatus === PublicContentStatuses.Ok
            );
            this.showPublicContentInfoLink = app.publicContentStatus === PublicContentStatuses.Ok &&
              app.accessType === AccessTypes.All;
            this.showPublicContentProblemLink = (
              app.publicContentStatus === PublicContentStatuses.Restricted ||
              app.publicContentStatus === PublicContentStatuses.Warning
            ) && app.accessType === AccessTypes.All;
          }

          // Get app owner. Viewers are not allowed to call getUser, and we only need fields
          // that we already have available on `app`, so just create a User DTO from those.
          // If ViewersCanOnlySeeThemselves is true then owner information has been stripped;
          // a placeholder name is set in the DTO.
          this.owner = new User({
            firstName: app.ownerFirstName,
            lastName: app.ownerLastName,
            username: app.ownerUsername,
            guid: app.ownerGuid,
            email: app.ownerEmail,
            locked: app.ownerLocked,
          });

          this.initial.mode =
            app.accessType === AccessTypes.Acl
              ? ModeType.VIEWER
              : ModeType.OWNER;

          this.initial.type = app.accessType;

          // Sort existing principals by name. Newly added principals should appear
          // at the top of the list, so sorting should not be repeated.
          this.initial.principals = [...app.users, ...app.groups].sort((a, b) => {
            const aname = a.displayName ? a.displayName : a.name;
            const bname = b.displayName ? b.displayName : b.name;
            if (aname < bname) {
              return -1;
            }
            if (aname > bname) {
              return 1;
            }
            return 0;
          });

          if (app.vanities && app.vanities.length > 0) {
            // Rename the GET /applications/{id} vanities field to mirror what
            // is returned by GET /v1/content/{guid}/vanity. The initial
            // customUrl does not need the entire vanity response object...
            this.initial.customUrl = {
              path: app.vanities[0].pathPrefix,
            };
          } else {
            // it's necessary to reset this here because we may be reloading
            // after deleting an existing custom url
            this.initial.customUrl = {
              path: '',
            };
          }
        })
        .then(this.resetActive);
    },
    async oauthInfo() {
      if (this.serverSettings.oauthIntegrationsEnabled) {
        this.oauthSessions = await getUserSessions();
        this.contentAssociations = await getContentAssociations(this.app.guid);
      }
    },
    async prefillPermissionRequestIfAny() {
      const queryFound = getHashQueryParameter('permission_request');
      if (!queryFound) {
        return;
      }
      try {
        const [requestToken] = queryFound;
        const request = await getRequestAccessByToken({
          contentGUID: this.app.guid,
          requestToken,
        });
        const requester = await getUser(request.requesterGuid);
        this.requestByToken = { ...request, user: requester };
        if (request.approved) {
          return;
        }
        this.addPrincipal({
          principal: requester,
          mode: request.requestedRole,
          highlight: true,
        });
      } catch (err) {
        if (err.response && err.response.status !== 404) {
          this.setErrorMessageFromAPI(err);
        }
      }
    },
    resetActive() {
      this.updateType(this.initial.type);
      this.updateMode(this.initial.mode);

      this.activePrincipals = this.initial.principals.map(principal => ({
        ...principal,
        type: principal instanceof User ? 'user' : 'group',
        added: false,
        changed: false,
        deleted: false,
      }));

      this.activeCustomUrl = this.initial.customUrl?.path;
      this.requestByToken = null;
    },
    isChanged(principal) {
      return principal.deleted || principal.changed;
    },
    containsCurrentUser(principal) {
      // Returns true if principal is the current user, or is a group containing the current user.
      return principal.guid === this.currentUser.guid ||
        principal.members && principal.members.some(m => m.guid === this.currentUser.guid);
    },
    resolvePrincipal(principal) {
      // If the principal does not have a guid, it is a remote user that hasn't been
      // created in Connect yet, so try to add it. The returned object will have a guid.
      if (principal.guid) {
        return Promise.resolve(principal);
      } else if (principal instanceof User) {
        return addNewRemoteUser(principal.tempTicket);
      }
      return addNewRemoteGroup(principal.tempTicket);
    },
    getRequestRole() {
      let role = this.requestByToken.requestedRole;
      if (this.requestByToken.approved) {
        // Because the app role requested could be different than the role applied
        const approvedPrincipal = this.activePrincipals.find(
          p => p.guid === this.requestByToken.requesterGuid
        );
        role = AppRoles.stringOf(approvedPrincipal.appRole);
      }
      if (role === ModeType.OWNER) {
        role = 'collaborator';
      }
      return upperFirst(role);
    },
    addPrincipal({ principal, mode, highlight = false }) {
      const role = AppRoles.of(mode);

      // Ensure the principal exists in Connect before adding
      return this.resolvePrincipal(principal)
        .then(resolvedPrincipal => {
          const existingPrincipal = this.activePrincipals
            .find(({ guid }) => guid === resolvedPrincipal.guid);

          if (existingPrincipal) {
            if (existingPrincipal.appRole !== role) {
              // role is changing
              this.updatePrincipal({ principal: existingPrincipal, mode });
            } else {
              // maybe they deleted and then changed their mind
              existingPrincipal.deleted = false;
            }
          } else {
            this.activePrincipals.unshift({
              ...resolvedPrincipal,
              type: resolvedPrincipal instanceof User ? 'user' : 'group',
              appRole: role,
              added: true,
              changed: true,
              deleted: false,
              highlight,
            });
          }
        })
        .catch(this.setErrorMessageFromAPI);
    },
    updatePrincipal({ principal, mode }) {
      const role = AppRoles.of(mode);
      // If a collaborator is becoming a viewer, ask for confirmation
      if (
        this.containsCurrentUser(principal) &&
        AppRoles.isOwner(principal.appRole) &&
        AppRoles.isViewer(role)
      ) {
        this.pendingBecomeViewerPrincipal = principal;
        this.showBecomeViewerWarning = true;
      } else {
        principal.changed = true;
        principal.deleted = false;
        principal.appRole = role;
      }
    },
    closeBecomeViewerWarning() {
      this.showBecomeViewerWarning = false;
      this.pendingBecomeViewerPrincipal = null;
    },
    confirmBecomeViewer() {
      this.showBecomeViewerWarning = false;
      const principal = this.pendingBecomeViewerPrincipal;
      principal.changed = true;
      principal.deleted = false;
      principal.appRole = AppRoles.Viewer;
      this.pendingBecomeViewerPrincipal = null;
    },
    removePrincipal(principal) {
      if (this.containsCurrentUser(principal)) {
        // If a user is removing themself, ask for confirmation
        this.pendingRemovePrincipal = principal;
        this.showRemovePrincipalWarning = true;
      } else if (principal.added) {
        // Deleting a principal that was never saved will throw an error.
        // Pull them from the list instead.
        const idx = this.activePrincipals.findIndex(({ guid }) => guid === principal.guid);
        this.activePrincipals.splice(idx, 1);
      } else {
        principal.deleted = true;
      }
    },
    closeRemovePrincipalWarning() {
      this.showRemovePrincipalWarning = false;
      this.pendingRemovePrincipal = null;
    },
    confirmRemovePrincipal() {
      this.showRemovePrincipalWarning = false;
      // This looks weird, but this.pendingRemovePrincipal is a reference to a principal in
      // this.activePrincipals. So we change it, and then remove the temporary reference.
      this.pendingRemovePrincipal.deleted = true;
      this.pendingRemovePrincipal = null;
    },
    saveCustomUrl() {
      if (this.customUrlIsChanging) {
        if (this.activeCustomUrl === '') {
          return deleteCustomUrl(this.app.guid);
        }

        // normalize new path---make sure it starts and ends with a single `/`
        const path = joinPaths([`/${ this.activeCustomUrl }/`]);
        return setCustomUrl(this.app.guid, path);
      }
      return Promise.resolve();
    },
    saveAccessTypeAndEnvironment() {
      const attributes = {};
      if (this.typeIsChanging) {
        attributes.accessType = this.activeState.type;
      }
      if (Object.keys(attributes).length > 0) {
        return updateContent(this.app.guid, attributes);
      }
      return Promise.resolve();
    },
    savePrincipals() {
      if (this.listIsChanging) {
        return Promise.all(
          this.activePrincipals.filter(this.isChanged).map(principal => {
            if (principal.deleted) {
              if (principal.type === 'user') {
                return removeUser(this.app.id, principal.guid);
              }
              return removeGroup(this.app.id, principal.guid);
            } else if (principal.type === 'user') {
              return addUser(this.app.id, principal.guid, AppRoles.stringOf(principal.appRole));
            }
            return addGroup(this.app.id, principal.guid, AppRoles.stringOf(principal.appRole));
          })
        );
      }
      return Promise.resolve();
    },
    save() {
      return this.saveConfirmation();
    },
    saveConfirmation() {
      const savingTimeout = setTimeout(() => {
        this.isSaving = true;
      }, 300);

      this.clearStatusMessage();

      // Return the user to the content listing if they would no longer be able to view this:
      // * access type is ACL
      // * current user is not the owner or an admin
      // * no remaining active principals include the user
      const closeRequired = this.activeState.type === AccessTypes.Acl &&
        this.currentUser.guid !== this.ownerGuid &&
        !this.isAdmin &&
        !this.activePrincipals
          .filter(p => !p.deleted)
          .some(this.containsCurrentUser);

      // Reload the whole page after saving if current user is changing roles
      const reloadRequired =
        this.activePrincipals.some(p => this.containsCurrentUser(p) && p.changed);

      // What appears to the user as a single action is really (potentially) a batch of updates:
      // * POST, PUT, or DELETE custom url
      // * update app accessType
      // * POST or DELETE users and groups, one at a time
      //
      // We will attempt them all at once and report the first error, if any---unfortunately
      // without a batch API available on the server we cannot avoid the possibility of getting
      // in a half-updated state.
      return Promise.all([
        this.saveCustomUrl(),
        this.saveAccessTypeAndEnvironment(),
        this.savePrincipals(),
      ])
        .then(() => {
          if (closeRequired) {
            this.$router.push({ name: 'contentList' });
            return null;
          }

          if (reloadRequired) {
            window.location.reload();
            return null;
          }

          // clear the permission request, if any
          this.clearPermissionRequest();
          return this.reloadAccessData();
        })
        .catch(e => {
          this.setSaveErrorMessage(e);
          // try to make sure the panel data is up-to-date, but if there are
          // additional errors don't hide the primary one we just displayed
          return this.reloadAccessData(true);
        })
        .finally(() => {
          clearTimeout(savingTimeout);
          this.isSaving = false;
        });
    },
    async reloadAccessData(keepErr = false) {
      await this.resetApp({
        appIdOrGuid: this.app.guid,
        variantId: this.currentVariant.id,
      });
      try {
        await this.getData();
      } catch (err) {
        if (!keepErr) {
          this.setErrorMessageFromAPI(err);
        }
      }
      this.reloadFrame(true);
    },
    discard() {
      this.resetActive();
    },
    setSaveErrorMessage(e) {
      if (
        e &&
        e.response &&
        e.response.data &&
        e.response.data.code === ApiErrors.VanityPathInUse
      ) {
        // If this is a conflicting vanity URL message, display conflicts
        const notShown = e.response.data.payload.NotShown || 0;
        const conflicts = e.response.data.payload.Details.length || 0;
        let message;
        if (conflicts > 0 && notShown === 0) {
          message = 'Vanity path conflicts with: ';
        } else if (conflicts === 0 && notShown > 0) {
          message = `Vanity path conflicts with ${notShown} other vanity paths that you cannot view due to access restrictions.`;
        } else {
          // case where (conflicts > 0 && notShown > 0)
          const total = notShown + conflicts;
          message = `Vanity path conflicts with ${total} other vanity paths. You cannot view ${notShown} of these paths due to access restrictions. You have permission to view the following conflicting paths: `;
        }
        if (conflicts > 0) {
          message += e.response.data.payload.Details
            .map(conflict => conflict.url)
            .join(', ');
        }
        this.setErrorMessage({ message: `Error: ${message}` });
        return;
      }

      this.setErrorMessageFromAPI(e);
    },
    clearPermissionRequest() {
      this.requestByToken = null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';
@import 'Styles/shared/_variables';

// Make different section titles all match (several different elements are used
// which have slightly different default styles.)
.access {
  margin-bottom: 15px;
  padding-bottom: 15px;

  .rs-radiogroup__title {
    color: $color-secondary-inverse;
  }

  .rs-help-toggler__label {
    font-size: 0.9rem;
  }

  &-help {
    list-style:outside;
  }
}
.groupHeadings {
  color: $color-heading;
  letter-spacing: .1em;
  font-size: 1em;
  text-transform: uppercase;
  margin-bottom: 0.5em;
}
.spaceBefore {
  margin-top: 0.5rem;
}
.spaceAfter {
  margin-bottom: 0.5rem;
}
.add-links {
  text-decoration: underline;
}
.access-type-admin-guide-link {
  margin-bottom: 1.2rem;
}
.rs-radiogroup {
  margin-bottom: 0.2rem;
}
.login-button {
  min-width: 8.75rem;
  margin: 0.5em 0 1.5em;
  padding: 0.7rem 1.1rem;
  font-size: $rs-font-size-normal;
  line-height: 1rem;
  overflow: visible;
  border: none;
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition-duration: 250ms;
  text-align: center;
  background-color: $color-secondary;
  color: $color-secondary-inverse;
  display: inline-block;
  font-weight: normal;
  text-decoration: none;

  &:hover {
    background-color: $color-secondary-hover;
    text-decoration: none;
  }
}
</style>

<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <BaseHistory
    :type="type"
    :items="items"
    :displayed-id="displayedId"
    @display-item="item => onDisplayItem(item.id)"
    @toggle-history="onToggleHistory"
  />
</template>

<script>
import BaseHistory, { RENDERING_HISTORY } from './BaseHistory';
import { mapState, mapMutations } from 'vuex';
import {
  TOGGLE_RENDERINGS_HISTORY,
  SET_RENDERINGS_DISPLAYED_ID,
} from '@/store/modules/contentView';

export default {
  name: 'RenderingHistory',
  components: {
    BaseHistory,
  },
  data() {
    return {
      type: RENDERING_HISTORY,
    };
  },
  computed: {
    ...mapState({
      items: state => state.contentView.renderingHistory.items,
      displayedId: state => state.contentView.renderingHistory.displayedId,
    }),
  },
  methods: {
    ...mapMutations({
      onToggleHistory: TOGGLE_RENDERINGS_HISTORY,
      onDisplayItem: SET_RENDERINGS_DISPLAYED_ID,
    }),
  },
};
</script>
